import React from 'react';
import styled from 'styled-components';
import { Column } from './type';

interface Props {
  columns: Array<Column>
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1f2f4;
  height: 40px;
  color: #1f2329;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 12px;
`;

const Header: React.FC<Props> = ({
  columns
}) => {
  return (
    <Container>
      {
        columns.map((column, index) => (
          <div
            key={`table_head_${index}`}
            style={{
              width: column.width,
              textAlign: 'center'
            }}
          >{column.title}</div>
        ))
      }
      </Container>
  )
}

export default Header;
