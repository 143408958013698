import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import StatusBar from './StatusBar';
import { Button, Row, Col, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import Title from "@/components/Form/Title";
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";
import { getActivityDetail } from '@/services/activity';

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  background-color: #F4F5F9;
  padding: 5px 10px;
  font-size: 14px;
  color: #1f2329;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
`;

interface ParamType {
  id: string;
}

const ActivityDetail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<ParamType>();
  const [detail, setDetail] = useState<any>({});


  useEffect(() => {
    getActivityDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data);
      }
    })
  }, [])

  
  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { history.goBack() }}><CloseOutlined /></Button>
      </ButtonBar>
      <Container>
        <StatusBar startDate={detail?.activityBeginTime} endDate={detail?.activityEndTime} />
        <Panel>
          <TitleContainer>
            <Title>活动详情</Title>
            <Link to={`/activity/edit/${id}`}><Button>编辑</Button></Link>
          </TitleContainer>
          <Row gutter={[14, 12]} wrap={false}>
            <Col>
              <ImageContainer>
                <Image src={detail?.imageUrls} />
              </ImageContainer>
            </Col>
            <Col flex={1}>
              <TextContainer>{detail?.activityTitle}</TextContainer>
              <TextContainer>{detail?.activityDetail}</TextContainer>
            </Col>
          </Row>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="活动起止时间">
                  <Text>
                    {`${moment(detail?.activityBeginTime).format('YYYY-MM-DD')} 至 ${moment(detail?.activityEndTime).format('YYYY-MM-DD')}`}
                  </Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动编号">
                  <Text>{detail?.activityId}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动创建时间">
                  <Text>{detail?.createTime}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动范围">
                  <Text>{detail?.activityScope}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="活动类型">
                  <Text>{detail?.activityType}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
      </Container>
    </>
  )
}

export default ActivityDetail;