import qs from 'qs';
import jsonToFormData from 'json-form-data';
import request from '@/utils/request';
import { Company, ContractStatus, Whether } from '@/types/common';
import { EmployeeListQueryParams } from '@/types/company';
import { convertDict2List } from '@/utils/transform';

export function addCompany(data: Company) {
  const formData = jsonToFormData(data, {
    initialFormData: new FormData()
  });
  
  return request.post('/api/permission/corpRecord/add', formData).then(res => {
    console.log(res)
    return res
  })
}

export function getCompanyDetail(corpId: number | string) {
  return request.get(`/api/permission/corpRecord/detail?corpId=${corpId}`)
}

export function getCompanyList(params: {
  currentPage: number;
  pageSize?: number;
  contractStatus?: ContractStatus;
  corpId?: number;
  salesman?: string;
  staffSizeType?: number;
  corpName?: string;
}) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/corpRecord/list', formData)
}

export function getCompanyCount(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/corpRecord/count', formData)
}

export function updateCompany(data: Company) {
  const formData = jsonToFormData(data, {
    initialFormData: new FormData()
  });
  return request.post('/api/permission/corpRecord/upd', formData)
}

export function deleteCompany(corpId: number | string) {
  return request.post('/api/corpRecord/del', {
    corpId
  })
}

export function addEmployee(data: any) {
  const { birth, entryDate, graduateDate, ...others } = data;
  const formData = jsonToFormData({
    birth: birth?.format('YYYY-MM-DD') || '',
    entryDate: entryDate?.format('YYYY-MM-DD') || '',
    graduateDate: graduateDate?.format('YYYY-MM-DD') || '',
    deviceManager: false,
    jobPosition: '',
    nickName: '',
    ...others,
  }, {initialFormData: new FormData()})

  return request.post('/api/permission/userCorpRelation/save', formData)
}

export function updateEmployee(data: any) {
  const { birth, entryDate, graduateDate, employedInSocialOrg, employerSocialOrgName, ...others } = data;
  const formData = jsonToFormData({
    birth: birth?.format('YYYY-MM-DD') || '',
    entryDate: entryDate?.format('YYYY-MM-DD') || '',
    graduateDate: graduateDate?.format('YYYY-MM-DD') || '',
    employerSocialOrgName: employedInSocialOrg === Whether.FALSE ? '' : employerSocialOrgName,
    employedInSocialOrg,
    ...others
  }, {initialFormData: new FormData()})

  return request.post('/api/permission/userCorpRelation/save', formData)
}

export function deleteEmployee() {}

export function getEmployeeList(params: EmployeeListQueryParams = {
  currentPage: 1,
  pageSize: 10
}) {
  console.log('params: ', params)
  return request.get(`/api/permission/userCorpRelation/list?${qs.stringify(params)}`)
}

export function getEmployeeCount(params: any) {
  const formData = jsonToFormData(params, { initialFormData: new FormData() })
  return request.post('/api/permission/userCorpRelation/count', formData);
}

export function getEmployeeDetail(id: string | number) {
  return request.get(`/api/permission/userCorpRelation/detail?id=${id}`)
}

// 取合同状态
export function getContractStatus() {
  return request.get('/api/conf/corp/contractStatus').then(res => convertDict2List(res.data))
}

// 取公司规模
export function getStaffSizeType() {
  return request.get('/api/conf/corp/staffSizeType').then(res => convertDict2List(res.data))
}

// 取扩容计划
export function getExpansionPlan() {
  return request.get('/api/conf/corp/expansionPlan').then(res => convertDict2List(res.data))
}

// 取国家或地区
export function getCountryList() {
  return request.get('/api/conf/corp/countryList').then(res => convertDict2List(res.data))
}

// 取电话区号
export function getPhoneCountryCode() {
  return request.get('/api/conf/corp/phoneCountryCode').then(res => convertDict2List(res.data))
}

// 取成员角色
export function getCoprRoleList() {
  return request.get('/api/conf/user/corpRole').then(res => convertDict2List(res.data))
}

// 取学历
export function getEducationList() {
  return request.get('/api/conf/user/education').then(res => convertDict2List(res.data))
}

// 取工作类型
export function getEmployeeTypeList() {
  return request.get('/api/conf/user/employeeType').then(res => convertDict2List(res.data))
}

// 取工作状态
export function getJobStatusList() {
  return request.get('/api/conf/user/jobStatus').then(res => convertDict2List(res.data))
}

// 取行业
export function getIndustryList() {
  return request.get('/api/conf/corp/industryList').then(res => convertDict2List(res.data))
}

// 取知识产权类型列表
export function getPatentTypeList() {
  return request.get('/api/conf/corp/patentType').then(res => convertDict2List(res.data))
}

// 取所在费用周期列表
export function getCurrentFeeStageList() {
  return request.get('/api/conf/corp/currentFeeStage').then(res => convertDict2List(res.data))
}

// 修改成员工作状态
export function updateJobStatus(id: number | string, jobStatus: number | string) {
  const formData = jsonToFormData({
    id,
    jobStatus
  }, {initialFormData: new FormData()})
  return request.post('/api/permission/userCorpRelation/updJobStatus', formData);
}

export function addPatent(params: {
  corpId: number | string;
  patentDescription: string;
  patentImageUrl: string;
}) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()})
  return request.post('/api/permission/corpPatentRecord/add', formData);
}

export function updatePatent(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()})
  return request.post('/api/permission/corpPatentRecord/upd', formData)
}

export function getPatentList(corpId: string | number) {
  return request.get(`/api/permission/corpPatentRecord/list?corpId=${corpId}&currentPage=1&pzgeSIze=100`)
}

export function deletePatent(id: number) {
  return request.post(`/api/permission/corpPatentRecord/del?id=${id}`)
}

export function getContactList(corpId: number | string) {
  return request.get(`/api/permission/corpContactRecord/list?corpId=${corpId}&currentPage=1&pzgeSIze=100`)
}

export function addContact(params: any) {
  const formData = jsonToFormData({
    ...params,
    defaultContact: false
  }, {initialFormData: new FormData()});
  return request.post('/api/permission/corpContactRecord/add', formData);
}

export function updateContact(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()});
  return request.post('/api/permission/corpContactRecord/upd', formData);
}

export function deleteContact(contactId: number) {
  return request.post(`/api/permission/corpContactRecord/del?contactId=${contactId}`);
}

export function addWorkspaceRentContract(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()});
  return request.post('/api/permission/workspaceRentContractRecord/add', formData);
}

export function updateWorkspaceRentContract(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()});
  return request.post('/api/permission/workspaceRentContractRecord/upd', formData);
}


export function addWorkspaceRentContractImg(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()});
  return request.post('/api/permission/workspaceRentContractImg/add', formData)
}

export function updateWorkspaceRentContractImg(params: any) {
  const formData = jsonToFormData(params, {initialFormData: new FormData()});
  return request.post('/api/permission/workspaceRentContractImg/upd', formData)
}

export function getWorkspaceRentContractImgList(contractId: number | string) {
  return request.get(`/api/permission/workspaceRentContractImg/list?contractId=${contractId}&status=1&currentPage=1&pzgeSIze=100`)
}
