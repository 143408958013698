import { useState, useRef } from 'react';
import { Upload } from 'antd';
import styled from 'styled-components'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { uploadFile, getFileUrl } from '@/services/common';

const ImageContainer = styled.div`
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  value?: string;
  onChange?: (url: string, fileName?: string) => void;
}

const FileUpload: React.FC<Props> = ({
  value,
  onChange
}) => {
  const [loading, setLoading] = useState(false);
  const [filePath, setFilePath] = useState('');
  const imgRef = useRef<HTMLImageElement | null>(null);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const doUpload = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    setLoading(true);
    return new Promise<any>((resolve, reject) => {
      uploadFile({
        file,
        onProgress
      }).then(res => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          resolve({
            url: getFileUrl(data),
            fileName: file.name
          })
          onSuccess(file);
        }
        reject(res.data);
        setLoading(false);
      }).catch(err => {
        onError({ event: err });
        reject(err)
        setLoading(false);
      });
    })
  }

  const onImageLoaded = () => {
    if (!imgRef.current) {
      return;
    }
    if (imgRef.current.width > imgRef.current.height) {
      imgRef.current.style.width = '100%';
      imgRef.current.style.height = 'auto';
    } else {
      imgRef.current.style.height = '100%';
      imgRef.current.style.width = 'auto';
    }
  }

  return (
    <Upload
      listType="picture-card"
      showUploadList={false}
      customRequest={options => {
        doUpload(options).then(({url, fileName}) => {
          setFilePath(url);
          onChange && onChange(url, fileName);
        })
      }}
    >
      {filePath ? <ImageContainer><img src={filePath} ref={imgRef} onLoad={onImageLoaded} /></ImageContainer> : uploadButton}
    </Upload>
  )
}

export default FileUpload;