import React, { useState, useEffect } from 'react';
import { Space, Drawer, Button, Switch } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Elevator from '@/components/Elevator';
import RoomInfo from '../RoomInfo';
import { getWorkspaceDetail, getWorkspaceList } from '@/services/workspace';
import { ValueAndText } from '@/types/common';
import Floor from './Floor';
import img_legend from '@/assets/floor_plan/biaoshi.png';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 24px;
`

const FloorPlanContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FloorPlanInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 60px 0;
`

const Legend = styled.img`
  width: 529px;
  margin-top: 50px;
  display: block;
`

const ElevatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;  
  width: 80px;
`



interface Props {
  dataSource: any[];
  floors: Array<ValueAndText>;
}

const FloorPlan: React.FC<Props> = ({
  dataSource,
  floors
}) => {
  const [roomInfoVisible, setRoomInfoVisible] = useState(false);
  const [workspaceList, setWorkspaceList] = useState<any[]>([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(0);
  const [selectedRoomNo, setSelectedRoomNo] = useState('');
  const floorNo = useSelector((state: any) => state.workspace.common.floorNo);
  const rentStatus = useSelector((state: any) => state.workspace.common.rentStatus);

  const closeRoomInfo = () => {
    setRoomInfoVisible(false);
    setSelectedWorkspaceId(0);
    setSelectedRoomNo('');
  }
  
  const getWorkspaceListByFloorNo = (floorNo: string) => {
    getWorkspaceList({
      floorNo,
      rentStatus,
      currentPage: 1,
      pageSize: 100
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setWorkspaceList(data)
      }
    })
  }

  useEffect(() => {
    getWorkspaceListByFloorNo(floorNo);
  }, [rentStatus])

  return (
    <>
      <Container>
        <FloorPlanContainer>
          <FloorPlanInner>
            <Floor
              floorNo={floorNo}
              workspaceList={workspaceList}
              selectedRoomNo={selectedRoomNo}
              onClick={(roomNo: string) => {
                setRoomInfoVisible(true);
                setSelectedRoomNo(roomNo);
                setSelectedWorkspaceId(workspaceList.find((item: any) => item.roomNo === roomNo)?.workspaceId);
              }} 
            />
            <Legend src={img_legend} />
          </FloorPlanInner>
        </FloorPlanContainer>
        <ElevatorContainer>
          <Elevator floors={floors} onChange={getWorkspaceListByFloorNo} />
        </ElevatorContainer>
      </Container>
      <Drawer
        visible={roomInfoVisible}
        onClose={closeRoomInfo}
        width={560}
        closable={false}
      >
        <RoomInfo          
          roomType="办公室"
          workspaceId={selectedWorkspaceId}
          onClose={closeRoomInfo}
        />
      </Drawer>
    </>
  )
}

export default FloorPlan