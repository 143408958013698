import React from 'react';
import styled from 'styled-components';
import { Column } from './type';

interface Props {
  c1: Array<Column>;
  c2: Array<Column>;
  data: any;
  index?: number;
}

const Container = styled.div`
  border: 1px solid #E2EBFF;
  margin-bottom: 12px;
`;

const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 16px;
  background-color: #f9fbff;
  border-bottom: 1px solid #E2EBFF;
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
`;

const Cell1 = styled.div`
  display: flex;
  flex-direction: row;
  color: #1f2329;
  font-size: 12px;
  font-weight: 400;
  height: 44px;
  line-height: 44px;
  padding-left: 18px;
  > span {
    padding-right: 8px;
  }
`;

const Cell2 = styled.div`
  padding: 18px 20px;
  text-align: center;
`

const getCellContent = (index: number, column: Column, data: any) => {
  if (column.dataIndex) {
    return data[column.dataIndex]
  }
  if (column.render) {
    return column.render(data, index);
  }
  return null;
}

const TableRow: React.FC<Props> = ({
  c1,
  c2,
  data,
  index
}) => {
  return (
    <Container>
      <Row1>
        {
          c1.map((column, index) => (
            <Cell1
              key={`t_r_1_${index}`}
            >
              <span>{column.title ? `${column.title}:` : ''}</span>
              {getCellContent(index, column, data)}
            </Cell1>
          ))
        }
      </Row1>
      <Row2>
        {
          c2.map((column, index) => (
            <Cell2
              key={`t_r_2_${index}`}
              style={{ width: column.width }}
            >
              {getCellContent(index, column, data)}
            </Cell2>
          ))
        }
      </Row2>
    </Container>
  );
}

export default TableRow;