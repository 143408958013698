import request from '@/utils/request';
import qs from 'qs';
import jsonToFormData from 'json-form-data';

export function getFileCategoryList(params: any) {
  return request.get(`/api/permission/fileCategoryConf/list?${qs.stringify(params)}`);
}

export function getFileGroupList(params: any) {
  return request.get(`/api/permission/fileGroupConf/list?${qs.stringify(params)}`);
}

export function getFileGroupCount() {
  return request.get(`/api/permission/fileGroupConf/count`);
}

export function getFileList(data: any) {
  const formData = jsonToFormData({
    ...data,
    status: 1
  }, {
    initialFormData: new FormData()
  });
  return request.post('/api/permission/fileRecord/list', formData);
}

export function getFileListCount(data: any) {
  return request.post('/api/permission/fileRecord/count', jsonToFormData({
    ...data,
    status: 1
  }, {
    initialFormData: new FormData()
  }));
}

export function addFile(data: any) {
  return request.post('/api/permission/fileRecord/add', data)
}

export function addGroup(data: any) {
  return request.post('/api/permission/fileGroupConf/add', data);
}

export function addCategory(data: any) {
  return request.post('/api/permission/fileCategoryConf/add', data);
}

export function updateFile(data: any) {
  return request.post('/api/permission/fileRecord/upd', data);
}