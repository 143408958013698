import bg from '@/assets/energy/floors/4/4.png';
import img_400_common from '@/assets/energy/floors/4/common/400.png';
import img_400_1_common from '@/assets/energy/floors/4/common/400-1.png';
import img_400_2_common from '@/assets/energy/floors/4/common/400-2.png';
import img_400_3_common from '@/assets/energy/floors/4/common/400-3.png';
import img_400_4_common from '@/assets/energy/floors/4/common/400-4.png';
import img_401_1_common from '@/assets/energy/floors/4/common/401-1.png';
import img_401_2_common from '@/assets/energy/floors/4/common/401-2.png';
import img_402_1_common from '@/assets/energy/floors/4/common/402-1.png';
import img_402_2_common from '@/assets/energy/floors/4/common/402-2.png';
import img_403_1_common from '@/assets/energy/floors/4/common/403-1.png';
import img_403_2_common from '@/assets/energy/floors/4/common/403-2.png';
import img_403_3_common from '@/assets/energy/floors/4/common/403-3.png';
import img_406_common from '@/assets/energy/floors/4/common/406.png';
import img_407_common from '@/assets/energy/floors/4/common/407.png';
import img_408_common from '@/assets/energy/floors/4/common/408.png';
import img_409_common from '@/assets/energy/floors/4/common/409.png';
import img_410_1_common from '@/assets/energy/floors/4/common/410-1.png';
import img_410_2_common from '@/assets/energy/floors/4/common/410-2.png';
import img_410_3_common from '@/assets/energy/floors/4/common/410-3.png';
import img_410_4_common from '@/assets/energy/floors/4/common/410-4.png';
import img_410_5_common from '@/assets/energy/floors/4/common/410-5.png';
import img_410_6_common from '@/assets/energy/floors/4/common/410-6.png';
import img_416_common from '@/assets/energy/floors/4/common/416.png';
import img_417_common from '@/assets/energy/floors/4/common/417.png';
import img_418_common from '@/assets/energy/floors/4/common/418.png';
import img_419_common from '@/assets/energy/floors/4/common/419.png';
import img_420_common from '@/assets/energy/floors/4/common/420.png';
import img_421_common from '@/assets/energy/floors/4/common/421.png';
import img_422_common from '@/assets/energy/floors/4/common/422.png';
import img_423_common from '@/assets/energy/floors/4/common/423.png';
import img_424_common from '@/assets/energy/floors/4/common/424.png';
import img_425_common from '@/assets/energy/floors/4/common/425.png';
import img_426_common from '@/assets/energy/floors/4/common/426.png';
import img_427_common from '@/assets/energy/floors/4/common/427.png';
import img_428_common from '@/assets/energy/floors/4/common/428.png';
import img_429_common from '@/assets/energy/floors/4/common/429.png';
import img_430_common from '@/assets/energy/floors/4/common/430.png';
import img_431_common from '@/assets/energy/floors/4/common/431.png';
import img_432_common from '@/assets/energy/floors/4/common/432.png';
import img_433_common from '@/assets/energy/floors/4/common/433.png';
import img_434_common from '@/assets/energy/floors/4/common/434.png';
import img_435_common from '@/assets/energy/floors/4/common/435.png';
import img_436_common from '@/assets/energy/floors/4/common/436.png';
import img_437_common from '@/assets/energy/floors/4/common/437.png';
import img_438_common from '@/assets/energy/floors/4/common/438.png';
import img_439_common from '@/assets/energy/floors/4/common/439.png';
import img_440_common from '@/assets/energy/floors/4/common/440.png';
import img_441_common from '@/assets/energy/floors/4/common/441.png';
import img_442_common from '@/assets/energy/floors/4/common/442.png';
import img_444_common from '@/assets/energy/floors/4/common/444.png';
import img_445_common from '@/assets/energy/floors/4/common/445.png';

import img_400_selected from '@/assets/energy/floors/4/selected/400.png';
import img_400_1_selected from '@/assets/energy/floors/4/selected/400-1.png';
import img_400_2_selected from '@/assets/energy/floors/4/selected/400-2.png';
import img_400_3_selected from '@/assets/energy/floors/4/selected/400-3.png';
import img_400_4_selected from '@/assets/energy/floors/4/selected/400-4.png';
import img_401_1_selected from '@/assets/energy/floors/4/selected/401-1.png';
// import img_401_2_selected from '@/assets/energy/floors/4/selected/401-2.png';
import img_402_1_selected from '@/assets/energy/floors/4/selected/402-1.png';
// import img_402_2_selected from '@/assets/energy/floors/4/selected/402-2.png';
import img_403_1_selected from '@/assets/energy/floors/4/selected/403-1.png';
// import img_403_2_selected from '@/assets/energy/floors/4/selected/403-2.png';
// import img_403_3_selected from '@/assets/energy/floors/4/selected/403-3.png';
import img_406_selected from '@/assets/energy/floors/4/selected/406.png';
// import img_407_selected from '@/assets/energy/floors/4/selected/407.png';
// import img_408_selected from '@/assets/energy/floors/4/selected/408.png';
import img_409_selected from '@/assets/energy/floors/4/selected/409.png';
import img_410_1_selected from '@/assets/energy/floors/4/selected/410-1.png';
// import img_410_2_selected from '@/assets/energy/floors/4/selected/410-2.png';
// import img_410_3_selected from '@/assets/energy/floors/4/selected/410-3.png';
// import img_410_4_selected from '@/assets/energy/floors/4/selected/410-4.png';
// import img_410_5_selected from '@/assets/energy/floors/4/selected/410-5.png';
// import img_410_6_selected from '@/assets/energy/floors/4/selected/410-6.png';
import img_416_selected from '@/assets/energy/floors/4/selected/416.png';
import img_417_selected from '@/assets/energy/floors/4/selected/417.png';
import img_418_selected from '@/assets/energy/floors/4/selected/418.png';
import img_419_selected from '@/assets/energy/floors/4/selected/419.png';
import img_420_selected from '@/assets/energy/floors/4/selected/420.png';
import img_421_selected from '@/assets/energy/floors/4/selected/421.png';
import img_422_selected from '@/assets/energy/floors/4/selected/422.png';
import img_423_selected from '@/assets/energy/floors/4/selected/423.png';
import img_424_selected from '@/assets/energy/floors/4/selected/424.png';
import img_425_selected from '@/assets/energy/floors/4/selected/425.png';
import img_426_selected from '@/assets/energy/floors/4/selected/426.png';
import img_427_selected from '@/assets/energy/floors/4/selected/427.png';
import img_428_selected from '@/assets/energy/floors/4/selected/428.png';
import img_429_selected from '@/assets/energy/floors/4/selected/429.png';
import img_430_selected from '@/assets/energy/floors/4/selected/430.png';
import img_431_selected from '@/assets/energy/floors/4/selected/431.png';
import img_432_selected from '@/assets/energy/floors/4/selected/432.png';
import img_433_selected from '@/assets/energy/floors/4/selected/433.png';
import img_434_selected from '@/assets/energy/floors/4/selected/434.png';
import img_435_selected from '@/assets/energy/floors/4/selected/435.png';
import img_436_selected from '@/assets/energy/floors/4/selected/436.png';
import img_437_selected from '@/assets/energy/floors/4/selected/437.png';
import img_438_selected from '@/assets/energy/floors/4/selected/438.png';
import img_439_selected from '@/assets/energy/floors/4/selected/439.png';
import img_440_selected from '@/assets/energy/floors/4/selected/440.png';
import img_441_selected from '@/assets/energy/floors/4/selected/441.png';
import img_442_selected from '@/assets/energy/floors/4/selected/442.png';
import img_444_selected from '@/assets/energy/floors/4/selected/444.png';
import img_445_selected from '@/assets/energy/floors/4/selected/445.png';



const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 310) / 328}%`;
const fontSize = (w: number, f: number) => `${100 * (f / w)}%`

const initialRoomList = [
  {
    title: '400-1',
    titleLeft: `${100 * (1017 - 370) / 830}%`,
    titleTop: `${100 * (531 - 399) / 830}%`,
    roomNo: '400-1',
    width: width(830),
    left: left(370),
    top: top(399),
    fontSize: '12px',
    color: '#808080',
    image: img_400_1_common,
    selectedImage: img_400_1_selected,
  },
  {
    title: '400-2',
    roomNo: '400-2',
    width: width(22),
    left: left(1022),
    top: top(421),
    fontSize: '12px',
    writingMode: 'vertical-lr',
    color: '#808080',
    image: img_400_2_common,
    selectedImage: img_400_2_selected,
  },
  {
    title: '400-3',
    roomNo: '400-3',
    width: width(18),
    left: left(860),
    top: top(421),
    fontSize: '12px',
    writingMode: 'vertical-lr',
    color: '#808080',
    image: img_400_3_common,
    selectedImage: img_400_3_selected,
  },
  {
    title: '400-4',
    roomNo: '400-4',
    width: width(18),
    left: left(638),
    top: top(421),
    fontSize: '12px',
    writingMode: 'vertical-lr',
    color: '#808080',
    image: img_400_4_common,
    selectedImage: img_400_4_selected,
  },
  {
    title: '健身区',
    roomNo: '417',
    width: width(66),
    left: left(300),
    top: top(313),
    fontSize: fontSize(66, 16),
    image: img_417_common,
    selectedImage: img_417_selected,
  },
  {
    title: '打印服务中心',
    roomNo: '416',
    width: width(134),
    left: left(368),
    top: top(313),
    fontSize: fontSize(134, 16),
    image: img_416_common,
    selectedImage: img_416_selected,
  },
  {
    title: '摄影区',
    titleLeft: `${100 * (628 - 504) / 192}%`,
    titleTop: `${100 * (333 - 313) / 192}%`,
    roomNo: '410-1',
    width: width(192),
    left: left(504),
    top: top(313),
    fontSize: fontSize(192, 16),
    image: img_410_1_common,
    selectedImage: img_410_1_selected,
  },
  {
    title: '410-3',
    roomNo: '410-3',
    width: width(24),
    left: left(504),
    top: top(313),
    fontSize: fontSize(24, 12),
    writingMode: 'vertical-lr',
    image: img_410_3_common,
    // selectedImage: img_410_3_selected,
  },
  {
    title: '410-2',
    roomNo: '410-2',
    width: width(78),
    left: left(530),
    top: top(313),
    fontSize: fontSize(78, 12),
    image: img_410_2_common,
    // selectedImage: img_410_2_selected,
  },
  {
    title: '410-4',
    roomNo: '410-4',
    width: width(24),
    left: left(504),
    top: top(369),
    fontSize: 'small',
    image: img_410_4_common,
    // selectedImage: img_410_4_selected,
  },
  {
    title: '410-5',
    roomNo: '410-5',
    width: width(24),
    left: left(542),
    top: top(369),
    fontSize: 'small',
    image: img_410_5_common,
    // selectedImage: img_410_5_selected,
  },
  {
    title: '410-6',
    roomNo: '410-6',
    width: width(40),
    left: left(568),
    top: top(369),
    fontSize: 'small',
    image: img_410_6_common,
  },
  {
    title: '新风机房',
    roomNo: '409',
    width: width(40),
    left: left(698),
    top: top(313),
    writingMode: 'vertical-lr',
    fontSize: fontSize(40, 12),
    image: img_409_common,
    selectedImage: img_409_selected,
  },
  {
    title: '茶室',
    titleLeft: `${100 * (866 - 740) / 190}%`,
    titleTop: `${100 * (333 - 313) / 190}%`,
    roomNo: '406',
    width: width(190),
    left: left(740),
    top: top(313),
    fontSize: fontSize(190, 16),
    image: img_406_common,
    selectedImage: img_406_selected,
  },
  {
    title: '408',
    roomNo: '408',
    width: width(61),
    left: left(740),
    top: top(313),
    fontSize: fontSize(61, 12),
    image: img_408_common
  },
  {
    title: '407',
    roomNo: '407',
    width: width(32),
    left: left(803),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_407_common
  },
  {
    title: '运营办公室',
    titleLeft: `${100 * (1001 - 932) / 160}%`,
    titleTop: `${100 * (333 - 313) / 160}%`,
    roomNo: '403-1',
    width: width(160),
    left: left(932),
    top: top(313),
    fontSize: fontSize(160 ,16),
    image: img_403_1_common,
    selectedImage: img_403_1_selected,
  },
  {
    title: '403-2',
    roomNo: '403-2',
    width: width(59),
    left: left(932),
    top: top(313),
    fontSize: fontSize(59, 12),
    image: img_403_2_common
  },
  {
    title: '403-3',
    roomNo: '403-3',
    width: width(59),
    left: left(932),
    top: top(355),
    fontSize: fontSize(59, 12),
    image: img_403_3_common
  },
  {
    title: '402-1',
    titleLeft: `${100 * (1114 - 1094) / 80}%`,
    titleTop: `${100 * (370 - 327) / 80}%`,
    roomNo: '402-1',
    width: width(80),
    left: left(1094),
    top: top(327),
    fontSize: fontSize(80, 16),
    image: img_402_1_common,
    selectedImage: img_402_1_selected,
  },
  {
    title: '402-2',
    roomNo: '402-2',
    width: width(28),
    left: left(1094),
    top: top(327),
    fontSize: 'small',
    image: img_402_2_common
  },
  {
    title: '401-1',
    titleLeft: `${100 * (1204 - 1176) / 98}%`,
    titleTop: `${100 * (370 - 327) / 98}%`,
    roomNo: '401-1',
    width: width(98),
    left: left(1176),
    top: top(327),
    fontSize: fontSize(98, 16),
    image: img_401_1_common,
    selectedImage: img_401_1_selected,
  },
  {
    title: '401-2',
    roomNo: '401-2',
    width: width(28),
    left: left(1176),
    top: top(327),
    fontSize: 'small',
    image: img_401_2_common
  },
  {
    title: '新风机房',
    roomNo: '434',
    width: width(70),
    left: left(1204),
    top: top(397),
    fontSize: 'small',
    image: img_434_common,
    selectedImage: img_434_selected,
  },
  {
    title: '电话间',
    roomNo: '435',
    width: width(70),
    left: left(1204),
    top: top(427),
    fontSize: fontSize(70, 16),
    image: img_435_common,
    selectedImage: img_435_selected,
  },
  {
    title: '436',
    roomNo: '436',
    width: width(36),
    left: left(1142),
    top: top(553),
    fontSize: fontSize(36, 12),
    image: img_436_common,
    selectedImage: img_436_selected,
  },
  {
    title: '437',
    roomNo: '437',
    width: width(36),
    left: left(1142),
    top: top(589),
    fontSize: fontSize(36, 12),
    image: img_437_common,
    selectedImage: img_437_selected,
  },
  {
    title: '接待区',
    roomNo: '400',
    width: width(236),
    left: left(904),
    top: top(553),
    fontSize: fontSize(236, 16),
    image: img_400_common,
    selectedImage: img_400_selected
  },
  {
    title: '茶水休闲区',
    titleLeft: `${100 * (718 - 700) / 202}%`,
    titleTop: `${100 * (567 - 553) / 202}%`,
    titleWidth: '80px',
    roomNo: '440',
    width: width(202),
    left: left(700),
    top: top(553),
    fontSize: fontSize(202, 16),
    image: img_440_common,
    selectedImage: img_440_selected,
  },
  {
    title: '438',
    roomNo: '438',
    width: width(42),
    left: left(860),
    top: top(553),
    fontSize: fontSize(42, 16),
    image: img_438_common,
    selectedImage: img_438_selected,
  },
  {
    title: '439',
    roomNo: '439',
    width: width(42),
    left: left(816),
    top: top(553),
    fontSize: fontSize(42, 16),
    image: img_439_common,
    selectedImage: img_439_selected,
  },
  {
    title: '445',
    roomNo: '445',
    width: width(90),
    left: left(608),
    top: top(553),
    fontSize: fontSize(90, 16),
    image: img_445_common,
    selectedImage: img_445_selected,
  },
  {
    title: '441',
    roomNo: '441',
    width: width(104),
    left: left(502),
    top: top(553),
    fontSize: fontSize(104, 16),
    image: img_441_common,
    selectedImage: img_441_selected,
  },
  {
    title: '442',
    roomNo: '442',
    width: width(60),
    left: left(440),
    top: top(553),
    fontSize: fontSize(60, 16),
    image: img_442_common,
    selectedImage: img_442_selected,
  },
  {
    title: '多功能路演厅',
    roomNo: '418',
    width: width(112),
    left: left(300),
    top: top(421),
    fontSize: fontSize(112, 16),
    writingMode: 'vertical-lr',
    image: img_418_common,
    selectedImage: img_418_selected,
  },
  {
    title: '保洁室',
    roomNo: '419',
    width: width(48),
    left: left(454),
    top: top(421),
    fontSize: fontSize(48, 12),
    image: img_419_common,
    selectedImage: img_419_selected,
  },
  {
    title: '储藏室',
    roomNo: '420',
    width: width(48),
    left: left(454),
    top: top(469),
    fontSize: fontSize(48, 12),
    image: img_420_common,
    selectedImage: img_420_selected,
  },
  {
    title: '421',
    roomNo: '421',
    width: width(60),
    left: left(505),
    top: top(422),
    fontSize: fontSize(60, 12),
    image: img_421_common,
    selectedImage: img_421_selected,
  },
  {
    title: '422',
    roomNo: '422',
    width: width(66),
    left: left(568),
    top: top(421),
    fontSize: fontSize(66, 12),
    image: img_422_common,
    selectedImage: img_422_selected,
  },
  {
    title: '423',
    roomNo: '423',
    width: width(66),
    left: left(568),
    top: top(469),
    fontSize: fontSize(66, 12),
    image: img_423_common,
    selectedImage: img_423_selected,
  },
  {
    title: '425',
    roomNo: '425',
    width: width(76),
    left: left(660),
    top: top(421),
    fontSize: fontSize(76, 12),
    image: img_425_common,
    selectedImage: img_425_selected,
  },
  {
    title: '母婴室',
    roomNo: '424',
    width: width(40),
    left: left(660),
    top: top(483),
    fontSize: fontSize(40, 12),
    image: img_424_common,
    selectedImage: img_424_selected,
  },
  {
    title: '书吧',
    titleLeft: `${100 * (748 - 702) / 88}%`,
    titleTop: `${100 * (453 - 421) / 88}%`,
    roomNo: '426',
    width: width(88),
    left: left(702),
    top: top(421),
    fontSize: fontSize(88, 12),
    image: img_426_common,
    selectedImage: img_426_selected,
  },
  {
    title: '427',
    roomNo: '427',
    width: width(64),
    left: left(792),
    top: top(421),
    fontSize: fontSize(64, 12),
    image: img_427_common,
    selectedImage: img_427_selected,
  },
  {
    title: '428',
    roomNo: '428',
    width: width(64),
    left: left(792),
    top: top(481),
    fontSize: fontSize(64, 12),
    image: img_428_common,
    selectedImage: img_428_selected,
  },
  {
    title: '430',
    roomNo: '430',
    width: width(58),
    left: left(882),
    top: top(421),
    fontSize: fontSize(58, 12),
    image: img_430_common,
    selectedImage: img_430_selected,
  },
  {
    title: '429',
    roomNo: '429',
    width: width(58),
    left: left(882),
    top: top(487),
    fontSize: fontSize(58, 12),
    image: img_429_common,
    selectedImage: img_429_selected,
  },
  {
    title: '431',
    roomNo: '431',
    width: width(28),
    left: left(912),
    top: top(463),
    fontSize: fontSize(28, 12),
    image: img_431_common,
    selectedImage: img_431_selected,
  },
  {
    title: '444',
    roomNo: '444',
    width: width(34),
    left: left(983),
    top: top(501),
    fontSize: fontSize(34, 12),
    image: img_444_common,
    selectedImage: img_444_selected,
  },
  {
    title: '432',
    roomNo: '432',
    width: width(18),
    left: left(1086),
    top: top(421),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_432_common,
    selectedImage: img_432_selected,
  },
  {
    title: '433',
    roomNo: '433',
    width: width(26),
    left: left(1082),
    top: top(488),
    fontSize: fontSize(26, 12),
    image: img_433_common,
    selectedImage: img_433_selected,
  },
  
]

export default {
  bg,
  initialRoomList
}