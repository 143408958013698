import { useRef } from 'react';
import QRCode from 'qrcode.react';
import AsyncQRCode from '@/components/AsyncQRCode';
import { Button, Space } from 'antd';

interface Props {
  value: string | Promise<any>;
  direction?: 'vertical' | 'horizontal';
  size?: number;
}

const DownloadableQRCode: React.FC<Props> = ({
  value,
  direction = 'vertical',
  size = 80
}) => {
  const qrImgRef = useRef(null);

  const downloadQRCode = () => {
    if (!qrImgRef.current) {
      return
    }
    const oContainer = qrImgRef.current as any;
    const oCanvas = oContainer.querySelector('canvas')
    const pngUrl = oCanvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `corp_qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <Space direction={direction} align="center">
      <div ref={qrImgRef}>
        {
          typeof value === 'object' ? (
            <AsyncQRCode value={value} size={size} />
          ) : (
            <QRCode value={value} size={size} />
          )
        }
      </div>
      <Button size="small" onClick={downloadQRCode}>下载二维码</Button>
    </Space>
    
  )
}

export default DownloadableQRCode