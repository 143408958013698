import { useState, useEffect } from 'react';
import { Input, Switch, Form, Select, Space, Button } from 'antd';
import { CardStatusList } from '@/common/constants';
import { getCardDetail, updateCard, addCard } from '@/services/card'
import { useForm } from 'antd/lib/form/Form';

const FormItem = Form.Item;

interface Props {
  cardNo?: string;
  onClose: () => void;
}

const Editor: React.FC<Props> = ({
  cardNo,
  onClose,
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const save = () => {
    setLoading(true);
    form.validateFields().then((values: any) => {
      const {
        hasAllPermission, hasMeetingRoomPermission, 
        hasNotRentOfficePermission, hasPublicWorkspacePermission,
        ...others
      } = values;
      const data = {
        hasAllPermission: hasAllPermission ? 1 : 0,
        hasMeetingRoomPermission: hasMeetingRoomPermission ? 1 : 0,
        hasNotRentOfficePermission: hasNotRentOfficePermission ? 1 : 0,
        hasPublicWorkspacePermission: hasPublicWorkspacePermission ? 1 : 0,
        ...others,
      };
      if (cardNo) {
        updateCard({
          ...data,
          cardNo
        }).then(() => {
          setLoading(false);
          onClose();
        }).catch(() => {
          setLoading(false);
          onClose();
        })
      }
      else {
        addCard(data).then((res: any) => {
          setLoading(false);
          onClose();
        }).catch(() => {
          setLoading(false);
          onClose();
        })
      }
    })
    
  }

  useEffect(() => {
    if (cardNo) {
      getCardDetail(cardNo).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const { hasAllPermission, hasMeetingRoomPermission, hasNotRentOfficePermission, hasPublicWorkspacePermission, ...otherData} = data;
          form.setFieldsValue({
            ...otherData,
            hasAllPermission: !!hasAllPermission,
            hasMeetingRoomPermission: !!hasMeetingRoomPermission,
            hasNotRentOfficePermission: !!hasNotRentOfficePermission,
            hasPublicWorkspacePermission: !!hasPublicWorkspacePermission,
          });
        }
      })
    }
  }, [cardNo])

  return (
    <Form form={form}>
      <FormItem label="卡号" name="cardNo">
        <Input />
      </FormItem>
      <FormItem label="是否拥有所有房间的权限" name="hasAllPermission" valuePropName="checked">
        <Switch />
      </FormItem>
      <FormItem label="是否拥有会议室的权限" name="hasMeetingRoomPermission" valuePropName="checked">
        <Switch />
      </FormItem>
      <FormItem label="是否拥有未出租办公室的权限" name="hasNotRentOfficePermission" valuePropName="checked">
        <Switch />
      </FormItem>
      <FormItem label="是否拥有公共区域的权限" name="hasPublicWorkspacePermission" valuePropName="checked">
        <Switch />
      </FormItem>
      <FormItem label="状态" name="status">
        <Select options={CardStatusList} />
      </FormItem>
      <Space>
        <Button onClick={save} loading={loading}>保存</Button>
      </Space>
    </Form>
  )
}

export default Editor;