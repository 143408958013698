import request from '@/utils/request';
import qs from 'qs';

export function getCardList(params: any) {
  return request.get(`/api/permission/doorNfcCardRecord/list?${qs.stringify(params)}`)
}

export function getCardListCount(params: any) {
  return request.get(`/api/permission/doorNfcCardRecord/count?${qs.stringify(params)}`)
}

export function getCardDetail(cardNo: string) {
  return request.get(`/api/permission/doorNfcCardRecord/detail?cardNo=${cardNo}`)
}

export function addCard(params: any) {
  return request.post('/api/permission/doorNfcCardRecord/add', params)
}

export function updateCard(params: any) {
  return request.post('/api/permission/doorNfcCardRecord/upd', params)
}