import { useEffect, useState } from 'react';
import { DatePicker, Popover, Space, Spin, Divider } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import Select from '@/components/Select';
import { getWorkspaceAcAmmeterMonthStat } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import CalendarDetail from './CalendarDetail';

const Container = styled.div``

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const RoomName = styled.div`
  width: 224px;
  height: 35px;
  line-height: 35px;
  padding-left: 8px;
  border-bottom: 1px solid #E2EBFF;
`

const Cell = styled.div`
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 36px;
  border-left: 1px solid #E2EBFF;
  border-bottom: 1px solid #E2EBFF;
  > div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  >.used {
    background-color: #E2EBFF;
    cursor: pointer
  }
`

const TimesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const timeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const getDayDataByDate = (date: Number, dayDataList: any[] = []) => {
  for(let i = 0; i < dayDataList.length; i++) {
    if (moment(dayDataList[i].generateDate).date() === date) {
      return dayDataList[i];
    }
  }
  return null;
}

interface Props {}

const MonthlyBill: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [dayList, setDayList] = useState<Array<number>>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [queryDate, setQueryDate] = useState<moment.Moment | null>(moment());
  const [floorNo, setFloorNo] = useState('');
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [floorList, setFloorList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);

  useEffect(() => {
    if (queryDate) {
      setLoading(true);
      const newDayList = []
      for(let i=1; i <= queryDate.endOf('month').date(); i++) {
        newDayList.push(i);
      }
      setDayList(newDayList);

      getWorkspaceAcAmmeterMonthStat(queryDate.format('yyyyMM')).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          let newDataSource: Array<any> = [];
          data.forEach((d: any) => {
            const { subList, ...roomData } = d;
            if (roomData.dayDataList.length > 0) {
              newDataSource.push(roomData);
              return;
            }

            newDataSource = newDataSource.concat(subList);
            
          });

          setDataSource(newDataSource);
          setLoading(false);
        }
      }).catch(() => {
        setLoading(false);
      })
    }
  }, [queryDate])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorList(list.map(item => ({
        value: item,
        text: item
      })));
      setFloorRoomMap(res.data);
    })
  }, [])

  useEffect(() => {
    if (floorNo) {
      setRoomList(floorRoomMap[floorNo].filter((item: string) => !item.includes('-')).map((item: string) => ({
        value: item,
        text: item
      })))
    }
  }, [floorNo])


  return (
    <Container>
      <SearchBar>
        <Space>
          <DatePicker picker="month" value={queryDate} onChange={val => setQueryDate(val)} />
          <Select label="所属楼层" value={floorNo} options={floorList} onChange={val => setFloorNo(val)} />
          <Select label="所属房间" value={roomNo} options={roomList} onChange={val => setRoomNo(val)} />
        </Space>
        <Space>
          <Link to="/energy/monthlybill">表格视图</Link>
          <Divider type="vertical" />
          <Link to="/energy/dailybill">日账单<RightOutlined /></Link>
        </Space>
      </SearchBar>
      <Spin spinning={loading}>
        <Header>
          <RoomName>房间号</RoomName>
          <TimesContainer>
            {
              dayList.map(d => <Cell key={`day_${d}`}>{d}</Cell>)
            }
          </TimesContainer>
        </Header>
        {
          dataSource.filter((item: any) => {
            if (floorNo && roomNo) {
              return item.floorNo === floorNo && item.roomNo === roomNo;
            }
            if (floorNo) {
              return item.floorNo === floorNo;
            }
            if (roomNo) {
              return item.roomNo === roomNo;
            }
            return true; 
          }).map((item: any, index: number) => {
            return (
              <TableRow key={`table_row_time_${index}`}>
                <RoomName>{`${item.roomNo}`}</RoomName>
                <TimesContainer>
                  {
                    dayList.map(t => {
                      const dayData = getDayDataByDate(t, item.dayDataList);
                      return (
                        <Cell key={`cell_${index}_${t}`}>
                          {
                            dayData ? (
                              <Popover
                                placement="right"
                                trigger="click"
                                content={<CalendarDetail data={dayData} />}
                              >
                                <div className="used"></div>
                              </Popover>
                            ) : null
                          }
                        </Cell>
                      )
                    })
                  }
                </TimesContainer>
              </TableRow>
            )
          })
        }
      </Spin>
      
    </Container>
  )
}

export default MonthlyBill;