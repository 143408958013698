import { useState, useEffect } from 'react';
import { Table, Space, DatePicker, Button, Select as AntSelect, Divider } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import Select from '@/components/Select';
import { getBillList, getBillListCount } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import RoomMonthlyDetail from '../RoomMonthlyDetail';
import { useSelector } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

const { Option, OptGroup } = AntSelect;
const { RangePicker } = DatePicker;
const { Column } = Table;

const MonthlyBill = () => {
  const [loading, setLoading] = useState(false);
  const [queryStartDate, setQueryStartDate] = useState<moment.Moment | null>(null);
  const [queryEndDate, setQueryEndDate] = useState<moment.Moment | null>(null);
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const businessTypeList = useSelector((state: any) => state.common.businessTypeList);

  const getData = (pageIndex = 1, pageSize = 30) => {
    setLoading(true);
    const params = {
      roomNo,
      beginCreateTime: queryStartDate?.format('yyyy-MM-DD 00:00:00'),
      endCreateTime: queryEndDate?.format('yyyy-MM-DD 00:00:00')
    }
    getBillList({
      currentPage: pageIndex,
      pageSize,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    getBillListCount(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    getData(page, pageSize_)
  }

  useEffect(() => {
    getData();
  }, [queryStartDate, queryEndDate, roomNo])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      setFloorRoomMap(res.data);
    })
  }, [])

  return (
    <div>
      <SearchBar>
        <Space>
          <RangePicker value={[queryStartDate, queryEndDate]} onChange={vals => {
            if (vals) {
              setQueryStartDate(vals[0])
              setQueryEndDate(vals[1])
            }
          }} />
          <Select
            showSearch
            label="所属房间"
            value={roomNo}
            onChange={val => setRoomNo(val)}
            optionsRender={() => Object.keys(floorRoomMap).sort((prev: string, next: string) => {
              return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
            }).map((floorName: string, floorIndex: number) => (
              <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                {
                  floorRoomMap[floorName].map((roomName: string, roomIndex: number) => (
                    <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                  ))
                }
              </OptGroup>
            ))}
          />
        </Space>
        <Space>
          <Link to="/energy/monthlybillc">日历视图</Link>
          <Divider type="vertical" />
          <Link to="/energy/dailybill">日账单<RightOutlined /></Link>
        </Space>
      </SearchBar>
      <Table
        dataSource={dataSource}
        loading={loading}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true
        }}
      >
        <Column title="公司名称" dataIndex="corpName" />
        <Column title="房间号" dataIndex="roomNo" />
        <Column title="账单名称" dataIndex="billTitle" />
        <Column title="账单类型" dataIndex="businessType" render={_ => getSelectedTextFromList(businessTypeList, _)} />
        <Column title="账单金额" dataIndex="payableAmount" />
        <Column title="账单时间" dataIndex="billDateSign" />
        <Column
          title="操作" 
          render={(_: string, record: any) => (
            <Button type="link" onClick={() => {
              setSelectedRecord(record)
              setIsShowDetail(true)
            }}>详情</Button>
          )}
        />
      </Table>
      <RoomMonthlyDetail
        visible={isShowDetail}
        billId={selectedRecord.billId}
        onClose={() => setIsShowDetail(false)}
      />
    </div>
  )
}

export default MonthlyBill;