import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';

interface Props {
  value: Promise<any>;
  size?: number;
}

const AsyncQRCode: React.FC<Props> = ({
  value,
  size
}) => {
  const [_value, setValue ] = useState('');

  useEffect(() => {
    value.then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setValue(data)
      }
    })
  }, [value])

  return (
    <QRCode value={_value} size={size} />
  )
}

export default AsyncQRCode;