import { useState } from 'react';
import { Row, Col, Select, DatePicker, Input, Upload, Form, Image } from 'antd';
import styled from 'styled-components';
import Text from "@/components/Form/Text";
import UploadButton from '@/components/UploadButton';
import { uploadFile, getFileUrl } from '@/services/common';
import { getWorkspaceList } from '@/services/workspace';
import { ContractImgType } from '@/types/common';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormInstance } from 'antd/lib/form';
import img_pdf from '@/assets/pdf.png';

const { Option, OptGroup } = Select;
const FormItem = Form.Item;

const ImageContainer = styled.div`
  width: 80px;
  img {
    width: 100%;
  }
`

const PdfBox = styled.div`
  width: 80px;

  img {
    width: 100%
  }

`

interface ImageListProps {
  imageKey: string;
  data: any[];
}

const ImageList: React.FC<ImageListProps> = ({ imageKey, data }) => (
  <>
  {
    data.map((item: any, index: number) => (
      <ImageContainer key={`image_list_${imageKey}_${index}`}>
        <a href={item.fileUrl} target="blank">
        {
          item.fileUrl.includes('pdf') ? (
            <PdfBox><img src={img_pdf} /></PdfBox>
          ) : (
            <Image src={item.url} />
          )
        }
        </a>
      </ImageContainer>
    ))
  }
  </>
) 

interface Props {
  form?: FormInstance;
  data?: any;
  floorRoomList: any;
  contractImgList: any[];
  onContractImgListChange?: (list: any []) => void;  
  cacheRoom?: (values: any) => void;
  removeRoom?: (roomNo: string) => void;
  modifyRoom?: (values: any) => void;
  cachedRoomList: any[];
}

const CorpStatusForm3: React.FC<Props> = ({
  form,
  data,
  floorRoomList,
  contractImgList,
  cachedRoomList,
  onContractImgListChange,
  cacheRoom,
  removeRoom,
  modifyRoom
}) => {
  const [contractAttachmentUploading, setContractAttachmentUploading] = useState(false);
  const [supplementalAgreementUploading, setSupplementalAgreementUploading] = useState(false);
  
  const getFileList = (contractImgType: ContractImgType): any[] => {
    console.log('contractImgList: ', contractImgList, 'contractImgType: ', contractImgType)
    const list= contractImgList.filter(item => {
      return !item.isRemoved && item.type === contractImgType
    }).map(item => ({
      uid: item.index,
      status: 'done',
      url: item.imageUrl.includes('pdf') ? img_pdf : item.imageUrl,
      fileUrl: item.imageUrl
    }))
    console.log('filelist: ', list)
    return list;
  }

  const addContractImg = (imageUrl: string, contractImgType: ContractImgType) => {
    const newList = [...contractImgList];
    newList.push({
      imageUrl,
      type: contractImgType,
      index: newList.length + 1,
      isRemoved: false
    })
    onContractImgListChange && onContractImgListChange(newList);
  }
  const removeContractImg = (file: UploadFile<any>) => {
    const newList = [...contractImgList];
    const _file = newList.find((item: any) => item.index === file.uid);
    if (_file) {
      _file.isRemoved = true
    }
    onContractImgListChange && onContractImgListChange(newList);
  }
  

  const doUpload = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;

    return new Promise<string>((resolve, reject) => {
      uploadFile({
        file,
        onProgress
      }).then(res => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          resolve(getFileUrl(data))
          onSuccess(file);
        }
        reject(res.data)
      }).catch(err => {
        onError({ event: err });
        reject(err)
      });
    })
  }

  const getRoomCost = (roomNoList: string[]) => {
    cachedRoomList.forEach(r => {
      const _room = roomNoList.find(roomNo => roomNo === r.roomNo);
      if (!_room && removeRoom) {
        removeRoom(r.roomNo);
      }
    })

    Promise.all(roomNoList.map(roomNo => getWorkspaceList({ roomNo }))).then((values: any[]) => {
      let totalRent = 0;
      values.forEach((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0 && data.length > 0) {
          totalRent += Number(data[0].officeMonthlyRent) || 0;
          cacheRoom && cacheRoom({
            roomNo: data[0].roomNo,
            rentCost: data[0].officeMonthlyRent,
            rentExpireDate: form?.getFieldValue('expiriteDate'),
          })
        }
      })
      
      if (form) {
        form.setFieldsValue({ 
          contractAmount: totalRent * 12,
        })
      }
    })
  }
  
  return (
    <>
      <Row gutter={[22, 0]}>
        <Col span={12}>
          <FormItem
          label="签约房间"
          name="roomList"
          rules={[{ required: true, message: '请选择签约房间' }]}
          >
            {
              data ? (
                <Text>{data?.roomList}</Text>
              ) : (
                <Select mode="multiple" showSearch onChange={getRoomCost}>
                  {
                    Object.keys(floorRoomList).sort((prev: string, next: string) => {
                      return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
                    }).map((floorName: string, floorIndex: number) => (
                      <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                        {
                          floorRoomList[floorName].map((roomName: string, roomIndex: number) => (
                            <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                          ))
                        }
                      </OptGroup>
                    ))
                  }
                </Select>
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
          label="入驻时间"
          name="checkinDate"
          rules={[{ required: true, message: '请输入入驻时间' }]}
        >
            {
              data ? (
                <Text>{data?.checkinDate}</Text>
              ) : (
                <DatePicker onChange={(val) => {
                  const d = val?.clone()
                  form?.setFieldsValue({
                    expiriteDate: d?.clone().add(12, 'month'),
                    perPayableSchedule: d?.add(6, 'month')
                  })
                }} />
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="到期时间"
            name="expiriteDate"
            rules={[{ required: true, message: '请输入到期时间' }]}
          >
            {
              data ? (
                <Text>{data?.expiriteDate}</Text>
              ) : (
                <DatePicker />
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="合同总金额"
            name="contractAmount"
            rules={[{ required: true, message: '请输入合同总金额' }]}
          >
            {
              data ? (
                <Text>{data?.contractAmount}</Text>
              ) : (
                <Input type="number" />
              )
            }
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label="合同附件" required>
            {
              data ? (
                <ImageList imageKey="contractImage" data={getFileList(ContractImgType.ContractAttachment)} />
              ) : (
                <Upload
                  listType="picture-card"
                  fileList={getFileList(ContractImgType.ContractAttachment)}
                  customRequest={options => {
                    setContractAttachmentUploading(true);
                    doUpload(options)
                      .then(url => {
                        addContractImg(url, ContractImgType.ContractAttachment);
                        setContractAttachmentUploading(false);
                      })
                      .catch(() => setContractAttachmentUploading(false))
                  }}
                  onRemove={removeContractImg}
                  onPreview={(file: any) => window.open(file.fileUrl)}
                >
                  <UploadButton loading={contractAttachmentUploading} />
                </Upload>
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="补充协议标题"
            name="extendProtocolTitle"
          >
          {
              data ? (
                <Text>{data?.extendProtocolTitle}</Text>
              ) : (
                <Input />
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="补充协议简述"
            name="extendProtocolDesc"
          >
          {
              data ? (
                <Text>{data?.extendProtocolDesc}</Text>
              ) : (
                <Input />
              )
            }
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label="补充协议附件">
            {
              data ? (
                <ImageList imageKey="SupplementaryAttachment" data={getFileList(ContractImgType.SupplementaryAttachment)} />
              ) : (
                <Upload
                  listType="picture-card"
                  fileList={getFileList(ContractImgType.SupplementaryAttachment)}
                  customRequest={options => {
                    setSupplementalAgreementUploading(true);
                    doUpload(options)
                      .then(url => {
                        addContractImg(url, ContractImgType.SupplementaryAttachment);
                        setSupplementalAgreementUploading(false);
                      })
                      .catch(() => setSupplementalAgreementUploading(false))
                  }}
                  onRemove={removeContractImg}
                >
                  <UploadButton loading={supplementalAgreementUploading} />
                </Upload>
              )
            }
          </FormItem>
        </Col>
      </Row>
    </>
  )
}

export default CorpStatusForm3;