import { JobStatus } from "@/types/common"

export function setCorpInfo(corpId: number, corpName: string) {
  return {
    type: 'SET_EMPLOYEE_CORP_INFO',
    data: {
      corpId,
      corpName
    }
  }
}

export function setPagination(pageIndex: number, pageSize: number) {
  return {
    type: 'SET_EMPLOYEE_PAGINATION',
    data: {
      pageIndex,
      pageSize
    }
  }
}

export function setJobStatus(jobStatus: JobStatus) {
  return {
    type: 'SET_EMPLOYEE_JOBSTATUS',
    data: jobStatus
  }
}