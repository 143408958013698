import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { breadcrumbNameMap } from '../common/constants';

const BreadcrumbWithRouter = withRouter(({ location }) => {
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        {
          (index === pathSnippets.length - 1 )? (
            breadcrumbNameMap[url]
          ): (
            <Link to={url}>{breadcrumbNameMap[url]}</Link>
          )
        }
      </Breadcrumb.Item>
    )
  });
  
  return (
    <>
      <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>
    </>
  )
})

export default BreadcrumbWithRouter;