import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';

interface Props extends InputProps {

}

const Input = (props: Props) => (
  <AntInput {...props} style={{ height: 32, width: '100%' }} />
)

export default Input;