import { useEffect, useState } from 'react';
import { Input, Row, Col, Modal, Form } from 'antd';

interface Props {
  data: any;
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
}

const ContactEditModal: React.FC<Props> = ({
  data,
  visible,
  onCancel,
  onOk
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data])

  return (
    <Modal
      title="联系人"
      visible={visible}
      closable
      onCancel={onCancel}
      onOk={() => {
        console.log(form.getFieldsValue())
        onOk(form.getFieldsValue());
      }}
    >
      <Form form={form}>
        <Form.Item label="姓名" name="contactName" required>
          <Input />
        </Form.Item>
        <Form.Item label="手机号" name="contactMobile" required>
          <Input type="number" />
        </Form.Item>
        <Form.Item label="邮箱" name="contactEmail" required>
          <Input type="email" />
        </Form.Item>
      </Form>
    </Modal>
  )
}


export default ContactEditModal;