import { Col, Table } from 'antd';
import styled from 'styled-components';

const { Column } = Table;

const Container = styled.div`
  padding: 12px 24px 0 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Label = styled.div`
  color: #646A73;
  font-size: 14px;
  width: 160px;
  text-align: left;
`

const Content = styled.div`
  flex: 1;
  color: #1F2329;
  font-size: 14px;
  font-weight: 400;
`

interface Props {
  data: any;
}

const RoomDailyDetail: React.FC<Props> = ({ data }) => {
  const { roomNo, totalFee, onMinutes, payFee, payConsumeValue, totalConsumeValue, generateDate } = data;
  return (
    <Container>
      <Row>
        <Label>日期：</Label>
        <Content>{generateDate}</Content>
      </Row>
      <Row> 
        <Label>房间号:</Label>
        <Content>{roomNo}</Content>
      </Row>
      <Row>
        <Label>使用时长（分钟）</Label>
        <Content>{onMinutes}</Content>
      </Row>
      <Row>
        <Label>耗电</Label>
        <Content>{totalConsumeValue?.toFixed(4)}</Content>
      </Row>
      <Row>
        <Label>需支付的电量</Label>
        <Content>{payConsumeValue?.toFixed(4)}</Content>
      </Row>
      <Row>
        <Label>总费用：</Label>
        <Content>{totalFee?.toFixed(4)}元</Content>
      </Row>
      <Row>
        <Label>需支付费用：</Label>
        <Content>{payFee?.toFixed(4)}元</Content>
      </Row>
    </Container>
  )
}

export default RoomDailyDetail;