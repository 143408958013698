import { CalendarMode } from "@/types/common";
import MonthMode from './Month';
import DayMode from './Day';

interface Props {
  mode: CalendarMode;
  queryDate: string;
}

const Calendar: React.FC<Props> = ({
  mode,
  queryDate
}) => {
  if (mode === CalendarMode.month) {
    return <MonthMode queryDate={queryDate} />
  }
  if (mode === CalendarMode.day) {
    return <DayMode queryDate={queryDate} />
  }

  return <></>
}

export default Calendar;