import request from '@/utils/request';
import { API_BASE } from '@/common/constants';

interface UploadParams {
  file: any;
  onProgress: (res: any, file: any) => void;
}

export function uploadFile({
  file,
  onProgress
}: UploadParams) {
  const formData = new FormData();
  formData.append('file', file);
  return request.post('/api/logined/upload/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data'
    },
    onUploadProgress: event => {
      onProgress({
        percent: (event.loaded / event.total) * 100
      }, file)
    }
  })
}

export function getFileUrl(filePath: string) {
  return `${API_BASE}/api/notlogined/file/file?filePath=${encodeURIComponent(filePath)}`;
}