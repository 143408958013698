import bg from '@/assets/floor_plan/7/7lou.png';
import img_701 from '@/assets/floor_plan/7/common/701.png';
import img_702 from '@/assets/floor_plan/7/common/702.png';
import img_703 from '@/assets/floor_plan/7/common/703.png';
import img_705 from '@/assets/floor_plan/7/common/705.png';
import img_706 from '@/assets/floor_plan/7/common/706.png';
import img_707 from '@/assets/floor_plan/7/common/707.png';
import img_708 from '@/assets/floor_plan/7/common/708.png';
import img_709 from '@/assets/floor_plan/7/common/709.png';
import img_710 from '@/assets/floor_plan/7/common/710.png';
import img_711 from '@/assets/floor_plan/7/common/711.png';
import img_712 from '@/assets/floor_plan/7/common/712.png';
import img_713 from '@/assets/floor_plan/7/common/713.png';
import img_715 from '@/assets/floor_plan/7/common/715.png';
import img_701_selected from '@/assets/floor_plan/7/selected/701.png';
import img_702_selected from '@/assets/floor_plan/7/selected/702.png';
import img_703_selected from '@/assets/floor_plan/7/selected/703.png';
import img_705_selected from '@/assets/floor_plan/7/selected/705.png';
import img_706_selected from '@/assets/floor_plan/7/selected/706.png';
import img_707_selected from '@/assets/floor_plan/7/selected/707.png';
import img_708_selected from '@/assets/floor_plan/7/selected/708.png';
import img_709_selected from '@/assets/floor_plan/7/selected/709.png';
import img_710_selected from '@/assets/floor_plan/7/selected/710.png';
import img_711_selected from '@/assets/floor_plan/7/selected/711.png';
import img_712_selected from '@/assets/floor_plan/7/selected/712.png';
import img_713_selected from '@/assets/floor_plan/7/selected/713.png';
import img_715_selected from '@/assets/floor_plan/7/selected/715.png';
import img_701_expire from '@/assets/floor_plan/7/expire/701.png';
import img_702_expire from '@/assets/floor_plan/7/expire/702.png';
import img_703_expire from '@/assets/floor_plan/7/expire/703.png';
import img_705_expire from '@/assets/floor_plan/7/expire/705.png';
import img_706_expire from '@/assets/floor_plan/7/expire/706.png';
import img_707_expire from '@/assets/floor_plan/7/expire/707.png';
import img_708_expire from '@/assets/floor_plan/7/expire/708.png';
import img_709_expire from '@/assets/floor_plan/7/expire/709.png';
import img_710_expire from '@/assets/floor_plan/7/expire/710.png';
import img_711_expire from '@/assets/floor_plan/7/expire/711.png';
import img_712_expire from '@/assets/floor_plan/7/expire/712.png';
import img_713_expire from '@/assets/floor_plan/7/expire/713.png';
import img_715_expire from '@/assets/floor_plan/7/expire/715.png';
import img_701_mismatch from '@/assets/floor_plan/7/mismatch/701.png';
import img_702_mismatch from '@/assets/floor_plan/7/mismatch/702.png';
import img_703_mismatch from '@/assets/floor_plan/7/mismatch/703.png';
import img_705_mismatch from '@/assets/floor_plan/7/mismatch/705.png';
import img_706_mismatch from '@/assets/floor_plan/7/mismatch/706.png';
import img_707_mismatch from '@/assets/floor_plan/7/mismatch/707.png';
import img_708_mismatch from '@/assets/floor_plan/7/mismatch/708.png';
import img_709_mismatch from '@/assets/floor_plan/7/mismatch/709.png';
import img_710_mismatch from '@/assets/floor_plan/7/mismatch/710.png';
import img_711_mismatch from '@/assets/floor_plan/7/mismatch/711.png';
import img_712_mismatch from '@/assets/floor_plan/7/mismatch/712.png';
import img_713_mismatch from '@/assets/floor_plan/7/mismatch/713.png';
import img_715_mismatch from '@/assets/floor_plan/7/mismatch/715.png';


const width = (w: number) => `${100 * (w / 987)}%`;
const left = (l: number) => `${100 * (l - 300) / 987}%`;
const top = (t: number) => `${100 * (t - 276) / 404}%`;

const initialRoomListData = [
  {
    title: '701',
    roomNo: '701',
    width: width(137),
    left: left(303),
    top: top(279),
    image: img_701,
    selectedImage: img_701_selected,
    expireImage: img_701_expire,
    mismatchImage: img_701_mismatch
  },
  {
    title: '702',
    roomNo: '702',
    width: width(118),
    left: left(442),
    top: top(279),
    image: img_702,
    selectedImage: img_702_selected,
    expireImage: img_702_expire,
    mismatchImage: img_702_mismatch
  },
  {
    title: '703',
    roomNo: '703',
    width: width(176),
    left: left(610),
    top: top(279),
    image: img_703,
    selectedImage: img_703_selected,
    expireImage: img_703_expire,
    mismatchImage: img_703_mismatch
  },
  {
    title: '705',
    roomNo: '705',
    width: width(176),
    left: left(788),
    top: top(279),
    image: img_705,
    selectedImage: img_705_selected,
    expireImage: img_705_expire,
    mismatchImage: img_705_mismatch
  },
  {
    title: '706',
    roomNo: '706',
    width: width(176),
    left: left(966),
    top: top(279),
    image: img_706,
    selectedImage: img_706_selected,
    expireImage: img_706_expire,
    mismatchImage: img_706_mismatch
  },
  {
    title: '707',
    roomNo: '707',
    width: width(140),
    left: left(1144),
    top: top(279),
    image: img_707,
    selectedImage: img_707_selected,
    expireImage: img_707_expire,
    mismatchImage: img_707_mismatch
  },
  {
    title: '708',
    roomNo: '708',
    width: width(100),
    left: left(1184),
    top: top(421),
    image: img_708,
    selectedImage: img_708_selected,
    expireImage: img_708_expire,
    mismatchImage: img_708_mismatch
  },
  {
    title: '709',
    roomNo: '709',
    width: width(140),
    left: left(1144),
    top: top(577),
    image: img_709,
    selectedImage: img_709_selected,
    expireImage: img_709_expire,
    mismatchImage: img_709_mismatch
  },
  {
    title: '710',
    roomNo: '710',
    width: width(114),
    left: left(1028),
    top: top(577),
    image: img_710,
    selectedImage: img_710_selected,
    expireImage: img_710_expire,
    mismatchImage: img_710_mismatch
  },
  {
    title: '712',
    roomNo: '712',
    width: width(116),
    left: left(794),
    top: top(577),
    image: img_712,
    selectedImage: img_712_selected,
    expireImage: img_712_expire,
    mismatchImage: img_712_mismatch
  },
  {
    title: '711',
    roomNo: '711',
    width: width(172),
    left: left(620),
    top: top(577),
    image: img_711,
    selectedImage: img_711_selected,
    expireImage: img_711_expire,
    mismatchImage: img_711_mismatch
  },
  {
    title: '713',
    roomNo: '713',
    width: width(172),
    left: left(446),
    top: top(577),
    image: img_713,
    selectedImage: img_713_selected,
    expireImage: img_713_expire,
    mismatchImage: img_713_mismatch
  },
  {
    title: '715',
    roomNo: '715',
    width: width(141),
    left: left(303),
    top: top(545),
    image: img_715,
    selectedImage: img_715_selected,
    expireImage: img_715_expire,
    mismatchImage: img_715_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}