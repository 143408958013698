import { Modal, Form, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { addSalePurpose, updateSalePurpose } from '@/services/workspace';

interface Props {
  id?: number | string;
  roomNo: string;
  visible: boolean;
  data: any;
  onClose: () => void;
}

const SalePurposeEdit: React.FC<Props> = ({
  visible,
  id,
  roomNo,
  data,
  onClose,
}) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.validateFields().then((values: any) => {
      const fn = id ? updateSalePurpose : addSalePurpose;
      fn({
        ...values,
        ...( id ? { id } : {} ),
        roomNo,
      }).then((res: any) => {
        const { resultCode } = res.data;
        if (resultCode === 0) {
          message.success('保存成功')
        }
        else {
          message.error('保存失败')
        }
        onClose()
      })
    })
  }

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])

  return (
    <Modal
      visible={visible}
      title={ id ? '编辑销售情况跟踪' : '添加销售情况跟踪'}
      onCancel={onClose}
      onOk={onOk}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="销售跟踪情况（客户反馈）"
          name="saleInfo"
          rules={[{ required: true, message: '请输入销售跟踪情况' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="销售人员"
          name="salesman"
          rules={[{ required: true, message: '请输入销售人员' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SalePurposeEdit