import bg from '@/assets/floor_plan/12/12lou.png';
import img_1201 from '@/assets/floor_plan/12/common/1201.png';
import img_1202 from '@/assets/floor_plan/12/common/1202.png';
// import img_1203 from '@/assets/floor_plan/12/common/1203.png';
import img_1205 from '@/assets/floor_plan/12/common/1205.png';
import img_1203 from '@/assets/floor_plan/12/common/1203-new.png';
import img_1206 from '@/assets/floor_plan/12/common/1206-new.png';
import img_1201_selected from '@/assets/floor_plan/12/selected/1201.png';
import img_1202_selected from '@/assets/floor_plan/12/selected/1202.png';
import img_1203_selected from '@/assets/floor_plan/12/selected/1203-new.png';
import img_1205_selected from '@/assets/floor_plan/12/selected/1205.png';
import img_1206_selected from '@/assets/floor_plan/12/selected/1206-new.png';
import img_1201_expire from '@/assets/floor_plan/12/expire/1201.png';
import img_1202_expire from '@/assets/floor_plan/12/expire/1202.png';
import img_1203_expire from '@/assets/floor_plan/12/expire/1203-new.png';
import img_1205_expire from '@/assets/floor_plan/12/expire/1205.png';
import img_1206_expire from '@/assets/floor_plan/12/expire/1206-new.png';
import img_1201_mismatch from '@/assets/floor_plan/12/mismatch/1201.png';
import img_1202_mismatch from '@/assets/floor_plan/12/mismatch/1202.png';
import img_1203_mismatch from '@/assets/floor_plan/12/mismatch/1203-new.png';
import img_1205_mismatch from '@/assets/floor_plan/12/mismatch/1205.png';
import img_1206_mismatch from '@/assets/floor_plan/12/mismatch/1206-new.png';

const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;

const initialRoomListData = [
  {
    title: '1201',
    roomNo: '1201',
    width: width(368),
    left: left(447),
    top: top(280),
    image: img_1201,
    selectedImage: img_1201_selected,
    expireImage: img_1201_expire,
    mismatchImage: img_1201_mismatch
  },
  {
    title: '1202',
    roomNo: '1202',
    width: width(346),
    left: left(817),
    top: top(280),
    image: img_1202,
    selectedImage: img_1202_selected,
    expireImage: img_1202_expire,
    mismatchImage: img_1202_mismatch
  },
  // {
  //   title: '1203',
  //   titleLeft: `${100 * (956 - 783) / 380}%`,
  //   titleTop: `${100 * (618 - 434) / 380}%`,
  //   roomNo: '1203',
  //   width: width(380),
  //   left: left(783),
  //   top: top(434),
  //   image: img_1203,
  //   selectedImage: img_1203_selected,
  //   expireImage: img_1203_expire,
  //   mismatchImage: img_1203_mismatch
  // },
  {
    title: '1203',
    roomNo: '1203',
    width: width(270),
    left: left(783),
    top: top(574),
    image: img_1203,
    selectedImage: img_1203_selected,
    expireImage: img_1203_expire,
    mismatchImage: img_1203_mismatch
  },
  {
    title: '1205',
    titleLeft: `${100 * (573 - 401) / 380}%`,
    titleTop: `${100 * (618 - 418) / 380}%`,
    titleWidth: 'auto',
    roomNo: '1205',
    width: width(380),
    left: left(401),
    top: top(418),
    image: img_1205,
    selectedImage: img_1205_selected,
    expireImage: img_1205_expire,
    mismatchImage: img_1205_mismatch
  },
  {
    title: '1206',
    roomNo: '1206',
    width: width(108),
    left: left(1055),
    top: top(434),
    image: img_1206,
    selectedImage: img_1206_selected,
    expireImage: img_1206_expire,
    mismatchImage: img_1206_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}