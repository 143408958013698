import { useEffect, useMemo, useState } from 'react';
import { Modal, Input, Form, message, Select, Spin } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { addBookingRecord } from '@/services/meeting';
import { getCompanyList, getEmployeeList } from '@/services/company';
import img_clock from '@/assets/clock@2x.png';
import img_crop from '@/assets/corp@2x.png';
import img_note from '@/assets/notes-o@2x.png';
import img_user from '@/assets/team@2x.png';
import img_meeting from '@/assets/meeting-board@2x.png';

const FormItem = Form.Item;
const { TextArea } = Input;

const Title = styled.div`
  width: 100%;
  text-align: center;
  color: #1F2329;
  font-size: 16px;
  font-weight: bold;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > .text {
    padding-left: 12px;
  }
`

const TimePanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
`

const Time = styled.div`
  padding: 0 8px;
  color: #1F2329;
  font-size: 14px;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
`

interface LabelProps {
  icon: string;
  text: string;
}

const Label: React.FC<LabelProps> = ({
  icon,
  text
}) => (
  <LabelContainer>
    <Icon src={icon} />
    <div className="text">{text}</div>
  </LabelContainer>
)

interface Props {
  roomNo: string;
  floorNo?: string;
  beginTime: string;
  endTime: string;
  visible: boolean;
  onClose: () => void;
  onOk?: () => void;
}

const BookingModal: React.FC<Props> = ({
  roomNo,
  floorNo,
  beginTime,
  endTime,
  visible,
  onClose,
  onOk
}) => {
  const [form] = Form.useForm();
  const userId = useSelector((state: any) => state.user.id);
  const [fetching, setFetching] = useState(false);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [employeeList, setEmployeeList] = useState<any[]>([]);

  const handleOk = () => {
    form.validateFields().then((values: any) => {
      console.log(values)
      addBookingRecord({
        ...values,
        beginTime: moment(beginTime).format('HH:mm'),
        endTime: moment(endTime).format('HH:mm'),
        useDate: moment(beginTime).format('YYYY-MM-DD'),
        roomNo,
        floorNo
      }).then((res: any) => {
        form.resetFields();
        const { resultCode, resultMsg } = res.data;
        if (resultCode === 0) {
          message.success('预约成功')
          onOk && onOk();
          return;
        }

        message.error(resultMsg)
      }).catch(() => {
        message.success('预约失败，服务请求异常。')
      })
    })
  }

  const searchCompany = useMemo(() => {
    return debounce((value: string) => {
      setFetching(true);
      getCompanyList({
        corpName: value,
        currentPage: 1,
        pageSize: 100
      }).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          setCompanyList(data.map((d: any) => ({
            label: d.corpName,
            value: d.corpId
          })))
        }
        setFetching(false)
      }).catch(() => {
        setFetching(false)
      })
    }, 1000)
  }, [])

  const getEmployeeByCorpId = (corpId: number) => {
    getEmployeeList({
      corpId,
      currentPage: 1,
      pageSize: 100
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setEmployeeList(data.map((d: any) => ({
          label: d.realName,
          value: d.id
        })))
      }
    })
  }

  useEffect(() => {
    searchCompany('');
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      title={<Title>创建会议</Title>}
      onOk={handleOk}
      okText="保存"
      okButtonProps={{
        style: { backgroundColor: '#3371FF'}
      }}
    >
      <Form form={form} labelCol={{ span: 6 }} labelAlign="left" colon={false}>
        <FormItem name="meetingSubject">
          <TextArea />
        </FormItem>
        <TimePanel>
          <Icon src={img_clock} />
          <Time>{moment(beginTime).format('YYYY年M月D日 HH:mm')}</Time>
          {' ~ '}
          <Time>{moment(endTime).format('YYYY年M月D日 HH:mm')}</Time>
        </TimePanel>
        <FormItem label={<Label icon={img_meeting} text="会议室" />}>{roomNo}</FormItem>
        <FormItem label={<Label icon={img_crop} text="公司" />} name="corpId">
          <Select
            showSearch
            showArrow={false}
            filterOption={false}
            onSearch={searchCompany}
            onChange={getEmployeeByCorpId}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={companyList}
          />
        </FormItem>
        <FormItem label={<Label icon={img_user} text="成员" />} name="bookingUserId">
          <Select
            options={employeeList}
          />
        </FormItem>
        <FormItem label={<Label icon={img_clock} text="提醒" />}>15分钟前</FormItem>
        <FormItem label={<Label icon={img_note} text="备注" />} name="cmt">
          <TextArea />
        </FormItem>
      </Form>
    </Modal>
  )
}

export default BookingModal;