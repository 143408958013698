import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { getOperateLogList } from '@/services/user';

const { Column } = Table

const OperateLog: React.FC = () => {
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [count, setCount] = useState(0)

  const getData = () => {
    getOperateLogList().then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Table
        dataSource={dataSource}
      >
        <Column title="手机号码" dataIndex="mobileNo" />
        <Column title="操作内容" dataIndex="operateContent" width={300} ellipsis />
        <Column title="操作类型" dataIndex="operateType" />
        <Column title="用户名" dataIndex="userName" />
        <Column title="操作时间" dataIndex="createTime" />
      </Table>
    </>
  )
}

export default OperateLog