import { JobStatus } from '@/types/common';
import produce from 'immer';

type EmployeeStateType = {
  corpId: number;
  corpName: string;
  pageIndex: number;
  pageSize: number;
  jobStatus: JobStatus;
}

const initialState: EmployeeStateType = {
  corpId: 0,
  corpName: '',
  pageIndex: 1,
  pageSize: 100,
  jobStatus: JobStatus.OnTheJob
}

const reducer = (state = initialState, action: any) =>
  produce(state, (draft): void => {
    switch(action.type) {
      case 'SET_EMPLOYEE_PAGINATION':
        draft.pageIndex = action.data.pageIndex;
        draft.pageSize = action.data.pageSize;
        break;
      case 'SET_EMPLOYEE_JOBSTATUS':
        draft.jobStatus = action.data;
        break;
      case 'SET_EMPLOYEE_CORP_INFO':
        draft.corpId = action.data.corpId;
        draft.corpName = action.data.corpName;
        break;
    }
  })

export default reducer;