import { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FileUpload from '@/components/FileUpload';
import { addFile } from '@/services/knowledge';

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

interface Props {
  groupId?: number | string;
  bigCategoryId?: number | string;
  smallCategoryId?: number | string;
  onClose: () => void;
}

const ContentEditor: React.FC<Props> = ({
  bigCategoryId,
  smallCategoryId,
  groupId,
  onClose
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false)

  const onSave = () => {
    setLoading(true);
    form.validateFields().then((values: any) => {
      addFile({
        ...values,
        bigCategoryId,
        smallCategoryId,
        groupId
      }).then((res: any) => {
        const { resultCode, resultMsg } = res.data;
        setLoading(false);
        if (resultCode === 0) {
          message.success('文件已保存');
          onClose();
          return;
        }
        message.error(resultMsg);
      }).catch(() => {
        message.error('保存失败');
        setLoading(false);
        onClose();
      })
    })
  }

  return (
    <Form {...layout} form={form}>
      <FormItem name="fileName" label="文件名">
        <Input />
      </FormItem>
      <FormItem name="fileDescription" label="文件描述">
        <Input.TextArea />
      </FormItem>
      <FormItem name="filePath" label="上传">
        <FileUpload onChange={(url: string, fileName?: string) => {
          if (!form.getFieldValue('fileName') && fileName) {
            form.setFieldsValue({ fileName })
          }
        }} />
      </FormItem>
      <Button type="primary" loading={loading} onClick={onSave}>保存</Button>
    </Form>
  )
}

export default ContentEditor;