import produce from 'immer';
import { Company } from '@/types/common';

type CompanyStateType = {
  companyList: Company[];
  selectedCorpId: number;
  pageIndex: number;
  pageSize: number;
  corpName: string;
  contractStatus: string;
}

const initialState: CompanyStateType = {
  companyList: [],
  selectedCorpId: 0,
  pageIndex: 1,
  pageSize: 100,
  corpName: '',
  contractStatus: '',
}

const reducer = (state = initialState, action: any) =>
  produce(state, (draft): void => {
    switch(action.type) {
      case 'SET_SELECTED_CORP_ID':
        draft.selectedCorpId = action.data;
        break;
      case 'SET_CORP_PAGINATION':
        draft.pageIndex = action.data.pageIndex;
        draft.pageSize = action.data.pageSize;
        break;
      case 'SET_CORP_NAME':
        draft.corpName = action.data;
        break;
      case 'SET_CORP_CONTRACT_STATUS':
        draft.contractStatus = action.data;
    }
  });

export default reducer