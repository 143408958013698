import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import PermissionManagement from '../PermissonManagement';
import PermissionEdit from '../PermissonManagement/Edit';

const PersonnelFiling = () => {
  return (
    <>
      <Switch>
        <Route path="/personnelfiling/permission/edit/:userId"><PermissionEdit /></Route>
        <Route path="/personnelfiling/permission"><PermissionManagement /></Route>
      </Switch>
    </>
  )
}

export default PersonnelFiling;