import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface Props {
  loading: boolean;
}

const UploadButton: React.FC<Props> = ({
  loading
}) => (
  <Container>
    {loading ? <LoadingOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </Container>
)

export default UploadButton;