import { useEffect, useState } from 'react';
import styles from './style.module.less';
import Cell from './Cell';
import AppointmentCell from './AppointmentCell';

const weekDayList = [
  {
    date: '12/14',
    weekDay: '一'
  },
  {
    date: '12/15',
    weekDay: '二'
  },
  {
    date: '12/16',
    weekDay: '三'
  },
  {
    date: '12/17',
    weekDay: '四'
  },
  {
    date: '12/18',
    weekDay: '五'
  },
  {
    date: '12/19',
    weekDay: '六'
  },
  {
    date: '12/20',
    weekDay: '日'
  },
]

const timeList = [
  { title: '上午9点' },
  { title: '上午10点' },
  { title: '上午11点' },
  { title: '中午12点' },
  { title: '下午1点' },
  { title: '下午2点' },
  { title: '下午3点' },
  { title: '下午4点' },
  { title: '下午5点' },
  { title: '下午6点' },
  { title: '下午7点' },
]

const dateTimeCellListDefaultValue: Array<Array<any>> = []

const mockData = [
  { begin: '2021-02-01 10:00', end: '2021-02-01 12:00', left: 0, top: 0, height: 0 }
]


timeList.forEach(() => {
  dateTimeCellListDefaultValue.push([]);
  weekDayList.forEach(() => {
    dateTimeCellListDefaultValue[dateTimeCellListDefaultValue.length - 1].push({
      left: 0,
      top: 0
    })
  })
})

const Calendar = () => {
  const [dateTimeCellList, setDateTimeCelList] = useState<Array<Array<any>>>(dateTimeCellListDefaultValue)
  const [data, setData] = useState(mockData);

  const updateDateTimeCell = (row: number, col: number, left: number, top: number) => {
    const cell = dateTimeCellList[row][col];
    if (cell) {
      cell.left = left;
      cell.top = top;
    }

    setDateTimeCelList(dateTimeCellList);
  }

  useEffect(() => {
    console.log('hello', dateTimeCellList)
    
    mockData.forEach(item => {
      const beginDate = new Date(item.begin);
      const endDate = new Date(item.end);
      console.log('beginDate:', beginDate.getHours());
      const beginHour = beginDate.getHours();
      const endHour = endDate.getHours();

      const { left, top } = dateTimeCellList[beginHour-9][2]
      
      const height = (endHour - beginHour) * 60

      console.log(`left: ${left}, top: ${top}, beginHour: ${beginHour} height: ${height}`)

      item.left = left;
      item.top = top;
      item.height = height;
    })

    setData(mockData)
  }, [dateTimeCellList])
  
  return (
    <div className={styles.container}>
      <div className={styles.controlBar}>contorl bar</div>
      <div className={styles.dateContainer}>
        <div className={styles.dateCellBlank}></div>
        {
          weekDayList.map((item, index) => {
            return (
              <div key={`date_${index}`} className={styles.dateCell}>
                {`${item.weekDay} ${item.date}`}
              </div>
            )
          })
        }
      </div>
      <div className={styles.row}>
        <div className={styles.timeLineContainer}>
          {
            timeList.map((item, index) => (
              <div key={`timeline_${index}`} className={styles.timeLineCell}>{item.title}</div>
            ))
          }
        </div>
        <div className={styles.dateTimeContainer}>
          {
            timeList.map((time, rowIndex) => (
              <div key={`datetime_row_${rowIndex}`} className={styles.row}>
                {
                  weekDayList.map((weekDay, colIndex) => (
                    <Cell
                      key={`datetime_${rowIndex}_cell_${colIndex}`} 
                      rowIndex={rowIndex}
                      colIndex={colIndex}
                      onChange={updateDateTimeCell}
                    />
                  ))
                }
              </div>
            ))
          }
          {
            data.map((item) => {
              const { left, top, height } = item;

              return (
                <AppointmentCell left={left} top={top} height={height}>lss</AppointmentCell>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Calendar;