export enum DeviceStatus {
  using = 0,
  idle = 1,
  repari = 2,
  damaged = 3
}

export enum DeviceType {
  
}

export enum NetworkStatus {
  on = 0,
  off = 1
}

export interface DeviceListParams {
  belongFloor?: string;
  belongRoom?: string;
  currentPage: number;
  deviceId?: number;
  deviceNo?: string;
  deviceStatus?: DeviceStatus;
  // deviceType?: DeviceType; // TODO: 设备类型这个字段在原型中没有出现
  networkStatus?: NetworkStatus;
  pageSize?: number;
  assetType?: 0 | 1;
}

export interface DeviceInfo {
  belongFloor?: string;
  belongRoom?: string;
  createTime?: string;
  deviceId?: number | string;
  deviceModel?: string;
  deviceName?: string;
  deviceNo?: string;
  deviceStatus?: DeviceStatus;
  deviceType?: number;
  networkStatus?: NetworkStatus;
  updateTime?: string;
  assetType?: 0 | 1;
}

export enum FanMode {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export enum HVACMode {
  off = 'off',
  cool = 'cool',
  dry = 'dry',
  fanOnly = 'fan_only',
  heat = 'heat'
}