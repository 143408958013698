import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Space, Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getUserInfoList, getUserInfoCount } from '@/services/user';
import { Link } from 'react-router-dom';

const { Column } = Table;

const SearchBar = styled.div`
  margin-bottom: 22px;
`

const PermissionManagement = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [queryValue, setQueryValue] = useState('');

  const getData = (page = 1, pageSize = 30) => {
    setLoading(true);
    getUserInfoList({
      currentPage: page,
      pageSize,
      mobileNo: queryValue,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
      setPageIndex(page);
      setPageSize(pageSize);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    getUserInfoCount({
      mobileNo: queryValue
    }).then((res: any) => {
      const { resultCode, data }  = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    getData(page, pageSize_);
  }

  useEffect(() => {
    getData();
  }, [queryValue])

  return (
    <>
      <SearchBar>
        <Input
          prefix={<SearchOutlined />}
          placeholder="请输入手机号"
          style={{ width: 260 }}
          onChange={e => setQueryValue(e.target.value)}
        />
      </SearchBar>
      <Table
        rowKey={(record: any) => `userinfo_${record.userId}`}
        dataSource={dataSource}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: dataCount,
          onChange: onPagination,
          showSizeChanger: true
        }}
      >
        <Column title="人员"  dataIndex="realName" />
        <Column title="手机"  dataIndex="mobileNo" />
        <Column
          title="权限" 
          dataIndex="roleList"
          render={(_: any) => { 
            const result = _.reduce((current: string, next: any) => {
              return `${next.roleDisplayName}, ${current}`
            }, '') as string;
            return result.substring(0, result.length - 2);
          }}
        />
        <Column
          title="操作"
          dataIndex="userId"
          render={_ => (
            <Space>
              <Button type="link"><Link to={`/personnelfiling/permission/edit/${_}`}>编辑</Link></Button>
            </Space>
          )}
        />
      </Table>
    </>
  )
}

export default PermissionManagement;