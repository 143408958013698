import bg from '@/assets/floor_plan/6/6lou.png';

import img_601 from '@/assets/floor_plan/6/common/601.png';
import img_602 from '@/assets/floor_plan/6/common/602.png';
import img_603 from '@/assets/floor_plan/6/common/603.png';
import img_605 from '@/assets/floor_plan/6/common/605.png';
import img_606 from '@/assets/floor_plan/6/common/606.png';
import img_607 from '@/assets/floor_plan/6/common/607.png';
import img_608 from '@/assets/floor_plan/6/common/608.png';
import img_609 from '@/assets/floor_plan/6/common/609.png';
import img_610 from '@/assets/floor_plan/6/common/610.png';
import img_611 from '@/assets/floor_plan/6/common/611.png';
import img_612_1 from '@/assets/floor_plan/6/common/612-1.png';
import img_613 from '@/assets/floor_plan/6/common/613.png';
import img_615 from '@/assets/floor_plan/6/common/615.png';
import img_616 from '@/assets/floor_plan/6/common/616.png';
import img_617 from '@/assets/floor_plan/6/common/617.png';
import img_618 from '@/assets/floor_plan/6/common/618.png';
import img_619 from '@/assets/floor_plan/6/common/619.png';
import img_620 from '@/assets/floor_plan/6/common/620.png';
import img_621 from '@/assets/floor_plan/6/common/621.png';
import img_622 from '@/assets/floor_plan/6/common/622.png';
import img_623 from '@/assets/floor_plan/6/common/623.png';
import img_625 from '@/assets/floor_plan/6/common/625.png';
import img_601_selected from '@/assets/floor_plan/6/selected/601.png';
import img_602_selected from '@/assets/floor_plan/6/selected/602.png';
import img_603_selected from '@/assets/floor_plan/6/selected/603.png';
import img_605_selected from '@/assets/floor_plan/6/selected/605.png';
import img_606_selected from '@/assets/floor_plan/6/selected/606.png';
import img_607_selected from '@/assets/floor_plan/6/selected/607.png';
import img_608_selected from '@/assets/floor_plan/6/selected/608.png';
import img_609_selected from '@/assets/floor_plan/6/selected/609.png';
import img_610_selected from '@/assets/floor_plan/6/selected/610.png';
import img_611_selected from '@/assets/floor_plan/6/selected/611.png';
import img_612_1_selected from '@/assets/floor_plan/6/selected/612-1.png';
import img_613_selected from '@/assets/floor_plan/6/selected/613.png';
import img_615_selected from '@/assets/floor_plan/6/selected/615.png';
import img_616_selected from '@/assets/floor_plan/6/selected/616.png';
import img_617_selected from '@/assets/floor_plan/6/selected/617.png';
import img_618_selected from '@/assets/floor_plan/6/selected/618.png';
import img_619_selected from '@/assets/floor_plan/6/selected/619.png';
import img_620_selected from '@/assets/floor_plan/6/selected/620.png';
import img_621_selected from '@/assets/floor_plan/6/selected/621.png';
import img_622_selected from '@/assets/floor_plan/6/selected/622.png';
import img_623_selected from '@/assets/floor_plan/6/selected/623.png';
import img_625_selected from '@/assets/floor_plan/6/selected/625.png';
import img_601_expire from '@/assets/floor_plan/6/expire/601.png';
import img_602_expire from '@/assets/floor_plan/6/expire/602.png';
import img_603_expire from '@/assets/floor_plan/6/expire/603.png';
import img_605_expire from '@/assets/floor_plan/6/expire/605.png';
import img_606_expire from '@/assets/floor_plan/6/expire/606.png';
import img_607_expire from '@/assets/floor_plan/6/expire/607.png';
import img_608_expire from '@/assets/floor_plan/6/expire/608.png';
import img_609_expire from '@/assets/floor_plan/6/expire/609.png';
import img_610_expire from '@/assets/floor_plan/6/expire/610.png';
import img_611_expire from '@/assets/floor_plan/6/expire/611.png';
import img_612_1_expire from '@/assets/floor_plan/6/expire/612-1.png';
import img_613_expire from '@/assets/floor_plan/6/expire/613.png';
import img_615_expire from '@/assets/floor_plan/6/expire/615.png';
import img_616_expire from '@/assets/floor_plan/6/expire/616.png';
import img_617_expire from '@/assets/floor_plan/6/expire/617.png';
import img_618_expire from '@/assets/floor_plan/6/expire/618.png';
import img_619_expire from '@/assets/floor_plan/6/expire/619.png';
import img_620_expire from '@/assets/floor_plan/6/expire/620.png';
import img_621_expire from '@/assets/floor_plan/6/expire/621.png';
import img_622_expire from '@/assets/floor_plan/6/expire/622.png';
import img_623_expire from '@/assets/floor_plan/6/expire/623.png';
import img_625_expire from '@/assets/floor_plan/6/expire/625.png';
import img_601_mismatch from '@/assets/floor_plan/6/mismatch/601.png';
import img_602_mismatch from '@/assets/floor_plan/6/mismatch/602.png';
import img_603_mismatch from '@/assets/floor_plan/6/mismatch/603.png';
import img_605_mismatch from '@/assets/floor_plan/6/mismatch/605.png';
import img_606_mismatch from '@/assets/floor_plan/6/mismatch/606.png';
import img_607_mismatch from '@/assets/floor_plan/6/mismatch/607.png';
import img_608_mismatch from '@/assets/floor_plan/6/mismatch/608.png';
import img_609_mismatch from '@/assets/floor_plan/6/mismatch/609.png';
import img_610_mismatch from '@/assets/floor_plan/6/mismatch/610.png';
import img_611_mismatch from '@/assets/floor_plan/6/mismatch/611.png';
import img_612_1_mismatch from '@/assets/floor_plan/6/mismatch/612-1.png';
import img_613_mismatch from '@/assets/floor_plan/6/mismatch/613.png';
import img_615_mismatch from '@/assets/floor_plan/6/mismatch/615.png';
import img_616_mismatch from '@/assets/floor_plan/6/mismatch/616.png';
import img_617_mismatch from '@/assets/floor_plan/6/mismatch/617.png';
import img_618_mismatch from '@/assets/floor_plan/6/mismatch/618.png';
import img_619_mismatch from '@/assets/floor_plan/6/mismatch/619.png';
import img_620_mismatch from '@/assets/floor_plan/6/mismatch/620.png';
import img_621_mismatch from '@/assets/floor_plan/6/mismatch/621.png';
import img_622_mismatch from '@/assets/floor_plan/6/mismatch/622.png';
import img_623_mismatch from '@/assets/floor_plan/6/mismatch/623.png';
import img_625_mismatch from '@/assets/floor_plan/6/mismatch/625.png';


const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 312) / 328}%`;

const initialRoomList = [
  {
    title: '601',
    roomNo: '601',
    width: width(110),
    left: left(300),
    top: top(315),
    image: img_601,
    selectedImage: img_601_selected,
    expireImage: img_601_expire,
    mismatchImage: img_601_mismatch
  },
  {
    title: '603',
    roomNo: '603',
    width: width(88),
    left: left(412),
    top: top(315),
    image: img_603,
    selectedImage: img_603_selected,
    expireImage: img_603_expire,
    mismatchImage: img_603_mismatch
  },
  {
    title: '605',
    roomNo: '605',
    width: width(98),
    left: left(502),
    top: top(315),
    image: img_605,
    selectedImage: img_605_selected,
    expireImage: img_605_expire,
    mismatchImage: img_605_mismatch
  },
  {
    title: '607',
    roomNo: '607',
    width: width(94),
    left: left(602),
    top: top(315),
    image: img_607,
    selectedImage: img_607_selected,
    expireImage: img_607_expire,
    mismatchImage: img_607_mismatch
  },
  {
    title: '606',
    roomNo: '606',
    width: width(52),
    left: left(738),
    top: top(315),
    image: img_606,
    selectedImage: img_606_selected,
    expireImage: img_606_expire,
    mismatchImage: img_606_mismatch
  },
  {
    title: '608',
    roomNo: '608',
    width: width(94),
    left: left(792),
    top: top(315),
    image: img_608,
    selectedImage: img_608_selected,
    expireImage: img_608_expire,
    mismatchImage: img_608_mismatch
  },
  {
    title: '609',
    roomNo: '609',
    width: width(94),
    left: left(888),
    top: top(315),
    image: img_609,
    selectedImage: img_609_selected,
    expireImage: img_609_expire,
    mismatchImage: img_609_mismatch
  },
  {
    title: '610',
    roomNo: '610',
    width: width(48),
    left: left(1076),
    top: top(315),
    image: img_610,
    selectedImage: img_610_selected,
    expireImage: img_610_expire,
    mismatchImage: img_610_mismatch
  },
  {
    title: '611',
    roomNo: '611',
    width: width(48),
    left: left(1126),
    top: top(315),
    image: img_611,
    selectedImage: img_611_selected,
    expireImage: img_611_expire,
    mismatchImage: img_611_mismatch
  },
  {
    title: '612',
    roomNo: '612',
    width: width(98),
    left: left(1176),
    top: top(315),
    image: img_612_1,
    selectedImage: img_612_1_selected,
    expireImage: img_612_1_expire,
    mismatchImage: img_612_1_mismatch
  },
  {
    title: '613',
    roomNo: '613',
    width: width(70),
    left: left(1204),
    top: top(431),
    image: img_613,
    selectedImage: img_613_selected,
    expireImage: img_613_expire,
    mismatchImage: img_613_mismatch
  },
  {
    title: '615',
    roomNo: '615',
    width: width(100),
    left: left(1174),
    top: top(557),
    image: img_615,
    selectedImage: img_615_selected,
    expireImage: img_615_expire,
    mismatchImage: img_615_mismatch
  },
  {
    title: '616',
    roomNo: '616',
    width: width(90),
    left: left(1082),
    top: top(557),
    image: img_616,
    selectedImage: img_616_selected,
    expireImage: img_616_expire,
    mismatchImage: img_616_mismatch
  },
  {
    title: '617',
    roomNo: '617',
    width: width(98),
    left: left(888),
    top: top(557),
    image: img_617,
    selectedImage: img_617_selected,
    expireImage: img_617_expire,
    mismatchImage: img_617_mismatch
  },
  {
    title: '618',
    roomNo: '618',
    width: width(92),
    left: left(794),
    top: top(557),
    image: img_618,
    selectedImage: img_618_selected,
    expireImage: img_618_expire,
    mismatchImage: img_618_mismatch
  },
  {
    title: '619',
    roomNo: '619',
    width: width(94),
    left: left(698),
    top: top(557),
    image: img_619,
    selectedImage: img_619_selected,
    expireImage: img_619_expire,
    mismatchImage: img_619_mismatch
  },
  {
    title: '620',
    roomNo: '620',
    width: width(94),
    left: left(602),
    top: top(557),
    image: img_620,
    selectedImage: img_620_selected,
    expireImage: img_620_expire,
    mismatchImage: img_620_mismatch
  },
  {
    title: '621',
    roomNo: '621',
    width: width(98),
    left: left(502),
    top: top(557),
    image: img_621,
    selectedImage: img_621_selected,
    expireImage: img_621_expire,
    mismatchImage: img_621_mismatch
  },
  {
    title: '622',
    roomNo: '622',
    width: width(88),
    left: left(412),
    top: top(557),
    image: img_622,
    selectedImage: img_622_selected,
    expireImage: img_622_expire,
    mismatchImage: img_622_mismatch
  },
  {
    title: '623',
    roomNo: '623',
    width: width(110),
    left: left(300),
    top: top(557),
    image: img_623,
    selectedImage: img_623_selected,
    expireImage: img_623_expire,
    mismatchImage: img_623_mismatch
  },
  {
    title: '625',
    roomNo: '625',
    width: width(118),
    left: left(300),
    top: top(478),
    image: img_625,
    selectedImage: img_625_selected,
    expireImage: img_625_expire,
    mismatchImage: img_625_mismatch
  },
  {
    title: '602',
    roomNo: '602',
    width: width(118),
    left: left(300),
    top: top(424),
    image: img_602,
    selectedImage: img_602_selected,
    expireImage: img_602_expire,
    mismatchImage: img_602_mismatch
  },
]

export default {
  bg,
  initialRoomList
}