import styled from 'styled-components';
import { Select as AntSelect } from 'antd';
import { Options } from '@/types/common';
import './style.less';

const { Option } = AntSelect;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid #DEE0E3;
  height: 22px;
  width: 190px;
  padding: 5px 12px;
  box-sizing: content-box;
`

const Label = styled.div`
  color: #8F959E;
  font-size: 14px;
`

interface Props {
  label: string;
  value?: string | number;
  onChange?: (val: any) => void;
  options?: Options;
  optionsRender?: () => JSX.Element[];
  showSearch?: boolean;
}

const Select: React.FC<Props> = ({
  label,
  options,
  value,
  showSearch,
  optionsRender,
  onChange
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <AntSelect className="my-select" value={value} onChange={onChange} allowClear showSearch={showSearch}>
        {
          optionsRender ? optionsRender() :
          options?.map((opt, index) => (
            <Option key={`select_opt_${index}`} value={opt.value}>{opt.text}</Option>
          ))
        }
      </AntSelect>
    </Container>
  )
}

export default Select;