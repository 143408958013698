import { useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import img_cover from '@/assets/illustration@2x.png';
import styles from '../Login/style.module.less';


const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #FFF;
`

const RightPanel = styled.div`
  height: 100vh;
  > img {
    height: 100%;
  }
`

const LoginPanel = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 60px 30px;
  width: 36vw;
  height: 60vh;
`

const Title  = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
`

const SubTitle = styled.div`
  font-size: 12px;
  padding-top: 4px;
`

const Label = styled.div`
  font-size: 13px;
  border-bottom: 2px solid #3371FF;
  color: #3371FF;
  display: inline-block;
  margin-bottom: 12px;
`

const Register = () => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [mobile, setMobile] = useState('');

  const changeMobile = (e: any) => {
    setMobile(e.target.value);
    setBtnDisabled(!e.target.value);
  }
  

  return (
    <Container>
      <LeftPanel>
        <LoginPanel>
          <Title>注册</Title>
          <Input type="tel" placeholder="请输入你的手机号" value={mobile} onChange={changeMobile} />
          <Button type="primary" disabled={btnDisabled} className={styles.btnNext}>下一步</Button>
          <SubTitle>
            <Checkbox style={{ fontSize: 12 }}>
              我已阅读并同意<a>服务协议</a>和<a>隐私政策</a>
            </Checkbox>
          </SubTitle>
          <SubTitle>
            已有账号？
            <Link to="/login">立即登录</Link>
          </SubTitle>
        </LoginPanel>
      </LeftPanel>
      <RightPanel>
        <img src={img_cover} alt="" />
      </RightPanel>
    </Container>
  )
}

export default Register;