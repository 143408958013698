import styled from 'styled-components';
import Title from '@/components/Form/Title';
import QRCode from 'qrcode.react';
import { Select, Switch, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams, Link } from 'react-router-dom';
import CorpName from '@/components/CorpName';
import DownloadableQRCode from '@/components/DownloadableQRCode';

const { Option } = Select;

const Container = styled.div``

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonApplyList = styled.div`
  border-radius: 30px;
  background-color: #F1F4FF;
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  cursor: pointer;
  color: #60636A;
`

const SubTitle = styled.div`
  font-size: 14px;
  color: #1f2329;
  text-align: left;
  padding-bottom: 11px;
`

const Panel = styled.div`
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  padding: 24px;

  &.one {
    align-items: center;
    > .title {
      font-size: 16px;
      color: #1f2329;
    }
    > .subTitle {
      fong-size: 14px;
      color: #646A73;
      margin: 9px 0 35px 0;
    }
  }

  &.two {
    align-items: flex-start;
    .title {
      font-size: 14px;
      color: #1f2329;
    }
    .row {
      margin: 6px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .select {
      min-width: 300px;
    }

    .switch {
      margin-right: 12px;
    }

    .icon {
      margin-left: 6px;
      
    }
  }
`

const Invitation = () => {
  const history = useHistory();
  const { corpName, corpId } = history.location.state as any;
  // const { corpId } = useParams<any>();
  return (
    <Container>
      <TitleContainer>
        <Space align="start">
          <Title>邀请成员加入</Title>
          <CorpName name={corpName} />
        </Space>
        <Link to={{
          pathname: `/company/applicants/${corpId}`,
          state: { corpId }
        }}><ButtonApplyList>申请人列表</ButtonApplyList></Link>
      </TitleContainer>
      <SubTitle>
      让员工扫描二维码加入本公司，目前的邀请有效期至
      </SubTitle>
      <Panel className="one">
        <div className="title">公司二维码</div>
        <div className="subTitle">成员扫描二维码加入公司</div>
        <DownloadableQRCode value={`https://zlh.yoymoney.com/tools/corpId=${corpId}`} size={94} />
      </Panel>
      <SubTitle>邀请设置</SubTitle>
      <Panel className="two">
        <div className="title row">
          设置邀请有效期
          <QuestionCircleOutlined className="icon" />
        </div>
        <div className="row">
          <Select className="select">
            <Option value={42}>42天后过期</Option>
          </Select>
        </div>
        <div className="row">
          <Switch className="switch" />
          <div className="title">
            须管理员审批
            <QuestionCircleOutlined className="icon" />
          </div>
        </div>
      </Panel>
    </Container>
  )
}

export default Invitation;