import bg from '@/assets/floor_plan/9/9lou.png';
import img_901 from '@/assets/floor_plan/9/common/901.png';
import img_902 from '@/assets/floor_plan/9/common/902.png';
import img_903 from '@/assets/floor_plan/9/common/903.png';
import img_905 from '@/assets/floor_plan/9/common/905.png';
import img_906 from '@/assets/floor_plan/9/common/906.png';
import img_907 from '@/assets/floor_plan/9/common/907.png';
import img_908 from '@/assets/floor_plan/9/common/908.png';
import img_909 from '@/assets/floor_plan/9/common/909.png';
import img_910 from '@/assets/floor_plan/9/common/910.png';
import img_901_selected from '@/assets/floor_plan/9/selected/901.png';
import img_902_selected from '@/assets/floor_plan/9/selected/902.png';
import img_903_selected from '@/assets/floor_plan/9/selected/903.png';
import img_905_selected from '@/assets/floor_plan/9/selected/905.png';
import img_906_selected from '@/assets/floor_plan/9/selected/906.png';
import img_907_selected from '@/assets/floor_plan/9/selected/907.png';
import img_908_selected from '@/assets/floor_plan/9/selected/908.png';
import img_909_selected from '@/assets/floor_plan/9/selected/909.png';
import img_910_selected from '@/assets/floor_plan/9/selected/910.png';
import img_901_expire from '@/assets/floor_plan/9/expire/901.png';
import img_902_expire from '@/assets/floor_plan/9/expire/902.png';
import img_903_expire from '@/assets/floor_plan/9/expire/903.png';
import img_905_expire from '@/assets/floor_plan/9/expire/905.png';
import img_906_expire from '@/assets/floor_plan/9/expire/906.png';
import img_907_expire from '@/assets/floor_plan/9/expire/907.png';
import img_908_expire from '@/assets/floor_plan/9/expire/908.png';
import img_909_expire from '@/assets/floor_plan/9/expire/909.png';
import img_910_expire from '@/assets/floor_plan/9/expire/910.png';
import img_901_mismatch from '@/assets/floor_plan/9/mismatch/901.png';
import img_902_mismatch from '@/assets/floor_plan/9/mismatch/902.png';
import img_903_mismatch from '@/assets/floor_plan/9/mismatch/903.png';
import img_905_mismatch from '@/assets/floor_plan/9/mismatch/905.png';
import img_906_mismatch from '@/assets/floor_plan/9/mismatch/906.png';
import img_907_mismatch from '@/assets/floor_plan/9/mismatch/907.png';
import img_908_mismatch from '@/assets/floor_plan/9/mismatch/908.png';
import img_909_mismatch from '@/assets/floor_plan/9/mismatch/909.png';
import img_910_mismatch from '@/assets/floor_plan/9/mismatch/910.png';


const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 300) / 980}%`;
const top = (t: number) => `${100 * (t - 269) / 400}%`;

const initialRoomListData = [
  {
    title: '901',
    roomNo: '901',
    width: width(152),
    left: left(577),
    top: top(272),
    image: img_901,
    selectedImage: img_901_selected,
    expireImage: img_901_expire,
    mismatchImage: img_901_mismatch
  },
  {
    title: '902',
    roomNo: '902',
    width: width(174),
    left: left(731),
    top: top(272),
    image: img_902,
    selectedImage: img_902_selected,
    expireImage: img_902_expire,
    mismatchImage: img_902_mismatch
  },
  {
    title: '903',
    roomNo: '903',
    width: width(174),
    left: left(907),
    top: top(272),
    image: img_903,
    selectedImage: img_903_selected,
    expireImage: img_903_expire,
    mismatchImage: img_903_mismatch
  },
  {
    title: '905',
    roomNo: '905',
    width: width(194),
    left: left(1083),
    top: top(272),
    image: img_905,
    selectedImage: img_905_selected,
    expireImage: img_905_expire,
    mismatchImage: img_905_mismatch
  },
  {
    title: '906',
    roomNo: '906',
    width: width(100),
    left: left(1177),
    top: top(412),
    image: img_906,
    selectedImage: img_906_selected,
    expireImage: img_906_expire,
    mismatchImage: img_906_mismatch
  },
  {
    title: '907',
    roomNo: '907',
    width: width(136),
    left: left(1141),
    top: top(566),
    image: img_907,
    selectedImage: img_907_selected,
    expireImage: img_907_expire,
    mismatchImage: img_907_mismatch
  },
  {
    title: '908',
    roomNo: '908',
    width: width(116),
    left: left(1023),
    top: top(566),
    image: img_908,
    selectedImage: img_908_selected,
    expireImage: img_908_expire,
    mismatchImage: img_908_mismatch
  },
  {
    title: '909',
    roomNo: '909',
    width: width(174),
    left: left(731),
    top: top(566),
    image: img_909,
    selectedImage: img_909_selected,
    expireImage: img_909_expire,
    mismatchImage: img_909_mismatch
  },
  {
    title: '910',
    roomNo: '910',
    width: width(196),
    left: left(533),
    top: top(566),
    image: img_910,
    selectedImage: img_910_selected,
    expireImage: img_910_expire,
    mismatchImage: img_910_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}