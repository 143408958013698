import { UserInfo } from '@/types/common';
import produce from 'immer';

const initialState: UserInfo = {
  id: '',
  name: '',
  corpName: '',
  corpId: '',
  isLogin: false
}

interface Action {
  type: string,
  data: UserInfo
}

const reducer = (state = initialState, action: Action) =>
  produce(state, (draft): void => {
    if (action.type === 'SET_USER_INFO') {
      draft.id = action.data.id || '';
      draft.name = action.data.name || '';
      draft.isLogin = action.data.isLogin;
      draft.corpName = action.data.corpName || '';
      draft.corpId = action.data.corpId || '';
    }
  });

export default reducer;