import { useState, useEffect } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import styled from 'styled-components';
import InfiniteScorll from 'react-infinite-scroller';
import { useSelector, useDispatch } from 'react-redux';
import { getCompanyList, getCompanyCount } from '@/services/company';
import { setCorpInfo } from '@/store/actions/employee';
import { setCorpName } from '@/store/actions/company';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 75vh;
`

const Search = styled.div`
  padding: 12px 24px 12px 0px;
`

const InfiniteContainer = styled.div`
  flex: 1;
  overflow: auto;
`

const List = styled.div``

type ListItemProps = {
  selected?: boolean;
}

const ListItem = styled.div`
  cursor: pointer;
  background: ${(props: ListItemProps) => props.selected ? '#F0F4FF' : '#F1F2F4'};
  border-radius: 1px;
  margin: 12px 24px 12px 0px;
  color: ${(props: ListItemProps) => props.selected ? '#3371FF' : '#1F2329'};
  font-size: 14px;
  padding: 8px 9px;
`

const SideBar = () => {
  const [dataSource, setDataSource] = useState([]);
  const [corpName, setCorpName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const selectedCorpId = useSelector((state: any) => state.employee.corpId);

  const dispatch = useDispatch();

  const getData = (params: any) => {
    getCompanyList(params).then(res => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(dataSource.concat(data));
        setPageIndex(params.currentPage);
        if (data.length > 0) {
          setCorpInfo(data[0].corpId, data[0].corpName)
        }
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });

    // getCompanyCount({
    //   corpName: params.corpName
    // }).then((res: any) => {
    //   const { resultCode, data } = res.data;
    //   if (resultCode === 0) {
    //     setHasMore(dataSource.length < data)
    //   }
    // })
  }

  const doSearch = debounce((e) => {
    // getData({
    //   corpName: e.target.value,
    //   currentPage: 1,
    //   pageSize: 30
    // });
    setCorpName(e.target.value);
    // setPageIndex(1);
  }, 500)

  const infiniteOnLoad = () => {
    setLoading(true);
    getData({
      corpName,
      currentPage: pageIndex + 1,
      pageSize: 30
    })
  }

  useEffect(() => {
    getData({
      currentPage: 1,
      pageSize: 30
    })
  }, [])

  return (
    <Container>
      <Search>
        <Input
          type="search"
          prefix={<SearchOutlined />}
          onChange={doSearch}
        />
      </Search>
      <InfiniteContainer>
        {
          dataSource.filter((item: any) => {
            if (!corpName) {
              return true;
            }
            return item.corpName.includes(corpName);
          }).map((item: any, index: number) => {
            return (
              <ListItem
                key={`employee_sidebar_item_${index}`}
                selected={item.corpId === selectedCorpId}
                onClick={() => {
                  dispatch(setCorpInfo(item.corpId, item.corpName))
                }}
              >{item.corpName}</ListItem>
            )
          })
        }
      </InfiniteContainer>
    </Container>
  )
}

export default SideBar;