import { Column, ColumnConfig } from '@ant-design/charts';
import { Card, DatePicker, Space, Tabs } from 'antd';
import styled from 'styled-components';
import { getElectricChargeList } from '@/services/electric';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CubeSaveCost from './CubeSaveCost';
import CubeSaveEnergy from './CubeSaveEnergy';
import CubeElectric from './CubeElectric';

const { RangePicker } = DatePicker;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const Dashboard: React.FC = () => {
  const config: any = {
    data: [],
    padding: 'auto',
    xField: 'generateDate',
    yField: 'totalConsumeValue',
    meta: {
      generateDate: { alias: '日期' },
      totalConsumeValue: { alias: '总消耗的电量' },
    },
  };

  const [data, setData] = useState<any>([]);
  const [queryDate, setQueryDate] = useState<moment.Moment | null>(moment())

  useEffect(() => {
    if (queryDate) {
      getElectricChargeList(queryDate.format('yyyyMM')).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          setData(data.dayDataList || [])
        }
      })
    }
  }, [queryDate]);

  return (
    <>
      <Space style={{ marginBottom: 20 }} size={20}>
        <CubeSaveCost
          monthlySaveCost={400}
          dailySaveCost={174.13}
          rate={63.01}
        />
        <CubeSaveEnergy
          monthlySaveCost={762}
          dailySaveCost={214.62}
          rate={30.48}
        />
        <CubeElectric
          monthlySaveCost={762}
          dailySaveCost={214.96}
          rate={32}
        />
      </Space>
      <Card>
        <Tabs>
          <Tabs.TabPane tab="电量统计" key="dashboard_tab_1">
            <SearchBar>
              <DatePicker picker="month" value={queryDate} onChange={val => setQueryDate(val)} />
            </SearchBar>
            <Column {...config} data={data} />
          </Tabs.TabPane>
        </Tabs>
        
      </Card>
    </>
  );
}

export default Dashboard