import { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'antd';
import styled from 'styled-components';
import img_electric from '@/assets/electric@2x.png';
import { getBillDetail } from '@/services/bill';

const { Column } = Table;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #1F2329;
  font-size: 16px;
`

const Logo = styled.div`
  width: 40px;
  height: 40px;
  background: #12B9F7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > img {
    width: 28px;
    transform: translate(1px, 1px);
  }
`

const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #3A3C3C;
  padding-top: 19px;
  padding-bottom: 7px;
`

const PriceText = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: #3A3C3C;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Label = styled.div`
  color: #646A73;
  font-size: 14px;
  width: 80px;
  text-align: left;
`

const Content = styled.div`
  flex: 1;
  color: #1F2329;
  font-size: 14px;
  font-weight: 400;
`

const TableContainer = styled.div`
  margin-bottom: 19px;
`

interface Props {
  visible: boolean;
  data?: any;
  billId: number;
  onClose: () => void;
}

const RoomMonthlyDetail: React.FC<Props> = ({ visible, billId, onClose }) => {
  const [data, setData] = useState<any>({})

  useEffect(() => {
    getBillDetail(billId).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setData(data);
      }
    })
  }, [billId]);
  return (
    <Modal
      title={<Title>账单详情</Title>}
      cancelText="关闭"
      visible={visible}
      onCancel={onClose}
      width={830}
      footer={[
        <Button onClick={onClose}>关闭</Button>
      ]}
    >
      <Container>
        <Logo>
          <img src={img_electric} alt="" />
        </Logo>
        <SubTitle>上海市电力有限公司营业所</SubTitle>
        <PriceText>{data.totalFee}</PriceText>
      </Container>
      <Row>
        <Label>支付空间</Label>
        <Content>{data.roomNo}</Content>
      </Row>
      <Row>
        <Label></Label>
        <TableContainer>
          <Table dataSource={data?.itemList} pagination={false}>
            <Column title="房间号" dataIndex="roomNo" />
            {/* <Column title="用电量（度）" dataIndex="totalConsumeValue" /> */}
            <Column title="应付电费（元）" dataIndex="totalAmount" />
            <Column title="已免电费（元）" dataIndex="freeAmount" />
            <Column title="实付电费（元）" dataIndex="payableAmount" />
          </Table>
        </TableContainer>
      </Row>
      <Row>
        <Label>创建时间</Label>
        <Content>{data?.createTime}</Content>
      </Row>
    </Modal>
  )
}

export default RoomMonthlyDetail;