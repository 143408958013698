import { useEffect } from 'react';
import { Modal, Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { updateFile } from '@/services/knowledge';

const FormItem = Form.Item;
const { Option } = Select;

interface Props {
  visible: boolean;
  id: number | string;
  groupId: number | string;
  onClose: () => void;
}

const ModalChangeGroup: React.FC<Props> = ({
  visible,
  id,
  groupId,
  onClose
}) => {
  const [form] = Form.useForm();
  const groupList: any[] = useSelector((state: any) => state.knowledge.groupList);

  const onSave = () => {
    form.validateFields().then((values: any) => {
      updateFile({
        id,
        ...values
      }).then((res: any) => {
        const { resultCode } = res.data;
        if (resultCode === 0) {
          onClose();
        }
      })
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      groupId
    })
  }, [groupId])
  
  return (
    <Modal
      destroyOnClose
      visible={visible}
      onOk={onSave}
      onCancel={onClose}
      title="编辑分组"
    >
      <Form form={form}>
        <FormItem
          name="groupId"
          label="分组"
        >
          <Select>
            {
              groupList.map((item: any) => (
                <Option
                  key={`knowledge_m_group_${item.groupId}`}
                  value={item.groupId}
                >{item.groupName}</Option>
              ))
            }
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
}

export default ModalChangeGroup;

