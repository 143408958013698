import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Pagination, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GroupList from './GroupList';
import Item from './Item';
import { getFileList, getFileListCount } from '@/services/knowledge';
import FileEditor from './FileEditor';
import GroupEditor from './GroupEditor';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
`

const ButtonBar = styled.div`
  padding: 24px 0;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const LeftContainer = styled.div`
  width: 150px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 12px 20px;
`

interface Props {
  bigCategoryId: string;
  categoryId: string;
}

const Content: React.FC<Props> = ({
  bigCategoryId,
  categoryId
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileListCount, setFileListCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [loading, setLoading] = useState(false);
  const [fileEditorVisible, setFileEditorVisible] = useState(false);
  const [groupEditorVisible, setGroupEditorVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const groupListRef = useRef<any>();

  const getData = (page = 1, pageSize = 30) => {
    setLoading(true);
    getFileList({
      currentPage: page,
      pageSize,
      bigCategoryId,
      groupId: selectedGroupId
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setFileList(data);
        setPageIndex(page);
        setPageSize(pageSize);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    getFileListCount({
      bigCategoryId,
      groupId: selectedGroupId
    }).then((res: any) => {
      const { resultCode, data } = res.data
      if (resultCode === 0) {
        setFileListCount(data);
      }
    })
  }
  
  const onPagination = (page: number, pageSize?: number | undefined) => {
    getData(page, pageSize);
  }

  useEffect(() => {
    getData();
  }, [selectedGroupId, bigCategoryId, categoryId]);

  return (
    <Container>
      <ButtonBar>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setFileEditorVisible(true)}>新建知识</Button>
      </ButtonBar>
      <MainContainer>
        <LeftContainer> 
          <Button
            onClick={() => setGroupEditorVisible(true)}
            style={{ marginBottom: 12 }}
          >添加分组</Button>
          <GroupList ref={groupListRef} onChange={(groupId: number) => setSelectedGroupId(groupId)} />
        </LeftContainer>
        <Spin spinning={loading}>
          <ContentContainer>
            {
              fileList.map((data: any) => (
                <Item
                  key={`knowledge_item_${data.id}`}
                  id={data.id}
                  groupId={data.groupId}
                  coverImgUrl={data.filePath}
                  fileName={data.fileName}
                  fileSize={data.fileSize}
                  updateTime={data.updateTime}
                  onChange={() => getData()}
                />
              ))
            }
          </ContentContainer>
        </Spin>
        <Pagination
          hideOnSinglePage
          showSizeChanger
          onChange={onPagination}
          total={fileListCount}
          current={pageIndex}
          pageSize={pageSize}
        />
      </MainContainer>
      <Drawer
        destroyOnClose
        maskClosable={false}
        visible={fileEditorVisible}
        title={'新建知识'}
        width={500}
        onClose={() => setFileEditorVisible(false)}
      >
        <FileEditor
          bigCategoryId={bigCategoryId}
          smallCategoryId={categoryId}
          groupId={selectedGroupId}
          onClose={() => {
            setFileEditorVisible(false);
            getData();
          }}
        />
      </Drawer>
      <Drawer
        destroyOnClose
        maskClosable={false}
        visible={groupEditorVisible}
        title="新建分组"
        width={500}
        onClose={() => setGroupEditorVisible(false)}
      >
        <GroupEditor
          onClose={() => {
            setGroupEditorVisible(false);
            if (groupListRef.current) {
              groupListRef.current.updateGroupList()
            }
          }}
        />
      </Drawer>
    </Container>
  )
}

export default Content