import styled from 'styled-components';

const Container = styled.div`
  border-radius: 26px;
  background-color: #E4EAFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  height: 26px;
`

const Patch = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #7494FD;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  line-height: 20px;

  > div {
    transform: scale(0.8)
  }
`

const Text = styled.div`
  padding: 0 12px 0 6px;
  font-weight: 500;
  font-size: 14px;
`

interface Props {
  name: string;
  style?: any;
}

const CorpName: React.FC<Props> = ({ name, style }) => {
  const firstChar = name.length > 0 ? name.substring(0, 1) : '';
  return (
    <Container style={style}>
      <Patch><div>{firstChar}</div></Patch>
      <Text>{name}</Text>
    </Container>
  )
}

export default CorpName;