import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message, Space, Spin } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Panel from '@/screens/EnergyManagement/Panel';
import { Button, SwitchContainer } from './StyledComponents';
import { FanMode, HVACMode } from '@/types/device';
import {
  turnOnAirConditioning,
  turnOffAirConditioning,
  getDeviceDetail,
  setFanMode,
  setHVACMode,
  setTemperature as setACTemperature
} from '@/services/energy';

const TemperatureControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 15px;
  align-items: center;
  user-select: none;
`

const TemperatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Temperature = styled.div<ButtonProps>`
  color: #1F2329;
  font-size: 34px;
  font-weight: 500;
  height: 48px;
  width: 82px;
  text-align: center;
  margin: 0 19px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  ${props => props.disabled ? `
  color: #8F959E;
  ` : ''}
`

const EnvTemperature = styled.div`
  color: #8F959E;
  font-size: 10px;
  font-weight: 400;
`

const CircleButton = styled.div<ButtonProps>`
  border: 1px solid #C2C5CA;
  background-color: #FFFFFF;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${props => props.disabled ? `
  background: #F1F2F4;
  cursor: not-allowed;
  ` : ''}
`

interface ButtonProps {
  size?: 'small' | 'normal';
  actived?: boolean;
  disabled?: boolean;
}

const CtrlGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 32px;
`

const Label = styled.div`
  color: #8F959E;
  font-size: 12px;
  padding-right: 24px;
`

interface Props {
  deviceId: number;
  deviceNo: string;
}

const AirConditioning: React.FC<Props> = ({
  deviceId,
  deviceNo
}) => {
  const [mode, setMode] = useState<HVACMode | undefined>();
  const [speed, setSpeed] = useState<FanMode | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [temperature, setTemperature] = useState(27);
  const [envTemperature, setEnvTemperature] = useState(27);
  const [loading, setLoading] = useState(false);

  const toggle = (isOpen: boolean) => {
    const fn = isOpen ? turnOnAirConditioning : turnOffAirConditioning;
    setLoading(true);
    fn(deviceNo).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        return getDetail();
      }
      throw new Error(resultMsg)
    }).catch(err => {
      message.error(err.message)
      setLoading(false);
    })
  }

  const changeHVACMode = (hVACMode: HVACMode) => {
    setLoading(true);
    setHVACMode(deviceNo, hVACMode).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        return getDetail();
      }
      throw new Error(resultMsg)
    }).catch(err => {
      message.error(err.message)
      setLoading(false);
    })
  }

  const changeFanMode = (fanMode: FanMode) => {
    setLoading(true);
    setFanMode(deviceNo, fanMode).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        return getDetail();
      }
      throw new Error(resultMsg)
    }).catch(err => {
      message.error(err.message)
      setLoading(false);
    })
  }

  const changeTemperature = (temperature: string | number) => {
    setLoading(true);
    setACTemperature(deviceNo, temperature).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        return getDetail();
      }
      throw new Error(resultMsg)
    }).catch(err => message.error(err.message))
  }

  const getDetail = () => {
    return getDeviceDetail(deviceId).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setIsOpen(data.switchStatus !== HVACMode.off);
        setMode(data.switchStatus);
        setTemperature(data.attributes?.temperature);
        setEnvTemperature(data.attributes?.current_temperature);
        switch(data.attributes?.fan_mode) {
          case '高':
            setSpeed(FanMode.high);
            break;
          case '中':
            setSpeed(FanMode.medium);
            break;
          case '低':
            setSpeed(FanMode.low);
            break;
          default:
            setSpeed(data.attributes?.fan_mode);
            break;
        }
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    getDetail();
  }, []);
  
  return (
    <Panel title="空调">
      <Spin spinning={loading}>
        <TemperatureControlContainer>
          <TemperatureContainer>
            <CircleButton
              disabled={!isOpen}
              onClick={() => changeTemperature(temperature > 10 ? temperature - 1 : temperature)}
            >
              <MinusOutlined color={isOpen ? '#1F2329' : '#8F959E'} />
            </CircleButton>
            {
              isOpen ? (
                <Temperature>{temperature?.toFixed(1)}&#176;</Temperature>
              ) : (
                <Temperature disabled={true}>{'- -'}</Temperature>
              )
            }
            <CircleButton
              disabled={!isOpen}
              onClick={() => changeTemperature(temperature + 1)}
            >
              <PlusOutlined color={isOpen ? '#1F2329' : '#8F959E'} />
            </CircleButton>
          </TemperatureContainer>
          <EnvTemperature>环境温度{envTemperature}&#176;</EnvTemperature>
        </TemperatureControlContainer>
        <SwitchContainer>
          <Space size={18}>
            <Button
              actived={isOpen}
              onClick={() => toggle(true)}
            >开启</Button>
            <Button
              actived={!isOpen}
              onClick={() => toggle(false)}
            >关闭</Button>
          </Space>
        </SwitchContainer>
        <Space size={14} direction="vertical">
          <CtrlGroupContainer>
            <Label>模式</Label>
            <div>
              <Space>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={mode === HVACMode.cool}
                  onClick={() => changeHVACMode(HVACMode.cool)}
                >制冷</Button>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={mode === HVACMode.dry}
                  onClick={() => changeHVACMode(HVACMode.dry)}
                >制热</Button>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={mode === HVACMode.fanOnly}
                  onClick={() => changeHVACMode(HVACMode.fanOnly)}
                >通风</Button>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={mode === HVACMode.heat}
                  onClick={() => changeHVACMode(HVACMode.heat)}
                >除湿</Button>
              </Space>
            </div>
          </CtrlGroupContainer>
          <CtrlGroupContainer>
            <Label>风速</Label>
            <div>
              <Space>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={speed == FanMode.high}
                  onClick={() => changeFanMode(FanMode.high)}
                >大</Button>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={speed == FanMode.medium}
                  onClick={() => changeFanMode(FanMode.medium)}
                >中</Button>
                <Button
                  size="small"
                  disabled={!isOpen}
                  actived={speed == FanMode.low}
                  onClick={() => changeFanMode(FanMode.low)}
                >小</Button>
              </Space>
            </div>
          </CtrlGroupContainer>
        </Space>
      </Spin>
    </Panel>
  )
}

export default AirConditioning;