import styled from 'styled-components';
import { Table, Button, Space, DatePicker, Select as AntSelect, } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Select from '@/components/Select';
import { getClearRecordList, getClearRecordCount } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import { invoiceStatusList } from '@/common/constants';
import Detail from './Detail';

const { Column } = Table;
const { RangePicker } = DatePicker;
const { Option, OptGroup } = AntSelect;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`

const ClearRecord: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [queryStartDate, setQueryStartDate] = useState<moment.Moment | null>(null);
  const [queryEndDate, setQueryEndDate] = useState<moment.Moment | null>(null);
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const getData = (pageIndex = 1, pageSize = 30) => {
    setLoading(true);
    const params = {
      roomNo,
      beginCreateTime: queryStartDate?.format('yyyy-MM-DD 00:00:00'),
      endCreateTime: queryEndDate?.format('yyyy-MM-DD 00:00:00'),
    }
    getClearRecordList({
      currentPage: pageIndex,
      pageSize,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
    getClearRecordCount(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    getData(page, pageSize_)
  }

  useEffect(() => {
    getData();
  }, [queryStartDate, queryEndDate, roomNo])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      setFloorRoomMap(res.data);
    })
  }, [])

  return (
    <>
      <SearchBar>
        <Space>
          <RangePicker
            value={[queryStartDate, queryEndDate]}
            onChange={vals => {
              if (vals) {
                setQueryStartDate(vals[0])
                setQueryEndDate(vals[1])
              }
            }}
          />
          <Select
            showSearch
            label="所属房间"
            value={roomNo}
            onChange={val => setRoomNo(val)}
            optionsRender={() => Object.keys(floorRoomMap).sort((prev: string, next: string) => {
              return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
            }).map((floorName: string, floorIndex: number) => (
              <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                {
                  floorRoomMap[floorName].map((roomName: string, roomIndex: number) => (
                    <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                  ))
                }
              </OptGroup>
            ))}
          />
        </Space>
      </SearchBar>
      <Table
        dataSource={dataSource.filter(d => !!d.corpName)}
        loading={loading}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true,
        }}
      >
        <Column title="公司名称" dataIndex="corpName" />
        <Column title="账单时间" dataIndex="createTime" />
        <Column title="总费用" dataIndex="totalAmount" />
        <Column
          title="操作" 
          render={(_: string, record: any) => (
            <Button type="link" onClick={() => {
              setSelectedRecord(record?.billRecordList)
              setIsShowDetail(true)
            }}>详情</Button>
          )}
        />
      </Table>
      <Detail
        visible={isShowDetail}
        data={selectedRecord}
        onClose={() => setIsShowDetail(false)}
      />
    </>
  )
}

export default ClearRecord;