import { useState, useEffect } from 'react';
import { Space, Switch } from 'antd';
import { useSelector, useDispatch }  from 'react-redux';
import styled from 'styled-components';
import Select from '@/components/Select';
import { getFloorRoomList } from '@/services/workspace';
import FloorPlan from './FloorPlan';
import CommonRoomList from './List';
import SalesRoomList from './SalesRoomList';
import { IsParentRoom, DisplayMode } from '@/types/common';
import {
  setRentStatus,
  setWorkspaceType,
  setWorkspaceSizeType,
  setFloorNo,
  setWorkspaceDisplayMode
} from '@/store/actions/workspace';

const Container = styled.div`
  margin-top: 20px;
`

interface Props {
  isParentRoom: IsParentRoom;
}

const Workspace: React.FC<Props> = ({ isParentRoom }) => {
  const rentStatusList = useSelector((state: any) => state.common.rentStatusList);
  const workspaceTypeList = useSelector((state: any) => state.common.workspaceTypeList);
  const workspaceSizeTypeList = useSelector((state: any) => state.common.workspaceSizeTypeList);
  const [floorRoomList, setFloorRoomList] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any []>([]);
  const WorkspaceList = isParentRoom === 1 ? SalesRoomList : CommonRoomList;

  const workspaceType = useSelector((state: any) => state.workspace.common.workspaceType);
  const workspaceSizeType = useSelector((state: any) => state.workspace.common.workspaceSizeType);
  const rentStatus = useSelector((state: any) => state.workspace.common.rentStatus);
  const floorNo = useSelector((state: any) => state.workspace.common.floorNo);
  const displayMode = useSelector((state: any) => state.workspace.common.displayMode);

  const dispatch = useDispatch();

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorRoomList(list.map(item => ({
        value: item,
        text: item
      })))
    })
  }, [])

  useEffect(() => {
    dispatch(setWorkspaceType(isParentRoom === 1 ? 0 : undefined))
  }, [isParentRoom])

  return (
    <>
      <Space>
        {
          isParentRoom !== 1 && (
            <Select label="功能类型" value={workspaceType} options={workspaceTypeList} onChange={val => dispatch(setWorkspaceType(val))} />
          )
        }
        <Select label="所属楼层" value={floorNo} options={floorRoomList} onChange={val => dispatch(setFloorNo(val))} />
        <Select label="使用情况" value={rentStatus} options={rentStatusList} onChange={val => dispatch(setRentStatus(val))} />
        <Select label="容纳人数" value={workspaceSizeType} options={workspaceSizeTypeList} onChange={val => dispatch(setWorkspaceSizeType(val))} />
        {/* <Select label="所属园区" options={resourceTypeList} /> */}
        <Switch
          checkedChildren="平面图模式"
          unCheckedChildren="列表模式"
          checked={displayMode === 'floor'}
          onChange={(val: boolean) => dispatch(setWorkspaceDisplayMode(val ? 'floor' : 'list'))}
        />
      </Space>
      <Container>
      {
        displayMode === 'floor' ? (
          <FloorPlan floors={floorRoomList} dataSource={dataSource} />
        ) : (
          <WorkspaceList
            rentStatus={rentStatus}
            workspaceType={workspaceType}
            workspaceSizeType={workspaceSizeType}
            floorNo={floorNo}
            isParentRoom={isParentRoom}
          />
        )
      }
      </Container>
    </>
  )
}

export default Workspace;