import { useState, useRef } from 'react';
import { Space, Divider, Typography, Popconfirm } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import ModalChangeGroup from './ModalChangeGroup';
import ModalChangeName from './ModalChangeName';
import { updateFile } from '@/services/knowledge';

const Container = styled.div`
  width: 142px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Cover = styled.div`
  width: 104px;
  height: 104px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DEE0E3;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.div`
  width: 88px;
  height: 88px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #1F2329;
  width: 100%;
`

const SubTitle = styled.div`
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #646A73;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ActionBar = styled.div`
  width: 100%;
`

const Button = styled.div`
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  color: #3371FF;
`

interface Props {
  id: number | string;
  coverImgUrl: string;
  fileName: string;
  fileSize: number;
  updateTime: string;
  groupId: number;
  onChange: () => void;
}

const Item:React.FC<Props> = ({
  id,
  groupId,
  coverImgUrl,
  fileName,
  fileSize,
  updateTime,
  onChange,
}) => {
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  
  const deleteFile = () => {
    updateFile({
      id,
      status: 0
    }).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        onChange();
      }
    })
  }

  const onImageLoaded = () => {
    if (!imgRef.current) {
      return;
    }
    if (imgRef.current.width < imgRef.current.height) {
      imgRef.current.style.width = '100%';
      imgRef.current.style.height = 'auto';
    } else {
      imgRef.current.style.height = '100%';
      imgRef.current.style.width = 'auto';
    }
  }

  return (
    <>
      <Container>
        <Cover>
          <ImageContainer>
            <img src={/\.(jpg|png|JPG|PNG)/.test(coverImgUrl) ? coverImgUrl : ''} ref={imgRef} onLoad={onImageLoaded} />
          </ImageContainer>
        </Cover>
        <Title>{fileName}</Title>
        <SubTitle>
          <span>{`${fileSize}KB`}</span>
          <span>{moment(updateTime).format('YYYY-MM-DD')}</span>
        </SubTitle>
        <ActionBar>
          <Space split={<Divider type="vertical" />}>
            <Typography.Link style={{whiteSpace: 'nowrap'}} onClick={() => setVisible1(true)}>改名</Typography.Link>
            <Typography.Link style={{whiteSpace: 'nowrap'}} onClick={() => setVisible2(true)}>分组</Typography.Link>
            <Popconfirm
              title={`确定要删除文件：${fileName}吗？`}
              onConfirm={deleteFile}
            >
              <Typography.Link style={{whiteSpace: 'nowrap'}}>删除</Typography.Link>
            </Popconfirm>
          </Space>
        </ActionBar>
      </Container>
      <ModalChangeName
        visible={visible1}
        fileName={fileName}
        id={id}
        onClose={() => {
          setVisible1(false);
          onChange();
        }}
      />
      <ModalChangeGroup
        visible={visible2}
        groupId={groupId}
        id={id}
        onClose={() => {
          setVisible2(false);
          onChange();
        }}
      />
    </>
  )
}

export default Item;

