import bg from '@/assets/energy/floors/11/11.png';
import img_1100_1_common from '@/assets/energy/floors/11/common/1100-1.png';
import img_1100_2_common from '@/assets/energy/floors/11/common/1100-2.png';
import img_1100_3_common from '@/assets/energy/floors/11/common/1100-3.png';
import img_1100_4_common from '@/assets/energy/floors/11/common/1100-4.png';
import img_1100_5_common from '@/assets/energy/floors/11/common/1100-5.png';
import img_1100_6_common from '@/assets/energy/floors/11/common/1100-6.png';
import img_1101_1_common from '@/assets/energy/floors/11/common/1101-1.png';
import img_1101_2_common from '@/assets/energy/floors/11/common/1101-2.png';
import img_1101_3_common from '@/assets/energy/floors/11/common/1101-3.png';
import img_1101_4_common from '@/assets/energy/floors/11/common/1101-4.png';
import img_1101_5_common from '@/assets/energy/floors/11/common/1101-5.png';
import img_1101_6_common from '@/assets/energy/floors/11/common/1101-6.png';
import img_1101_7_common from '@/assets/energy/floors/11/common/1101-7.png';
import img_1101_8_common from '@/assets/energy/floors/11/common/1101-8.png';
import img_1101_9_common from '@/assets/energy/floors/11/common/1101-9.png';
import img_1102_1_common from '@/assets/energy/floors/11/common/1102-1.png';
import img_1102_2_common from '@/assets/energy/floors/11/common/1102-2.png';
import img_1102_3_common from '@/assets/energy/floors/11/common/1102-3.png';
import img_1102_4_common from '@/assets/energy/floors/11/common/1102-4.png';
import img_1102_5_common from '@/assets/energy/floors/11/common/1102-5.png';
import img_1102_6_common from '@/assets/energy/floors/11/common/1102-6.png';
import img_1103_1_common from '@/assets/energy/floors/11/common/1103-1.png';
import img_1103_2_common from '@/assets/energy/floors/11/common/1103-2.png';
import img_1103_3_common from '@/assets/energy/floors/11/common/1103-3.png';
import img_1103_4_common from '@/assets/energy/floors/11/common/1103-4.png';
import img_1103_5_common from '@/assets/energy/floors/11/common/1103-5.png';
import img_1103_6_common from '@/assets/energy/floors/11/common/1103-6.png';
import img_1105_1_common from '@/assets/energy/floors/11/common/1105-1.png';
import img_1105_2_common from '@/assets/energy/floors/11/common/1105-2.png';
import img_1105_3_common from '@/assets/energy/floors/11/common/1105-3.png';
import img_1105_4_common from '@/assets/energy/floors/11/common/1105-4.png';
import img_1105_5_common from '@/assets/energy/floors/11/common/1105-5.png';
import img_1105_6_common from '@/assets/energy/floors/11/common/1105-6.png';
import img_1105_7_common from '@/assets/energy/floors/11/common/1105-7.png';
import img_1105_8_common from '@/assets/energy/floors/11/common/1105-8.png';
import img_1130_common from '@/assets/energy/floors/11/common/1130.png';
import img_1131_common from '@/assets/energy/floors/11/common/1131.png';
import img_1132_common from '@/assets/energy/floors/11/common/1132.png';
import img_1133_common from '@/assets/energy/floors/11/common/1133.png';
import img_1134_common from '@/assets/energy/floors/11/common/1134.png';
import img_1135_common from '@/assets/energy/floors/11/common/1135.png';
import img_1136_common from '@/assets/energy/floors/11/common/1136.png';
import img_1137_common from '@/assets/energy/floors/11/common/1137.png';

import img_1100_1_selected from '@/assets/energy/floors/11/selected/1100-1.png';
import img_1100_2_selected from '@/assets/energy/floors/11/selected/1100-2.png';
import img_1100_3_selected from '@/assets/energy/floors/11/selected/1100-3.png';
import img_1100_4_selected from '@/assets/energy/floors/11/selected/1100-4.png';
import img_1100_5_selected from '@/assets/energy/floors/11/selected/1100-5.png';
import img_1100_6_selected from '@/assets/energy/floors/11/selected/1100-6.png';
import img_1101_1_selected from '@/assets/energy/floors/11/selected/1101-1.png';
import img_1101_2_selected from '@/assets/energy/floors/11/selected/1101-2.png';
import img_1101_3_selected from '@/assets/energy/floors/11/selected/1101-3.png';
import img_1101_4_selected from '@/assets/energy/floors/11/selected/1101-4.png';
import img_1101_5_selected from '@/assets/energy/floors/11/selected/1101-5.png';
import img_1101_6_selected from '@/assets/energy/floors/11/selected/1101-6.png';
import img_1101_7_selected from '@/assets/energy/floors/11/selected/1101-7.png';
import img_1101_8_selected from '@/assets/energy/floors/11/selected/1101-8.png';
import img_1101_9_selected from '@/assets/energy/floors/11/selected/1101-9.png';
import img_1102_1_selected from '@/assets/energy/floors/11/selected/1102-1.png';
import img_1102_2_selected from '@/assets/energy/floors/11/selected/1102-2.png';
import img_1102_3_selected from '@/assets/energy/floors/11/selected/1102-3.png';
import img_1102_4_selected from '@/assets/energy/floors/11/selected/1102-4.png';
import img_1102_5_selected from '@/assets/energy/floors/11/selected/1102-5.png';
import img_1102_6_selected from '@/assets/energy/floors/11/selected/1102-6.png';
import img_1103_1_selected from '@/assets/energy/floors/11/selected/1103-1.png';
import img_1103_2_selected from '@/assets/energy/floors/11/selected/1103-2.png';
import img_1103_3_selected from '@/assets/energy/floors/11/selected/1103-3.png';
import img_1103_4_selected from '@/assets/energy/floors/11/selected/1103-4.png';
import img_1103_5_selected from '@/assets/energy/floors/11/selected/1103-5.png';
import img_1103_6_selected from '@/assets/energy/floors/11/selected/1103-6.png';
import img_1105_1_selected from '@/assets/energy/floors/11/selected/1105-1.png';
import img_1105_2_selected from '@/assets/energy/floors/11/selected/1105-2.png';
import img_1105_3_selected from '@/assets/energy/floors/11/selected/1105-3.png';
import img_1105_4_selected from '@/assets/energy/floors/11/selected/1105-4.png';
import img_1105_5_selected from '@/assets/energy/floors/11/selected/1105-5.png';
import img_1105_6_selected from '@/assets/energy/floors/11/selected/1105-6.png';
import img_1105_7_selected from '@/assets/energy/floors/11/selected/1105-7.png';
import img_1105_8_selected from '@/assets/energy/floors/11/selected/1105-8.png';
import img_1130_selected from '@/assets/energy/floors/11/selected/1130.png';
import img_1131_selected from '@/assets/energy/floors/11/selected/1131.png';
import img_1132_selected from '@/assets/energy/floors/11/selected/1132.png';
import img_1133_selected from '@/assets/energy/floors/11/selected/1133.png';
import img_1134_selected from '@/assets/energy/floors/11/selected/1134.png';
import img_1135_selected from '@/assets/energy/floors/11/selected/1135.png';
import img_1136_selected from '@/assets/energy/floors/11/selected/1136.png';
import img_1137_selected from '@/assets/energy/floors/11/selected/1137.png';

const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;
const fontSize = (w: number, f: number) => `${100 * (f / w)}%`
// {
//   title: '11xx-',
//   roomNo: '11xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   writingMode: 'vertical-lr',
//   image: img_11xx__common,
//   selectedImage: img_11xx__selected,
// },
const initialRoomList = [
  {
    title: '空调机房',
    roomNo: '1130',
    width: width(44),
    left: left(401),
    top: top(280),
    fontSize: fontSize(44, 12),
    writingMode: 'vertical-lr',
    image: img_1130_common,
    selectedImage: img_1130_selected,
  },
  {
    title: '1102-1',
    titleLeft: `${100 * (599 - 447) / 368}%`,
    titleWidth: 'atuo',
    roomNo: '1102-1',
    width: width(368),
    left: left(447),
    top: top(280),
    fontSize: fontSize(368, 16),
    image: img_1102_1_common,
    selectedImage: img_1102_1_selected,
  },
  {
    title: '1102-2',
    roomNo: '1102-2',
    width: width(60),
    left: left(693),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1102_2_common,
    // selectedImage: img_1102_2_selected,
  },
  {
    title: '1102-3',
    roomNo: '1102-3',
    width: width(60),
    left: left(447),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1102_3_common,
    // selectedImage: img_1102_3_selected,
  },
  {
    title: '1102-4',
    roomNo: '1102-4',
    width: width(60),
    left: left(447),
    top: top(338),
    fontSize: fontSize(60, 12),
    image: img_1102_4_common,
    // selectedImage: img_1102_4_selected,
  },
  {
    title: '1102-5',
    roomNo: '1102-5',
    width: width(46),
    left: left(509),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1102_5_common,
    // selectedImage: img_1102_5_selected,
  },
  {
    title: '1102-6',
    roomNo: '1102-6',
    width: width(60),
    left: left(755),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1102_6_common,
    // selectedImage: img_1102_6_selected,
  },
  {
    title: '1103-1',
    titleLeft: `${100 * (1003 - 817) / 346}%`,
    titleWidth: 'atuo',
    roomNo: '1103-1',
    width: width(346),
    left: left(817),
    top: top(280),
    fontSize: fontSize(346, 16),
    image: img_1103_1_common,
    selectedImage: img_1103_1_selected,
  },
  {
    title: '1103-2',
    roomNo: '1103-2',
    width: width(60),
    left: left(879),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1103_2_common,
    // selectedImage: img_1103_2_selected,
  },
  {
    title: '1103-3',
    roomNo: '1103-3',
    width: width(60),
    left: left(817),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1103_3_common,
    // selectedImage: img_1103_3_selected,
  },
  {
    title: '1103-4',
    roomNo: '1103-4',
    width: width(60),
    left: left(817),
    top: top(338),
    fontSize: fontSize(60, 12),
    image: img_1103_4_common,
    // selectedImage: img_1103_4_selected,
  },
  {
    title: '1103-5',
    roomNo: '1103-5',
    width: width(46),
    left: left(1117),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1103_5_common,
    // selectedImage: img_1103_5_selected,
  },
  {
    title: '1103-6',
    roomNo: '1103-6',
    width: width(46),
    left: left(1117),
    top: top(328),
    fontSize: fontSize(46, 12),
    image: img_1103_6_common,
    // selectedImage: img_1103_6_selected,
  },
  {
    title: '空调机房',
    roomNo: '1133',
    width: width(108),
    left: left(1055),
    top: top(390),
    fontSize: fontSize(108, 12),
    image: img_1133_common,
    selectedImage: img_1133_selected,
  },
  {
    title: '1105-1',
    titleLeft: `${100 * (948 - 783) / 380}%`,
    titleTop: `${100 *(592 - 434) / 380}%`,
    titleWidth: 'atuo',
    roomNo: '1105-1',
    width: width(380),
    left: left(783),
    top: top(434),
    fontSize: fontSize(380, 16),
    image: img_1105_1_common,
    selectedImage: img_1105_1_selected,
  },
  {
    title: '1105-2',
    roomNo: '1105-2',
    width: width(60),
    left: left(1103),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1105_2_common,
    // selectedImage: img_1105_2_selected,
  },
  {
    title: '1105-3',
    roomNo: '1105-3',
    width: width(60),
    left: left(783),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1105_3_common,
    // selectedImage: img_1105_3_selected,
  },
  {
    title: '1105-4',
    roomNo: '1105-4',
    width: width(76),
    left: left(783),
    top: top(632),
    fontSize: fontSize(76, 12),
    image: img_1105_4_common,
    // selectedImage: img_1105_4_selected,
  },
  {
    title: '1105-5',
    roomNo: '1105-5',
    width: width(60),
    left: left(1055),
    top: top(632),
    fontSize: fontSize(60, 12),
    image: img_1105_5_common,
    // selectedImage: img_1105_5_selected,
  },
  {
    title: '1105-6',
    roomNo: '1105-6',
    width: width(46),
    left: left(1117),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1105_6_common,
    // selectedImage: img_1105_6_selected,
  },
  {
    title: '1105-7',
    roomNo: '1105-7',
    width: width(46),
    left: left(861),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1105_7_common,
    // selectedImage: img_1105_7_selected,
  },
  {
    title: '1105-8',
    roomNo: '1105-8',
    width: width(46),
    left: left(909),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1105_8_common,
    // selectedImage: img_1105_8_selected,
  },
  {
    title: '1101-1',
    titleLeft: `${100 * (566 - 401) / 380}%`,
    titleTop: `${100 * (592 - 418) / 380}%`,
    titleWidth: 'atuo',
    roomNo: '1101-1',
    width: width(380),
    left: left(401),
    top: top(418),
    fontSize: fontSize(380, 16),
    image: img_1101_1_common,
    selectedImage: img_1101_1_selected,
  },
  {
    title: '1101-2',
    roomNo: '1101-2',
    width: width(60),
    left: left(721),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1101_2_common,
    // selectedImage: img_1101_2_selected,
  },
  {
    title: '1101-3',
    roomNo: '1101-3',
    width: width(76),
    left: left(705),
    top: top(632),
    fontSize: fontSize(76, 12),
    image: img_1101_3_common,
    // selectedImage: img_1101_3_selected,
  },
  {
    title: '1101-4',
    roomNo: '1101-4',
    width: width(60),
    left: left(401),
    top: top(580),
    fontSize: fontSize(60, 12),
    image: img_1101_4_common,
    // selectedImage: img_1101_4_selected,
  },
  {
    title: '1101-5',
    roomNo: '1101-5',
    width: width(46),
    left: left(401),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1101_5_common,
    // selectedImage: img_1101_5_selected,
  },
  {
    title: '1101-6',
    roomNo: '1101-6',
    width: width(46),
    left: left(657),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1101_6_common,
    // selectedImage: img_1101_6_selected,
  },
  {
    title: '1101-7',
    roomNo: '1101-7',
    width: width(46),
    left: left(609),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1101_7_common,
    // selectedImage: img_1101_7_selected,
  },
  {
    title: '1101-8',
    roomNo: '1101-8',
    width: width(46),
    left: left(449),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1101_8_common,
    // selectedImage: img_1101_8_selected,
  },
  {
    title: '1101-9',
    roomNo: '1101-9',
    width: width(46),
    left: left(401),
    top: top(528),
    fontSize: fontSize(46, 12),
    image: img_1101_9_common,
    // selectedImage: img_1101_9_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '1100-3',
    width: width(102),
    left: left(525),
    top: top(418),
    fontSize: fontSize(102, 16),
    image: img_1100_3_common,
    selectedImage: img_1100_3_selected,
  },
  {
    title: '1131',
    roomNo: '1131',
    width: width(69),
    left: left(657),
    top: top(418),
    fontSize: fontSize(69, 12),
    image: img_1131_common,
    selectedImage: img_1131_selected,
  },
  {
    title: '1136',
    roomNo: '1136',
    width: width(69),
    left: left(657),
    top: top(496),
    fontSize: fontSize(69, 12),
    image: img_1136_common,
    selectedImage: img_1136_selected,
  },
  {
    title: '1137',
    roomNo: '1137',
    width: width(40),
    left: left(686),
    top: top(468),
    fontSize: fontSize(40, 12),
    image: img_1137_common,
    selectedImage: img_1137_selected,
  },
  {
    title: '1135',
    roomNo: '1135',
    width: width(41),
    left: left(777),
    top: top(513),
    fontSize: fontSize(41, 12),
    image: img_1135_common,
    selectedImage: img_1135_selected,
  },
  {
    title: '1134',
    roomNo: '1134',
    width: width(32),
    left: left(910),
    top: top(497),
    fontSize: fontSize(32, 12),
    image: img_1134_common,
    selectedImage: img_1134_selected,
  },
  {
    title: '1132',
    roomNo: '1132',
    width: width(24),
    left: left(914),
    top: top(418),
    fontSize: fontSize(24, 12),
    writingMode: 'vertical-lr',
    image: img_1132_common,
    selectedImage: img_1132_selected,
  },
  {
    title: '1100-1',
    titleLeft: `${100 * (826 - 524) / 527}%`,
    titleTop: `${100 * (552 - 514) / 527}%`,
    titleWidth: 'atuo',
    roomNo: '1100-1',
    width: width(527),
    left: left(524),
    top: top(514),
    fontSize: fontSize(527, 12),
    color: '#808080',
    image: img_1100_1_common,
    selectedImage: img_1100_1_selected,
  },
  {
    title: '1100-2',
    roomNo: '1100-2',
    width: width(650),
    left: left(401),
    top: top(392),
    fontSize: fontSize(650, 12),
    color: '#808080',
    image: img_1100_2_common,
    selectedImage: img_1100_2_selected,
  },
  {
    title: '1100-4',
    roomNo: '1100-4',
    width: width(22),
    left: left(1029),
    top: top(418),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1100_4_common,
    selectedImage: img_1100_4_selected,
  },
  {
    title: '1100-5',
    roomNo: '1100-5',
    width: width(43),
    left: left(823),
    top: top(418),
    fontSize: fontSize(43, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1100_5_common,
    selectedImage: img_1100_5_selected,
  },
  {
    title: '1100-6',
    roomNo: '1100-6',
    width: width(22),
    left: left(631),
    top: top(418),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1100_6_common,
    selectedImage: img_1100_6_selected,
  },
]

export default {
  bg,
  initialRoomList
}