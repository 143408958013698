import produce from 'immer';
import { DisplayMode } from '@/types/common';

type WorkspaceStateType = {
  sales: {
    pageIndex: number;
    pageSize: number;
  },
  common: {
    pageIndex: number;
    pageSize: number;
    rentStatus: number | undefined;
    floorNo: string;
    workspaceType: number | undefined;
    workspaceSizeType: number | undefined;
    displayMode: DisplayMode;
  }
}

const initialState: WorkspaceStateType = {
  sales: {
    pageIndex: 1,
    pageSize: 20,
  },
  common: {
    pageIndex: 1,
    pageSize: 20,
    rentStatus: undefined,
    floorNo: '04F',
    workspaceType: undefined,
    workspaceSizeType: undefined,
    displayMode: 'list'
  }
}

const reducer = (state = initialState, action: any) => 
  produce(state, (draft): void => {
    switch(action.type) {
      case 'SET_WS_SALES_PAGINATION':
        draft.sales.pageIndex = action.data.pageIndex;
        draft.sales.pageSize = action.data.pageSize;
        break;
      case 'SET_WS_COMMON_PAGINATION':
        draft.common.pageIndex = action.data.pageIndex;
        draft.common.pageSize = action.data.pageSize;
        break;
      case 'SET_WS_RENT_STATUS':
        draft.common.rentStatus = action.data;
        break;
      case 'SET_WS_FLOOR_NO':
        draft.common.floorNo = action.data;
        break;
      case 'SET_WS_WORKSPACE_TYPE':
        draft.common.workspaceType = action.data;
        break;
      case 'SET_WS_WORKSPACE_SIZE_TYPE':
        draft.common.workspaceSizeType = action.data;
        break;
      case 'SET_WS_DISPLAY_MODE':
        draft.common.displayMode = action.data;
        break;
    }
  })

export default reducer