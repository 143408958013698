import { useEffect } from 'react';
import { Layout, Menu, Card, Dropdown } from 'antd';
import { PayCircleOutlined } from '@ant-design/icons';
import {
  Switch,
  Route,
  Link,
  useHistory
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CompanyManagement from '@/screens/CompanyManagement';
import DataManagement from '@/screens/DataManagement';
import Invitation from '@/screens/CompanyManagement/Employee/Invitation';
import Breadcrumb from '@/components/Breadcrumb'
import ApplicantList from '../CompanyManagement/Employee/ApplicantList';
import Workspace from '@/screens/WorkspaceManagement';
import WorkspaceDetail from '@/screens/WorkspaceManagement/Detail';
import WOrkspaceEdit from '@/screens/WorkspaceManagement/Edit';
import DeviceList from '@/screens/DeviceManagement';
import DeviceEdit from '@/screens/DeviceManagement/Edit';
import DeviceDetail from '@/screens/DeviceManagement/Detail';
import Calendar from '@/screens/WorkspaceManagement/Calendar.discard';
import MeetingRoomManagement from '@/screens/MeetingRoomManagement';
import EnergyManagement from '@/screens/EnergyManagement';
import DailyBill from '@/screens/EnergyManagement/Bill/Daily';
import MonthlyBill from '@/screens/EnergyManagement/Bill/Monthly/MonthlyBill';
import MonthlyBillCalendarMode from '@/screens/EnergyManagement/Bill/Monthly/Calendar';
import InvoiceList from '@/screens/EnergyManagement/Invoice'
import ClearRecord from '@/screens/EnergyManagement/ClearRecord';
import PersonnelFiling from '@/screens/OperationManagement/PersonnelFiling';
import OperateLog from '@/screens/OperationManagement/OperateLog';
import CardManagent from '@/screens/CompanyManagement/Card';
import styles from './style.module.less';
import img_logo from '@/assets/group@2x.png';
import img_down from '@/assets/caret-down@2x.png';
import { getUserInfo } from '@/services/user';
import { setUserInfo } from '@/store/actions/user';
import Cookies from 'js-cookie';
import NavIcon from '@/components/NavIcon';
import Knowledge from '@/screens/Knowledge';
import TaskManagement from '@/screens/TaskManagement';
import TaskDetail from '../TaskManagement/Detail';
import ActivityList from '../ActivityManagement';
import ActivityEdit from '../ActivityManagement/Edit';
import ActivityDetail from '../ActivityManagement/Detail';
import AdvertiseList from '../AdvertiseManagement';
import AdvertiseEdit from '../AdvertiseManagement/Edit';
import AdvertiseDetail from '../AdvertiseManagement/Detail';
import Dashboard from '../Dashboard';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const MenuStyle: React.CSSProperties = { fontWeight: 'bold' };
const MenuItemStyle: React.CSSProperties = { fontWeight: 'normal' };

const logout = () => {
  Cookies.remove('wpSessionKey')
  setTimeout(() => {
    window.location.href = '/web/qrlogin'
  }, 500);
}

const menu = (
  <Menu>
    <Menu.Item onClick={logout}>退出</Menu.Item>
  </Menu>
)

function MainFrame() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.user);

  useEffect(() => {
    getUserInfo().then((res: any) => {
      const { resultCode, resultMsg, data } = res.data;
      console.log(res)
      if (resultCode === 0) {
        dispatch(setUserInfo({
          id: data.userId,
          name: data.realName || data.nickName,
          isLogin: true,
          corpName: data.corpName,
          corpId: data.corpId
        }));
      }
    })
  }, [])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className={styles.header}>
        <img className={styles.logo} src={img_logo} alt="腾讯双创社区（南通）" />
        <Dropdown overlay={menu} placement="bottomCenter" arrow>
          <div className={styles.userInfoContainer}>
            <div className={styles.userInfoContainerInner}>
              <div className={styles.corpName}>{userInfo.name}</div>
              {
                userInfo.corpName && (
                  <div className={styles.positionName}>{userInfo.corpName}</div>
                )
              }
            </div>
            <img className={styles.iconDown} src={img_down} alt="" />
          </div>
        </Dropdown>
      </Header>
      <Layout>
        <Sider collapsible theme="light">
          <Menu theme="light" mode="inline" selectedKeys={[history.location.pathname]}>
            <Menu.Item icon={<NavIcon type="home" />} style={MenuStyle}>首页</Menu.Item>
            <SubMenu key="sub2" icon={<NavIcon type="space" />} title="空间管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/salesspace">空间入驻管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/workspace">空间配置管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/meetingroom">会议室管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/device">资产管理</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<NavIcon type="source" />} title="能源管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/energy">能源管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/monthlybill">账单</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/invoice">开票申请</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/energy/clearrecord">账务清结算</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<NavIcon type="marketing" />} title="运营管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/personnelfiling/permission">人员备案</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/personnelfiling/log">事件跟踪</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" icon={<NavIcon type="enterprise" />} title="公司及数据管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/company/list">公司管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/card">卡片管理</Link></Menu.Item>
              {/* <Menu.Item style={MenuItemStyle}><Link to="/data">数据管理</Link></Menu.Item> */}
            </SubMenu>
            <SubMenu key="sub6" icon={<PayCircleOutlined />} title="经营管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/dashboard">数据分析</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/task">任务管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/activity">活动管理</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/ad">投放管理</Link></Menu.Item>
            </SubMenu>
            <SubMenu key="sub7" icon={<NavIcon type="book" />} title="知识管理" style={MenuStyle}>
              <Menu.Item style={MenuItemStyle}><Link to="/knowledge/2">公共知识</Link></Menu.Item>
              <Menu.Item style={MenuItemStyle}><Link to="/knowledge/1">积累知识</Link></Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="main-container">
          <Breadcrumb />
          <Content>
            <Switch>
              <Route path="/dashboard"><Dashboard /></Route>
              <Card>
                <Switch>
                  <Route path="/calendar"><Calendar /></Route>
                  <Route path="/device/detail/:id"><DeviceDetail /></Route>
                  <Route path="/device/add"><DeviceEdit type="add" /></Route>
                  <Route path="/device/edit/:id"><DeviceEdit type="edit" /></Route>
                  <Route path="/device"><DeviceList /></Route>
                  <Route path="/workspace/edit/:id"><WOrkspaceEdit /></Route>
                  <Route path="/workspace/detail/:id"><WorkspaceDetail /></Route>
                  <Route path="/salesspace"><Workspace isParentRoom={1} /></Route>
                  <Route path="/workspace"><Workspace isParentRoom={0} /></Route>
                  <Route path="/company/applicants"><ApplicantList /></Route>
                  <Route path="/company/invitation"><Invitation /></Route>
                  <Route path="/company"><CompanyManagement /></Route>
                  <Route path="/data"><DataManagement /></Route>
                  <Route path="/meetingroom"><MeetingRoomManagement /></Route>
                  <Route path="/energy/dailybill"><DailyBill /></Route>
                  <Route path="/energy/monthlybill"><MonthlyBill /></Route>
                  <Route path="/energy/monthlybillc"><MonthlyBillCalendarMode /></Route>
                  <Route path="/energy/invoice"><InvoiceList /></Route>
                  <Route path="/energy/clearrecord"><ClearRecord /></Route>
                  <Route path="/energy"><EnergyManagement /></Route>
                  <Route path="/personnelfiling/permission"><PersonnelFiling /></Route>
                  <Route path="/personnelfiling/log"><OperateLog /></Route>
                  <Route path="/card"><CardManagent /></Route>
                  <Route path="/knowledge/:bigCategoryId"><Knowledge /></Route>
                  <Route path="/task/:id"><TaskDetail /></Route>
                  <Route path="/task"><TaskManagement /></Route>
                  <Route path="/activity/add"><ActivityEdit type="add" /></Route>
                  <Route path="/activity/edit/:id"><ActivityEdit type="edit" /></Route>
                  <Route path="/activity/:id"><ActivityDetail /></Route>
                  <Route path="/activity"><ActivityList /></Route>
                  <Route path="/ad/add"><AdvertiseEdit type="add" /></Route>
                  <Route path="/ad/edit/:id"><AdvertiseEdit type="edit" /></Route>
                  <Route path="/ad/:id"><AdvertiseDetail /></Route>
                  <Route path="/ad"><AdvertiseList /></Route>
                </Switch>
              </Card>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainFrame;
