import produce from 'immer';

type KnowledgeStateType = {
  groupList: any[];
}

const initialState: KnowledgeStateType = {
  groupList: [],
}

const reducer = (state = initialState, action: any) => 
  produce(state, (draft): void => {
    switch(action.type) {
      case 'SET_KNOWLEDGE_GROUPLIST':
        draft.groupList = action.data;
        break;
    }
  })


export default reducer;