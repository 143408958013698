import { List as AntList, Pagination } from 'antd';
import styled from 'styled-components';
import React from 'react';
import Header from './Header';
import TableRow from './Row';
import { Column } from './type';
import { PaginationProps } from 'antd/lib/pagination';

const List = styled(AntList)`
  .ant-list-header {
    border-bottom: none;
  }
`;

interface Props {
  columns: Array<any>;
  dataSource: Array<any>;
  renderItem?: (item: any, index: number, c1: Array<Column>, c2: Array<Column>) => React.ReactNode;
  onChange?: PaginationProps['onChange'];
  current?: number;
  total?: number;
}

const Table:React.FC<Props> = ({
  columns,
  dataSource,
  renderItem,
  onChange,
  current,
  total
}) => {
  const c1 = columns.filter(c => !Boolean(c.width));
  const c2 = columns.filter(c => Boolean(c.width))
  return (
    <>
      <List
        header={<Header columns={c2} />}
        dataSource={dataSource}
        renderItem={
          (item: any, index: number) => 
            renderItem 
              ? renderItem(item, index, c1, c2) 
              : <TableRow c1={c1} c2={c2} index={index} data={item} />
        }
      />
      <Pagination
        current={current}
        total={total}
        onChange={onChange}
      />
    </>
  );
}

export default Table;