import React, { useState, useEffect } from "react";
import { Table, Button, Space, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  getSalePurposeList,
  getSalePuposeCount,
  deleteSalePurpose,
} from '@/services/workspace';
import SalePurposeEdit from "./Edit";

const ButtonAdd = styled(Button)`
  margin-bottom: 12px;
`

const { Column } = Table

interface Props {
  workspaceId: number | string;
  roomNo: string;
}

const SalePurpose: React.FC<Props> = ({
  roomNo,
}) => {
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>({})

  const getData = () => {
    const params = {}
    getSalePurposeList(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data)
      }
    })
    getSalePuposeCount(params).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setCount(data)
      }
    })
  }

  const onDelete = (id: string | number) => {
    deleteSalePurpose({id}).then((res: any) => {
      getData()
    })
  }

  const onEdit = (record: any) => {
    setSelectedItem(record);
    setVisible(true);
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <ButtonAdd icon={<PlusOutlined />} onClick={() => setVisible(true)}>添加销售情况跟踪</ButtonAdd>
      <Table
        dataSource={dataSource}
        rowKey={(record: any) => `sale_purpose_${record.id}`}
      >
        <Column title="销售情况跟踪（客户反馈）" dataIndex="saleInfo" />
        <Column title="销售" dataIndex="salesman" />
        <Column title="操作时间" dataIndex="updateTime" />
        <Column
          title="操作"
          dataIndex="id"
          render={(_, record) => (
            <Space>
              <Popconfirm
                title={`确认要删除该条记录吗？`}
                onConfirm={() => onDelete(_)}
              >
                <Button type="link">删除</Button>
              </Popconfirm>
              <Button type="link" onClick={() => onEdit(record)}>编辑</Button>
            </Space>
          )}
        />
      </Table>
      <SalePurposeEdit
        visible={visible}
        id={selectedItem?.id}
        data={selectedItem}
        roomNo={roomNo}
        onClose={() => {
          setVisible(false)
          getData()
        }}
      />
    </>
  )
}

export default SalePurpose
