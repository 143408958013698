import styled from 'styled-components';

interface RoomContainerProps {
  width: string | number;
  left: string | number;
  top: string | number;
  disabled: boolean;
}

const Container = styled.div<RoomContainerProps>`
  position: absolute;
  width: ${props => props.width};
  left: ${props => props.left};
  top: ${props => props.top};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`

interface TitleContainerProps {
  left?: string;
  top?: string;
}
interface TitleProps {
  left?: string;
  top?: string;
  fontSize?: string;
  width?: string;
  writingMode?: string;
}

// TODO: left 和 top 的判断以及产生的坐标计算，需要更细致一些，现在的逻辑存在漏洞

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: ${props => props.left ? 'flex-start' : 'center'};
  align-items: ${props => props.top ? 'flex-start' : 'center'};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  ${props => props.left ? `padding-left: ${props.left};` : null}
  ${props => props.top ? `padding-top: ${props.top};` : null}
`

const Title = styled.div<TitleProps>`
  color: #FFF;
  font-size: ${props => props.fontSize || '16px'};
  text-align: center;
  width: ${props => props.width || '100%'};
  ${props => props.writingMode ? `writing-mode: ${props.writingMode};` : null}
`

interface Props {
  image: string;
  selectedImage: string;
  expireImage: string;
  mismatchImage: string;
  title: string;
  titleLeft?: string;
  titleTop?: string;
  titleWidth?: string;
  writingMode?: string;
  width: string | number;
  left: string | number;
  top: string | number;
  fontSize: string;
  onClick: () => void;
  selected?: boolean;
  rentStatus: number;
  disabled: boolean;
}

const Room: React.FC<Props> = ({
  image,
  title,
  titleLeft,
  titleTop,
  titleWidth,
  writingMode,
  selected,
  selectedImage,
  expireImage,
  mismatchImage,
  rentStatus,
  fontSize,
  ...props
}) => {
  let src = image;
  if (rentStatus === undefined) {
    src = mismatchImage;
  }
  if (rentStatus === 1) {
    src = expireImage;
  }
  if (selected) {
    src = selectedImage
  }
  return (
    <Container {...props}>
      <img alt="" src={src} width="100%" />
      <TitleContainer left={titleLeft} top={titleTop}>
        <Title
          fontSize={fontSize}
          width={titleWidth}
          writingMode={writingMode}
        >{title}</Title>
      </TitleContainer>
    </Container>
  )
}

export default Room;