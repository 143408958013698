export function setSelectedCorpId(corpId: number | string) {
  return {
    type: 'SET_SELECTED_CORP_ID',
    data: corpId
  }
}

export function setPagination(pageIndex: number, pageSize: number) {
  return {
    type: 'SET_CORP_PAGINATION',
    data: {
      pageIndex,
      pageSize
    }
  }
}

export function setCorpName(corpName: string) {
  return {
    type: 'SET_CORP_NAME',
    data: corpName
  }
}

export function setCorpContractStatus(contractStatus: string) {
  return {
    type: 'SET_CORP_CONTRACT_STATUS',
    data: contractStatus
  }
}