import React from 'react';
import Cube from './Cube';
import img_purse from '@/assets/purse@2x.png';

interface Props {
  monthlySaveCost: number;
  dailySaveCost: number;
  rate: number;
}

const CubeSaveCost: React.FC<Props> = ({
  monthlySaveCost,
  dailySaveCost,
  rate
}) => {
  return (
    <Cube
      title="节省费用"
      dailyValue={`¥${dailySaveCost}`}
      dailyText="昨日节省费用"
      monthlyValue={`¥${monthlySaveCost}`}
      monthlyText="本月节省费用"
      rate={rate}
      logo={<img src={img_purse} alt="" />}
    />
  )
}

export default CubeSaveCost;