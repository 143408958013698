import bg from '@/assets/floor_plan/10/10lou.png';
import img_1001 from '@/assets/floor_plan/10/common/1001.png';
import img_1002 from '@/assets/floor_plan/10/common/1002.png';
import img_1003 from '@/assets/floor_plan/10/common/1003.png';
import img_1005 from '@/assets/floor_plan/10/common/1005.png';
import img_1006 from '@/assets/floor_plan/10/common/1006.png';
import img_1007 from '@/assets/floor_plan/10/common/1007.png';
import img_1001_selected from '@/assets/floor_plan/10/selected/1001.png';
import img_1002_selected from '@/assets/floor_plan/10/selected/1002.png';
import img_1003_selected from '@/assets/floor_plan/10/selected/1003.png';
import img_1005_selected from '@/assets/floor_plan/10/selected/1005.png';
import img_1006_selected from '@/assets/floor_plan/10/selected/1006.png';
import img_1007_selected from '@/assets/floor_plan/10/selected/1007.png';
import img_1001_expire from '@/assets/floor_plan/10/expire/1001.png';
import img_1002_expire from '@/assets/floor_plan/10/expire/1002.png';
import img_1003_expire from '@/assets/floor_plan/10/expire/1003.png';
import img_1005_expire from '@/assets/floor_plan/10/expire/1005.png';
import img_1006_expire from '@/assets/floor_plan/10/expire/1006.png';
import img_1007_expire from '@/assets/floor_plan/10/expire/1007.png';
import img_1001_mismatch from '@/assets/floor_plan/10/mismatch/1001.png';
import img_1002_mismatch from '@/assets/floor_plan/10/mismatch/1002.png';
import img_1003_mismatch from '@/assets/floor_plan/10/mismatch/1003.png';
import img_1005_mismatch from '@/assets/floor_plan/10/mismatch/1005.png';
import img_1006_mismatch from '@/assets/floor_plan/10/mismatch/1006.png';
import img_1007_mismatch from '@/assets/floor_plan/10/mismatch/1007.png';


const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;

const initialRoomListData = [
  {
    title: '1001',
    roomNo: '1001',
    width: width(214),
    left: left(447),
    top: top(280),
    image: img_1001,
    selectedImage: img_1001_selected,
    expireImage: img_1001_expire,
    mismatchImage: img_1001_mismatch
  },
  {
    title: '1002',
    roomNo: '1002',
    width: width(234),
    left: left(663),
    top: top(280),
    image: img_1002,
    selectedImage: img_1002_selected,
    expireImage: img_1002_expire,
    mismatchImage: img_1002_mismatch
  },
  {
    title: '1003',
    roomNo: '1003',
    width: width(264),
    left: left(899),
    top: top(280),
    image: img_1003,
    selectedImage: img_1003_selected,
    expireImage: img_1003_expire,
    mismatchImage: img_1003_mismatch
  },
  {
    title: '1005',
    roomNo: '1005',
    width: width(147),
    left: left(1016),
    top: top(434),
    image: img_1005,
    selectedImage: img_1005_selected,
    expireImage: img_1005_expire,
    mismatchImage: img_1005_mismatch
  },
  {
    title: '1006',
    roomNo: '1006',
    width: width(336),
    left: left(723),
    top: top(574),
    image: img_1006,
    selectedImage: img_1006_selected,
    expireImage: img_1006_expire,
    mismatchImage: img_1006_mismatch
  },
  {
    title: '1007',
    roomNo: '1007',
    width: width(320),
    left: left(401),
    top: top(492),
    image: img_1007,
    selectedImage: img_1007_selected,
    expireImage: img_1007_expire,
    mismatchImage: img_1007_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}