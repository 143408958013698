import { ReactChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  margin-bottom: 27px;

  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background: #3371FF;
  }
`

const Content = styled.div`
  padding-left: 12px;
  font-weight: 600;
  color: #1F2329;
`

type Props = {
  children?: string | ReactChildren;
}

const Title = ({ children }: Props) => (
  <Container>
    <Content>{children}</Content>
  </Container>
)

export default Title;