import styled from 'styled-components';

const Container = styled.div`
  background-color: #FAFBFF;
  border-radius: 5px;
  width: 288px;
  height: 277px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 24px;
  width: 100%;
`

const Title = styled.div`
  font-size: 14px;
  color: #1F2329;
  font-weight: 400;
`

const ControlContainer = styled.div``



const Button = styled.div``

interface Props {
  title: string;
  children?: React.ReactChildren | React.ReactChild;
}

const Panel: React.FC<Props> = ({
  title,
  children
}) => {
  return (
    <Container>
      <Header>
        <div></div>
        <Title>{title}</Title>
        <div></div>
      </Header>
      <ControlContainer>{children}</ControlContainer>
    </Container>
  )
}

export default Panel;