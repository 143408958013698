import { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Form, DatePicker, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import Title from '@/components/Form/Title';
import Input from '@/components/Form/Input';
import { useHistory, useParams } from 'react-router-dom';
import { ValueAndText, Whether } from '@/types/common';
import { addEmployee, updateEmployee, getEmployeeDetail } from '@/services/company';
import { getNationList } from '@/services/user';
import doUpload from '@/utils/upload';

const FormItem = Form.Item;
const { Option } = Select;

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Panel = styled.div`
  width: 666px;
`

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`

interface Props {
  type: 'add' | 'edit'
}

const EmployeeAdd: React.FC<Props> = (props) => {
  const history = useHistory();
  const { corpId, id } = useParams<any>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [_corpId, setCorpId] = useState(0);
  const [nationList, setNationList] = useState<string []>([]);
  const [isEmployedInSocialOrg, setIsEmployedInSocialOrg] = useState(false);
  const [tempOrgName, setTempOrgName] = useState('');
  const [idCardImgUrl1, setIdCardImgUrl1] = useState('');
  const [idCardImgUrl2, setIdCardImgUrl2] = useState('');
  const [headImgUrl, setHeadImgUrl] = useState('');
  const [degreeCertificateImgUrl, setDegreeCertificateImgUrl] = useState('');
  const [userId, setUserId] = useState(0);
  
  const educationList: Array<ValueAndText> = useSelector((state: any) => state.common.educationList);
  const employeeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.employeeTypeList);
  const politicsStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.politicsStatusList);
  const corpRoleList: Array<ValueAndText> = useSelector((state: any) => state.common.corpRoleList);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const save = (values: any) => {
    setLoading(true)
    if (props.type === 'add') {
      addEmployee({
        ...values,
        corpId,
        degreeCertificateImgUrl,
        headImgUrl,
        idCardFrontImgUrl: idCardImgUrl1,
        idCardReverseImgUrl: idCardImgUrl2,
        selfContactPhone: values.mobileNo,
      }).then(res => {
        const { resultCode, resultMsg } = res.data
        
        setLoading(false);
        
        if (resultCode === 0) {
          message.success('添加成功');
          setTimeout(() => {
            history.push('/company', { tab: 'employee' });
            // TODO: 跳转到/company，考虑带参数来决定是激活哪个tab
          }, 1500)
          return;
        }
        
        message.error(resultMsg);
        
      }).catch(() => {
        setLoading(false)
      })
    }
    else {
      updateEmployee({
        userId,
        employeeId: id,
        corpId: _corpId,
        degreeCertificateImgUrl,
        headImgUrl,
        idCardFrontImgUrl: idCardImgUrl1,
        idCardReverseImgUrl: idCardImgUrl2,
        selfContactPhone: values.mobileNo,
        ...values
      }).then(res => {  
        setLoading(false)
        message.success('修改成功');
          setTimeout(() => {
            history.push('/company', { tab: 'employee' });
            // TODO: 跳转到/company，考虑带参数来决定是激活哪个tab
          }, 1500)
      }).catch(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (props.type === 'edit') {
      getEmployeeDetail(id).then(res => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const employeeDetail = data;
          employeeDetail.birth = data.birth ? moment(data.birth) : data.birth;
          employeeDetail.entryDate = data.entryDate ? moment(data.entryDate): data.entryDate;
          employeeDetail.graduateDate= data.graduateDate ? moment(data.graduateDate): data.graduateDate;
          form.setFieldsValue(employeeDetail);
          setCorpId(employeeDetail.corpId);
          setIsEmployedInSocialOrg(employeeDetail.employedInSocialOrg === Whether.TRUE);
          setTempOrgName(employeeDetail.employerSocialOrgName);
          setDegreeCertificateImgUrl(employeeDetail.degreeCertificateImgUrl);
          setHeadImgUrl(employeeDetail.headImgUrl);
          setIdCardImgUrl1(employeeDetail.idCardFrontImgUrl);
          setIdCardImgUrl2(employeeDetail.idCardReverseImgUrl);
          setUserId(employeeDetail.userId);
        }
      })
    }
    getNationList().then((res: any) => {
      // setNationList(res);
      console.log(res.data)
      setNationList(Object.keys(res.data || {}))
    })
  }, [])

  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { history.goBack() }}><CloseOutlined /></Button>
      </ButtonBar>
      <Form form={form} onFinish={save} layout="vertical">
        <Container>
          <Panel>
            <Title>基本信息</Title>
            <FormContainer>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <FormItem
                    label="身份证姓名"
                    name="realName"
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="性别"
                    name="sex"
                  >
                    <Select>
                      <Option value={1}>男</Option>
                      <Option value={2}>女</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="民族"
                    name="nation"
                  >
                    <Select>
                      {
                        nationList.map((item: string, index: number) => (
                          <Option key={`emp_nation_${index}`} value={item}>{item}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="出生日期"
                    name="birth"
                  >
                    <DatePicker />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="联系电话"
                    name="mobileNo"
                  >
                    <Input disabled={userId > 0} />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="成员角色"
                    name="corpRole"
                  >
                    <Select options={corpRoleList} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="证件号码"
                    name="idCardNo"
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="头像">
                    <Upload
                      name="headImgUrl"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={(options) => {
                        doUpload(options).then(url => setHeadImgUrl(url))
                      }}
                    >
                      {headImgUrl ? <img src={headImgUrl} alt="" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="身份证正面">
                    <Upload
                      name="idCardImgUrl1"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={(options) => {
                        doUpload(options).then(url => setIdCardImgUrl1(url))
                      }}
                    >
                      {idCardImgUrl1 ? <img src={idCardImgUrl1} alt="" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="身份证反面">
                    <Upload
                      name="idCardImgUrl2"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={(options) => {
                        doUpload(options).then(url => setIdCardImgUrl2(url))
                      }}
                    >
                      {idCardImgUrl2 ? <img src={idCardImgUrl2} alt="" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </FormItem>
                </Col>
              </Row>
            </FormContainer>
          </Panel>
          <Panel>
            <Title>学历信息</Title>
            <FormContainer>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <FormItem
                    label="学历"
                    name="education"
                  >
                    <Select>
                      {
                        educationList.map(({value, text}, index) => (
                          <Option key={`emp_add_edu_${index}`} value={value}>{text}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="毕业学校"
                    name="graduateSchool"
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="毕业时间"
                    name="graduateDate"
                  >
                    <DatePicker />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="所学专业"
                    name="schoolMajor"
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="学位证照片">
                    <Upload
                      name="degreeCertificateImgUrl"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={(options) => {
                        doUpload(options).then(url => setDegreeCertificateImgUrl(url))
                      }}
                    >
                      {degreeCertificateImgUrl ? <img src={degreeCertificateImgUrl} alt="" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="学位证编号"
                    name="degreeCertificateNo"
                  >
                    <Input />
                  </FormItem>
                </Col>
              </Row>
            </FormContainer>
          </Panel>
          <Panel>
            <Title>社会关系</Title>
            <FormContainer>
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <FormItem
                    label="政治面貌"
                    name="politicsStatus"
                  >
                    <Select>
                      {
                        politicsStatusList.map(({value, text}, index) => (
                          <Option key={`emp_pls_${index}`} value={value}>{text}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="是否退伍军人" name="exSoldier" initialValue={Whether.FALSE}>
                    <Select>
                      <Option value={Whether.TRUE}>是</Option>
                      <Option value={Whether.FALSE}>否</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="是否任职社会组织" name="employedInSocialOrg" initialValue={Whether.FALSE}>
                    <Select onChange={val => {
                      setIsEmployedInSocialOrg(val === Whether.TRUE)
                      if (val === Whether.FALSE) {
                        setTempOrgName(form.getFieldValue('employerSocialOrgName'))
                        form.setFieldsValue({ employerSocialOrgName: '' })
                      }
                      else {
                        form.setFieldsValue({ employerSocialOrgName: tempOrgName })
                      }                      
                    }}>
                      <Option value={Whether.TRUE}>是</Option>
                      <Option value={Whether.FALSE}>否</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="任职的社会组织名称" name="employerSocialOrgName">
                    <Input disabled={!isEmployedInSocialOrg} />
                  </FormItem>
                </Col>
                
              </Row>
            </FormContainer>
          </Panel>
          <Panel style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
            <Button onClick={() => {
              history.goBack();
            }}>取消</Button>
            <Button
              type="primary"
              style={{ marginLeft: 12 }}
              htmlType="submit"
              loading={loading}
            >确定</Button>
          </Panel>
        </Container>
      </Form>
    </>
  )
}

export default EmployeeAdd;