import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { getOneMeetingRoomBooingList } from '@/services/meeting';

const Container = styled.div`
  min-width: 239px;
  min-height: 90px;
`

const RecordContainer = styled.div`
  border-bottom: 1px solid #DEE0E3;
  color: #3C4A73;
  font-size: 12px;
  padding: 9px 56px 9px 19px;

  > div {
    white-space: nowrap;
    line-height: 17px;
    height: 17px;
  }

  &:last-child {
    border: none;
  }
`

interface Props {
  roomNo: string;
  queryDate: string;
  bookingId?: number;
}

const DailyDetail: React.FC<Props> = ({
  roomNo,
  queryDate,
  bookingId,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    getOneMeetingRoomBooingList(
      roomNo,
      queryDate
    ).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        if (bookingId) {
          setData(data.filter((item: any) => item.bookingId === bookingId));
        }
        else {
          setData(data.filter((item: any) => item.useDate === queryDate));
        }
        
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }, [roomNo, queryDate]);
  return (
    <Spin spinning={loading}>
      <Container>
        {
          data.map((item: any, index: number) => (
            <RecordContainer key={`calendar_daily_detail_${index}`}>
              <div>{`预约编号：${item.bookingId}`}</div>
              <div>{`会议名称：${item.meetingSubject}`}</div>
              <div>{`公司名称：${item.corpName}`}</div>
              <div>{`开始时间：${item.useBeginTime}`}</div>
              <div>{`结束时间：${item.useEndTime}`}</div>
            </RecordContainer>
          ))
        }
      </Container>
    </Spin>
  )
}

export default DailyDetail;