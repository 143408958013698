import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './App.css';
import Login from './screens/Login';
import QRLogin from './screens/Login/QRLogin';
import Register from './screens/Register';
import MainFrame from './screens/MainFrame';
import {
  getContractStatus,
  getCountryList,
  getExpansionPlan,
  getPhoneCountryCode,
  getStaffSizeType,
  getCoprRoleList,
  getEducationList,
  getEmployeeTypeList,
  getJobStatusList,
  getIndustryList,
  getPatentTypeList,
  getCurrentFeeStageList
} from './services/company';
import { getUserStatusList } from './services/user';
import {
  getNetworkStatusList,
  getDeviceStatusList,
  getDeviceTypeList,
  getAssetTypeList
} from './services/device';
import {
  getRentStatusList,
  getWorkspaceSizeTypeList,
  getWorkspaceTypeList,
  getResourceTypeList,
} from './services/workspace';
import { getPoliticsStatusList } from './services/user';
import { getBusinessTypeList, getBillStatusList } from './services/bill';
import {
  getAdTypeList,
  getAdDisplayLocationList,
  getAdStatusList,
} from './services/advertise';
import {
  setCommonList,
} from './store/actions/common';




function App() {
  const dispatch = useDispatch();
  // TODO: 这部分初始化加载数据的代码，迁移到自定义hooks中。
  useEffect(() => {
    getStaffSizeType().then(data => dispatch(setCommonList('staffSizeTypeList', data)))
    getContractStatus().then(data => dispatch(setCommonList('contractStatusList', data)))
    getExpansionPlan().then(data => dispatch(setCommonList('expansionPlanList', data)))
    getCountryList().then(data => dispatch(setCommonList('countryList', data)))
    getPhoneCountryCode().then(data => dispatch(setCommonList('phoneCountryCodeList', data)))
    getCoprRoleList().then(data => dispatch(setCommonList('corpRoleList', data)))
    getEducationList().then(data => dispatch(setCommonList('educationList', data)))
    getEmployeeTypeList().then(data => dispatch(setCommonList('employeeTypeList', data)))
    getJobStatusList().then(data => dispatch(setCommonList('jobStatusList', data)))
    getUserStatusList().then(data => dispatch(setCommonList('userStatusList', data)))
    getDeviceStatusList().then(data => dispatch(setCommonList('deviceStatusList', data)))
    getDeviceTypeList().then(data => dispatch(setCommonList('deviceTypeList', data)))
    getNetworkStatusList().then(data => dispatch(setCommonList('networkStatusList', data)))
    getRentStatusList().then(data => dispatch(setCommonList('rentStatusList', data)))
    getWorkspaceSizeTypeList().then(data => dispatch(setCommonList('workspaceSizeTypeList', data)))
    getWorkspaceTypeList().then(data => dispatch(setCommonList('workspaceTypeList', data)))
    getResourceTypeList().then(data => dispatch(setCommonList('resourceTypeList', data)))
    getPoliticsStatusList().then(data => dispatch(setCommonList('politicsStatusList', data)))
    getIndustryList().then(data => dispatch(setCommonList('industryList', data)))
    getPatentTypeList().then(data => dispatch(setCommonList('patentTypeList', data)))
    getCurrentFeeStageList().then(data => dispatch(setCommonList('currentFeeStageList', data)))
    getAssetTypeList().then(data => dispatch(setCommonList('assetTypeList', data)))
    getBusinessTypeList().then(data => dispatch(setCommonList('businessTypeList', data)))
    getBillStatusList().then(data => dispatch(setCommonList('billStatusList', data)))
    getAdTypeList().then(data => dispatch(setCommonList('adTypeList', data)))
    getAdDisplayLocationList().then(data => dispatch(setCommonList('adDisplayLocationList', data)))
    getAdStatusList().then(data => dispatch(setCommonList('adStatusList', data)))
  }, []);

  return (
    <Router basename="/web">
      <Switch>
        <Route path="/login"><Login /></Route>
        <Route path="/qrlogin"><QRLogin /></Route>
        <Route path="/register"><Register /></Route>
        <Route path="/"><MainFrame /></Route>
      </Switch>
    </Router>
  );
}

export default App;
