import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Drawer, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { getFileCategoryList } from '@/services/knowledge';
import Content from './Content';
import CategoryEditor from './CategoryEditor';


const { TabPane } = Tabs;

const defaultCategory = {
  categoryId: 0,
  categoryName: '默认分类',
  parentCategoryId: 0,
}

const Knowledge = () => {
  const { bigCategoryId } = useParams<any>();
  const [fileCategoryList, setFileCategoryList] = useState<any[]>([defaultCategory]);
  const [activeKey, setActiveKey] = useState('file_category_0');
  const [categoryEditorVisible, setCategoryEditorVisible] = useState(false);

  const onTabEdit = () => {
    setCategoryEditorVisible(true);
  }

  const getData = () => {
    getFileCategoryList({
      currentPage: 1,
      pageSize: 90,
      parentCategoryId: bigCategoryId
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setFileCategoryList([
          defaultCategory,
          ...data
        ]);
      }
    })
  }

  useEffect(() => {
    getData();
  }, [bigCategoryId]);

  return (
    <div style={{ height: '100%'}}>
      <Tabs
        type="editable-card"
        defaultActiveKey={activeKey} 
        onChange={key => setActiveKey(key)}
        onEdit={onTabEdit}
      >
        {
          fileCategoryList.map((item: any) => (
            <TabPane tab={item.categoryName} key={`file_category_${item.categoryId}`} closable={false}>
              <Content categoryId={item.categoryId} bigCategoryId={bigCategoryId} />
            </TabPane>
          ))
        }
      </Tabs>
      <Drawer
        destroyOnClose
        maskClosable={false}
        visible={categoryEditorVisible}
        title="添加分组"
        width={500}
        onClose={() => setCategoryEditorVisible(false)}
      >
        <CategoryEditor
          parentCategoryId={bigCategoryId}
          onClose={() => {
            setCategoryEditorVisible(false);
            getData();
          }}
        />
      </Drawer>
    </div>
  )
}

export default Knowledge;