export type Dictionary = {
  [key: string]: any;
}

export type ValueAndText = {
  value: string | number;
  text: string;
  label?: string;
}

export type UserInfo = {
  id: string | number;
  name: string;
  corpName: string;
  corpId: string | number;
  isLogin: boolean;
}

export type Sex = 1 | 2;

export type Company = {
  belongIndustry: string;     // 所属行业
  businessLicenseUrl: string; // 营业执照地址
  businessType: string;       // 主营业务
  checkinDate: string;        // 入驻日期（格式：YYYY-MM-DD）
  contactEmail: string;       // 联系邮箱
  contactMobile: string;      // 联系人手机号码
  contactName: string;        // 联系人姓名
  contactTelephone: string;   // 固定电话
  contractStatus: number;     // 合同状态
  corpAddress: string;        // 地址
  corpCountry: string;        // 所属国家或地区
  corpId: number;             // 公司编号
  corpName: string;           // 公司名称
  corpNo: string;             // 企业编号
  expansionPlan: number;      // 扩容计划
  postcode: string;           // 邮编
  salesman: string;           // 对应销售
  staffSizeType: number;      // 公司规模
  websiteUrl: string;         // 企业官网
  patentImageUrl: string;     // 专利图片地址
  patentDescription: string;  // 专利描述
  point: number;              // 积分
  patentRecordList: any[];    // 专利列表
  status: number;             // 合同状态
  lastRentContractRecord?: any; // 合同字段集
  legalPersonIdPositiveImg: string;
  legalPersonIdNegativeImg: string;
  legalPersonIdNumber: string;
  legalPersonName: string;
}

export enum ContractStatus {
  Negtiating = 0,
  Rejected = 1,
  Unpaid = 2,
  Paid = 3,
  Retreat = 4,
}

export enum ContractImgType {
  ContractAttachment = 0,
  SupplementaryAttachment = 1,
  PaymentVoucher = 2
}

export enum JobStatus {
  Applying = 0,
  OnTheJob = 1,
  Resign = 2,
  Rejected = 4
}

export type Option = {
  text: string;
  value: string | number;
}

export type Options = Array<Option>

export type IsParentRoom = 1 | 0;

export enum Whether {
  TRUE = 1,
  FALSE = 0
}

export enum CalendarMode {
  month = 'month',
  week = 'week',
  day = 'day'
}

export type DisplayMode = 'floor' | 'list'

export enum CardStatus {
  VALID = 1,
  INVALID = 0
}