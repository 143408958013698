import { useEffect, useState } from 'react';
import { Input, Table, Popover, Space, Button, message, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import classNames from 'classnames';
import img_search from '@/assets/search@2x.png';
import { getMeetingRoomList, cancelBookingRecord } from '@/services/meeting';
import Title from '@/components/Form/Title';
import Chip from '@/components/Chip';
import DailyDetail from './DailyDetail';
import { getSelectedTextFromList } from '@/utils/transform';
import AuditModal from '../AuditModal';
import BookingModal from '../bookingModal';

const { Column } = Table;

const Container = styled.div`
  overflow: auto;
  margin-bottom: 30px;
  -moz-user-select:none; 
  -webkit-user-select:none; 
  -ms-user-select:none;
  -khtml-user-select:none;
  user-select:none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  height: 36px;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const SearchBox = styled.div`
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #C2C5CA;
  > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > input {
    width: 211px;
  }
`

const TimesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #1F2329;
  font-size: 12px;
  > .cell {
    width: 41px;
    height: 35px;
    text-align: center;
    line-height: 36px;
    border-bottom: 1px solid #C2C5CA;
    &:first-child {
      border-left: 1px solid #E8E8E8;
    }
  }
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > .roomName {
    width: 224px;
    padding-left: 8px;
    border-bottom: 1px solid #E2EBFF;
    > div {
      width: 224px;
      line-height: 35px;
    }
  }

  .cell {
    width: 41px;
    height: 35px;
    text-align: center;
    line-height: 36px;
    border-left: 1px solid #E2EBFF;
    border-bottom: 1px solid #E2EBFF;
    > div {
      display: inline-block;
      width: 50%;
      height: 100%;
      cursor: pointer;
    }
    >.used {
      background-color: #E2EBFF;
      color: #123FA7;
    }
    >.selected {
      background-color: #82A7FC;
      color: #123FA7;
      cursor: pointer;
    }
    >.disabled {
      cursor: not-allowed;
    }
  } 
`

interface Props {
  queryDate: string;
}

const timeList = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23' ];

const fillCalendarData = (data: any[], queryDate: string) => {
  const calendarData: any[] = [];

  data.forEach((item: any) => {
    let roomData = calendarData.find(cd => cd.roomNo === item.roomNo);
    if (!roomData) {
      roomData = {
        roomNo: item.roomNo,
        roomAlias: item.roomAlias,
        floorNo: item.floorNo,
        times: Array.from(timeList, x => ({
          time: Number(x),
          datetimeA: moment(`${queryDate} ${x}:00:00`),
          datetimeB: moment(`${queryDate} ${x}:30:00`),
          isUsedA: false,
          isUsedB: false,
          optionalA: true,
          optionalB: true,
          selectedA: false,
          selectedB: false,
          valueA: Number(x),
          valueB: Number(x) + 0.5
        }))
      }
      calendarData.push(roomData);
    }

    item.bookingList.forEach((bookingRecord: any) => {
      const beginTime = moment(bookingRecord.useBeginTime)
      const endTime = moment(bookingRecord.useEndTime)
      roomData.times
        .filter((t: any) => t.time >= beginTime.hour() && t.time <= endTime.hour())
        .forEach((t: any) => {
          t.isUsedA = t.datetimeA >= beginTime && t.datetimeB <= endTime;
          t.isUsedB = t.datetimeB < endTime;
          if (t.datetimeA >= beginTime && t.datetimeA <= endTime) {
            t.bookingIdA = bookingRecord.bookingId;
          }
          if (t.datetimeB >= beginTime && t.datetimeB <= endTime) {
            t.bookingIdB = bookingRecord.bookingId
          }
        })
    })
  })

  return calendarData;
}

const fillBookingData = (data: any[]): any[] => {
  const bookingData: Array<any> = [];

  data.forEach((item: any) => {
    item.bookingList.forEach((bookingRecord: any) => {
      bookingData.push({
        ...bookingRecord,
        realGalleryful: item.realGalleryful,
        workspaceType: item.workspaceType
      })
    })
  })

  return bookingData;
}

const Calendar: React.FC<Props> = ({
  queryDate
}) => {
  const [roomNoForFilter, setRoomNoForFilter] = useState('');
  const [calendarData, setCalendarData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [auditModalVisible, setAuditModalVisible] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(0);
  const [selectedRoomNo, setSelectedRoomNo] = useState('');
  const [selectedFloorNo, setSelectedFloorNo] = useState('');
  const [selectedBeginTime, setSelectedBeginTime] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState('');
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const workspaceTypeList = useSelector((state: any) => state.common.workspaceTypeList);
  

  const getData = () => {
    getMeetingRoomList({
      beginUseDate: queryDate,
      endUseDate: queryDate,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setOriginData(data);
        setTableData(fillBookingData(data));
        setCalendarData(fillCalendarData(data, queryDate));
      }
    })
  }

  const showBookingModal = () => {
    const roomData = calendarData.find(c => c.roomNo = selectedRoomNo);
    if (!roomData) {
      message.error('未选择会议室')
      return;
    }

    let selectedTimes: any[] = [];
    roomData.times.filter((t: any) => t.selectedA || t.selectedB).forEach((t: any) => {
      if (t.selectedA) {
        selectedTimes.push(t.datetimeA);
      }
      if (t.selectedB) {
        selectedTimes.push(t.datetimeB);
      }
    })

    for (let i = 0; i < selectedTimes.length; i++) {
      if (i === 0) {
        continue;
      }

      if (selectedTimes[i].diff(selectedTimes[i-1], 'minutes') > 30) {
        message.warning('预约时间需要是连续的时间段');
        return;
      }
    }

    setSelectedBeginTime(selectedTimes[0].format('YYYY-MM-DD HH:mm:ss'));
    setSelectedEndTime(selectedTimes[selectedTimes.length-1].clone().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
    setBookingModalVisible(true);
  }

  const selectTimes = (roomNo: string, floorNo: string, time: any) => {
    let firstBookingTime: any;
    let hasSelectedTimes = false;

    const roomData = calendarData.find(c => c.roomNo === roomNo);
    if (!roomData) {
      return;
    }
    roomData.times.forEach((t: any) => {
      t.optionalA = t.optionalB = true;
      if (t.datetimeA === time) {
        t.selectedA = !t.selectedA;
        hasSelectedTimes = hasSelectedTimes || t.selectedA;
      }
      if (t.datetimeA >= firstBookingTime) {
        t.optionalA = false;
      }
      if (t.isUsedA && !firstBookingTime && hasSelectedTimes) {
        firstBookingTime = t.datetimeA;
      }
      
      
      if (t.datetimeB === time) {
        t.selectedB = !t.selectedB;
        hasSelectedTimes = hasSelectedTimes || t.selectedB;
      }
      if (t.datetimeB >= firstBookingTime) {
        t.optionalB = false;
      }
      if (t.isUsedB && !firstBookingTime && hasSelectedTimes) {
        firstBookingTime = t.datetimeB;
      }
    })

    setCalendarData([...calendarData]);

    if (roomData.times.filter((t: any) => t.selectedA || t.selectedB).length === 0) {
      setSelectedRoomNo('');
      setSelectedFloorNo('');
    }
    else {
      setSelectedRoomNo(roomNo);
      setSelectedFloorNo(floorNo);
    }
  }

  useEffect(() => {
    getData();
  }, [queryDate]);

  useEffect(() => {
    const filterdData = originData.filter((item: any) => item.roomNo.includes(roomNoForFilter));
    setTableData(fillBookingData(filterdData));
    setCalendarData(fillCalendarData(filterdData, queryDate));
  }, [
    roomNoForFilter
  ])

  return (
    <>
      <Container>
        <Header>
          <SearchBox>
            <img src={img_search} alt="" />
            <Input placeholder="搜索会议室" bordered={false} width={200} onChange={e => setRoomNoForFilter(e.target.value)} />
          </SearchBox>
          <TimesContainer>
            {
              timeList.map((t: string, index: number) => (
                <div className="cell" key={`time_${index}`}>{`${t}:00`}</div>
              ))
            }
          </TimesContainer>
        </Header>
        {
          calendarData.map((item: any, index: number) => {
            const selectedItems = item.times.filter((t: any) => t.selectedA || t.selectedB);
            const lastSelectedItem = selectedItems.length > 0 ? selectedItems[selectedItems.length - 1] : null;
            const lastSelectedTime = lastSelectedItem ? (lastSelectedItem.selectedB ? lastSelectedItem.datetimeB : lastSelectedItem.datetimeA) : null;
            return (
              <TableRow key={`table_row_time_${index}`}>
                <div className="roomName">
                  <div>{`${item.roomNo} - ${item.roomAlias}`}</div>
                </div>
                <TimesContainer>
                  {
                    item.times.map((t: any) => {
                      const stylesA = classNames({
                        'selected': t.selectedA,
                        'disabled': t.isUsedA || !t.optionalA || (item.roomNo !== selectedRoomNo && !!selectedRoomNo)
                      })
                      const stylesB = classNames({
                        'selected': t.selectedB,
                        'disabled': t.isUsedB || !t.optionalB || (item.roomNo !== selectedRoomNo && !!selectedRoomNo)
                      })
                      return (
                        <div className="cell">
                          {
                            t.isUsedA ? (
                              <Popover placement="right" trigger="click" content={<DailyDetail roomNo={item.roomNo} queryDate={queryDate} bookingId={t.bookingIdA} />}>
                                <div className="used"></div>
                              </Popover>
                            ) : (
                              <Popover
                                placement="top"
                                trigger="click"
                                visible={t.datetimeA === lastSelectedTime && !bookingModalVisible}
                                content={<Button onClick={() => showBookingModal()}>预约会议室</Button>}
                              >
                                <div className={stylesA} onClick={() => {
                                  if (t.isUsedA || !t.optionalA || (item.roomNo !== selectedRoomNo && !!selectedRoomNo)) {
                                    return;
                                  }
                                  selectTimes(item.roomNo, item.floorNo, t.datetimeA)
                                }}></div>
                              </Popover>
                            )
                          }
                          {
                            t.isUsedB ? (
                              <Popover placement="right" trigger="click" content={<DailyDetail roomNo={item.roomNo} queryDate={queryDate} bookingId={t.bookingIdB} />}>
                                <div className="used"></div>
                              </Popover>
                            ) : (
                              <Popover
                                placement="top"
                                trigger="click"
                                visible={t.datetimeB === lastSelectedTime && !bookingModalVisible}
                                content={<Button onClick={() => showBookingModal()}>预约会议室</Button>}
                              >
                                <div className={stylesB} onClick={() => {
                                  if (t.isUsedB || !t.optionalB || (item.roomNo !== selectedRoomNo && !!selectedRoomNo)) {
                                    return;
                                  }
                                  selectTimes(item.roomNo, item.floorNo, t.datetimeB)
                                }}></div>
                              </Popover>
                            )
                          }
                        </div>
                      )
                    })
                  }
                </TimesContainer>
              </TableRow>
            )
          })
        }
      </Container>
      <Title>会议记录</Title>
      <Table
        dataSource={tableData}
        pagination={false}
        rowKey={(record: any) => `calendar_month_data_${record.bookingId}`}
      >
        <Column title="会议名称" dataIndex="meetingSubject" />
        <Column title="使用方" dataIndex="corpName" />
        <Column title="用户名称" dataIndex="bookingUserRealName" />
        <Column title="预约发起时间" dataIndex="createTime" />
        <Column title="会议室" dataIndex="roomNo" />
        <Column
          title="会议室类型"
          dataIndex="workspaceType"
          render={_ => getSelectedTextFromList(workspaceTypeList, _)}
        />
        <Column title="容纳人数" dataIndex="realGalleryful" />
        <Column
          title="开始时间"
          dataIndex="useBeginTime"
          sorter={(a: any, b: any) => moment(a.useBeginTime).diff(b.useBeginTime)}
          sortDirections={['ascend', 'descend']}
          sortOrder="descend"
        />
        <Column title="结束时间" dataIndex="useEndTime" />
        <Column
          title="审批状态"
          dataIndex="status"
          render={_ => <Chip meetingRoomStatus={_} />}
        />
        <Column
          title="操作"
          dataIndex="bookingId"
          render={(_: number, record: any) => {
            if (record.workspaceType === 20 && record.status === 10) {
              return (
                <Space>
                  <Button
                    type="link"
                    onClick={() => {
                      setIsApproved(true);
                      setAuditModalVisible(true);
                      setSelectedBookingId(_);
                    }}
                  >通过</Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setIsApproved(false);
                      setAuditModalVisible(true);
                      setSelectedBookingId(_);
                    }}
                  >拒绝</Button>
                </Space>
              )
            }
            return (
              <>
                <Popconfirm
                  title={`确定要取消会议${record.bookingId}吗？`}
                  onConfirm={() => {
                    cancelBookingRecord(_).then((res: any) => {
                      const { resultCode, resultMsg } = res.data;
                      if (resultCode === 0) {
                        message.success(`已取消会议：${_}`);
                        return;
                      }
                      message.error(resultMsg);
                    })
                  }}
                >
                  <Button type="link">取消</Button>
                </Popconfirm>
              </>
            )
          }}
        />
      </Table>
      <AuditModal
        bookingId={selectedBookingId}
        visible={auditModalVisible}
        isApproved={isApproved}
        onClose={() => setAuditModalVisible(false)}
        onOk={() => getData()}
      />
      <BookingModal
        roomNo={selectedRoomNo}
        floorNo={selectedFloorNo}
        beginTime={selectedBeginTime}
        endTime={selectedEndTime}
        visible={bookingModalVisible}
        onClose={() => setBookingModalVisible(false)}
        onOk={() => {
          getData();
          setAuditModalVisible(false);
        }}
      />
    </>
  )
}

export default Calendar;