import { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import { updateFile } from '@/services/knowledge';

const FormItem = Form.Item;

interface Props {
  visible: boolean;
  id: number | string;
  fileName: string;
  onClose: () => void;
}

const ModalChangeName: React.FC<Props> = ({
  visible,
  id,
  fileName,
  onClose
}) => {
  const [form] = Form.useForm();

  const onSave = () => {
    form.validateFields().then((values: any) => {
      updateFile({
        id,
        ...values
      }).then((res: any) => {
        const { resultCode } = res.data;
        if (resultCode === 0) {
          onClose();
        }
      })
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      fileName
    })
  }, [fileName])

  return (
    <Modal
      visible={visible}
      title="编辑名称"
      onCancel={onClose}
      onOk={onSave}
    >
      <Form form={form}>
        <FormItem
          label="名称"
          name="fileName"
        >
          <Input />
        </FormItem>
      </Form>
    </Modal>
  )
}

export default ModalChangeName;