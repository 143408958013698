import { useState, useEffect } from 'react';
import { updateDevice, getDeviceInfo } from '@/services/device';
import { ValueAndText } from '@/types/common';
import { Form, Input, Select, Modal, message } from 'antd';
import { useSelector } from 'react-redux';

const { Option } = Select;
const FormItem = Form.Item;

interface Props {
  deviceId?: number;
  visible: boolean;
  onClose: () => void;
  onOk: () => void;
}

const EditDevice: React.FC<Props> = ({
  deviceId,
  visible,
  onClose,
  onOk
}) => {
  const [form] = Form.useForm();
  const deviceStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceStatusList);
  const deviceTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.deviceTypeList);
  const networkStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.networkStatusList);

  const onSave = () => {
    const values = form.getFieldsValue();
    updateDevice({
      ...values,
      deviceId
    }).then((res: any) => {
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        message.success('保存成功');
        onOk();
        return;
      }
      message.error(resultMsg)
    })
  }

  useEffect(() => {
    if (deviceId) {
      getDeviceInfo(deviceId).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          form.setFieldsValue(data);
        }
      })
    }
  }, [deviceId])

  return (
    <Modal
     visible={visible}
     title="编辑设备"
     cancelText="取消"
     okText="保存"
     onCancel={onClose}
     onOk={onSave}
     width={900}
    >
      <Form form={form} onFinish={onSave}>
        <FormItem label="设备名称" name="deviceName">
          <Input />
        </FormItem>
        <FormItem label="设备编号" name="deviceNo">
          <Input />
        </FormItem>
        <FormItem label="型号" name="deviceModel">
          <Input />
        </FormItem>
        <FormItem label="使用状态" name="deviceStatus">
          <Select>
            {
              deviceStatusList.map((item, index) => (
                <Option key={`device_add_devicestatus_opt_${index}`} value={item.value}>{item.text}</Option>
              ))
            }
          </Select>
        </FormItem>
        <FormItem label="设备类型" name="deviceType">
          <Select>
              {
                deviceTypeList.map((item, index) => (
                  <Option key={`device_add_devicetype_opt_${index}`} value={item.value}>{item.text}</Option>
                ))
              }
            </Select>
        </FormItem>
        <FormItem label="联网状态" name="networkStatus">
          <Select>
            {
              networkStatusList.map((item, index) => (
                <Option key={`device_add_networkstatus_opt_${index}`} value={item.value}>{item.text}</Option>
              ))
            }
          </Select>
        </FormItem>
      </Form>
    </Modal>
  )
}

export default EditDevice;