import styled from 'styled-components';

interface ButtonProps {
  size?: 'small' | 'normal';
  actived?: boolean;
  disabled?: boolean;
}

export const Button = styled.div<ButtonProps>`
  width: ${props => props.size === 'small' ? '38px' : '48px'};
  height: 22px;
  background: #FFFFFF;
  border-radius: 11px;
  border: 1px solid #C2C5CA;
  text-align: center;
  line-height: 19px;
  font-size: 14px;
  color: #8F959E;
  cursor: pointer;

  ${props => props.actived ? `
  background: #3371FF;
  border-color: #3371FF;
  color: #FFFFFF;
  ` : ''}

  ${props => props.disabled ? `
  background-color: #F1F2F4;
  border-color: #C2C5CA;
  color: #8F959E;
  cursor: not-allowed;
  ` : ''}

  &.actived {
    background: #3371FF;
    border-color: #3371FF;
    color: #FFFFFF;
  }
  &.disabled {
    background-color: #F1F2F4;
    border-color: #C2C5CA;
    color: #8F959E;
    cursor: not-allowed;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`