import { useState, useEffect } from 'react';
import Room from '@/components/Room';
import './style.less';
import F4 from '../floors/F4';
import F5 from '../floors/F5';
import F6 from '../floors/F6';
import F7 from '../floors/F7';
import F8 from '../floors/F8';
import F9 from '../floors/F9';
import F10 from '../floors/F10';
import F11 from '../floors/F11';
import F12 from '../floors/F12';

interface Props {
  bg: string;
  initialRoomList: any[];
  workspaceList: any[];
  selectedRoomNo: string;
  onClick: (roomNo: string) => void;
}

const Floor: React.FC<Props> = ({
  bg,
  initialRoomList,
  workspaceList,
  selectedRoomNo,
  onClick
}) => {
  const [roomList, setRoomList] = useState<any[]>(initialRoomList);

  useEffect(() => {
    setRoomList(initialRoomList.map((item: any) => {
      const roomData = workspaceList.find((d: any) => d.roomNo === item.roomNo);
      if (!roomData) {
        return item
      }

      return {
        ...item,
        ...roomData
      }
    }))
  }, [workspaceList, initialRoomList])

  return (
    <div className="floor_plan_container">
      <img src={bg} width="100%" alt="" />
      {
        roomList.map((item, index) => (
          <Room
            key={`room_f4_${index}`}
            {...item}
            selected={selectedRoomNo === item.roomNo}
            disabled={!item.workspaceId}
            onClick={() => {
              if (!item.workspaceId) {
                return;
              }
              onClick(item.roomNo);
            }}
          />
        ))
      }
    </div>
  )
}

interface FloorWrapperProps {
  floorNo: string;
  workspaceList: any[];
  selectedRoomNo: string;
  onClick: (roomNo: string) => void;
}

const FloorWrapper: React.FC<FloorWrapperProps> = ({
  floorNo,
  ...others
}) => {
  if (floorNo.includes('4')) {
    return <Floor {...F4} {...others} />
  }
  if (floorNo.includes('5')) {
    return <Floor {...F5} {...others} />
  }
  if (floorNo.includes('6')) {
    return <Floor {...F6} {...others} />
  }
  if (floorNo.includes('7')) {
    return <Floor {...F7} {...others} />
  }
  if (floorNo.includes('8')) {
    return <Floor {...F8} {...others} />
  }
  if (floorNo.includes('9')) {
    return <Floor {...F9} {...others} />
  }
  if (floorNo.includes('10')) {
    return <Floor {...F10} {...others} />
  }
  if (floorNo.includes('11')) {
    return <Floor {...F11} {...others} />
  }
  if (floorNo.includes('12')) {
    return <Floor {...F12} {...others} />
  }
  

  return <></>
}

export default FloorWrapper;