import bg from '@/assets/energy/floors/5/5.png';
import img_500_1_common from '@/assets/energy/floors/5/common/500-1.png';
import img_500_2_common from '@/assets/energy/floors/5/common/500-2.png';
import img_500_3_common from '@/assets/energy/floors/5/common/500-3.png';
import img_500_4_common from '@/assets/energy/floors/5/common/500-4.png';
import img_500_5_common from '@/assets/energy/floors/5/common/500-5.png';
import img_500_6_common from '@/assets/energy/floors/5/common/500-6.png';
import img_500_7_common from '@/assets/energy/floors/5/common/500-7.png';
import img_500_8_common from '@/assets/energy/floors/5/common/500-8.png';
import img_500_9_common from '@/assets/energy/floors/5/common/500-9.png';
import img_500_10_common from '@/assets/energy/floors/5/common/500-10.png';
import img_501_1_common from '@/assets/energy/floors/5/common/501-1.png';
import img_501_2_common from '@/assets/energy/floors/5/common/501-2.png';
import img_501_3_common from '@/assets/energy/floors/5/common/501-3.png';
import img_502_1_common from '@/assets/energy/floors/5/common/502-1.png';
import img_502_2_common from '@/assets/energy/floors/5/common/502-2.png';
import img_502_3_common from '@/assets/energy/floors/5/common/502-3.png';
import img_503_1_common from '@/assets/energy/floors/5/common/503-1.png';
import img_503_2_common from '@/assets/energy/floors/5/common/503-2.png';
import img_503_3_common from '@/assets/energy/floors/5/common/503-3.png';
import img_505_1_common from '@/assets/energy/floors/5/common/505-1.png';
import img_505_2_common from '@/assets/energy/floors/5/common/505-2.png';
import img_506_1_common from '@/assets/energy/floors/5/common/506-1.png';
import img_506_2_common from '@/assets/energy/floors/5/common/506-2.png';
import img_506_3_common from '@/assets/energy/floors/5/common/506-3.png';
import img_507_common from '@/assets/energy/floors/5/common/507.png';
import img_508_1_common from '@/assets/energy/floors/5/common/508-1.png';
import img_508_2_common from '@/assets/energy/floors/5/common/508-2.png';
import img_508_3_common from '@/assets/energy/floors/5/common/508-3.png';
import img_509_common from '@/assets/energy/floors/5/common/509.png';
import img_510_common from '@/assets/energy/floors/5/common/510.png';
import img_511_1_common from '@/assets/energy/floors/5/common/511-1.png';
import img_511_3_common from '@/assets/energy/floors/5/common/511-3.png';
import img_512_1_common from '@/assets/energy/floors/5/common/512-1.png';
import img_512_2_common from '@/assets/energy/floors/5/common/512-2.png';
import img_512_3_common from '@/assets/energy/floors/5/common/512-3.png';
import img_513_1_common from '@/assets/energy/floors/5/common/513-1.png';
import img_513_3_common from '@/assets/energy/floors/5/common/513-3.png';
import img_515_1_common from '@/assets/energy/floors/5/common/515-1.png';
import img_515_3_common from '@/assets/energy/floors/5/common/515-3.png';
import img_516_1_common from '@/assets/energy/floors/5/common/516-1.png';
import img_516_3_common from '@/assets/energy/floors/5/common/516-3.png';
import img_517_1_common from '@/assets/energy/floors/5/common/517-1.png';
import img_517_2_common from '@/assets/energy/floors/5/common/517-2.png';
import img_517_3_common from '@/assets/energy/floors/5/common/517-3.png';
import img_518_1_common from '@/assets/energy/floors/5/common/518-1.png';
import img_518_2_common from '@/assets/energy/floors/5/common/518-2.png';
import img_518_3_common from '@/assets/energy/floors/5/common/518-3.png';
import img_519_1_common from '@/assets/energy/floors/5/common/519-1.png';
import img_519_2_common from '@/assets/energy/floors/5/common/519-2.png';
import img_519_3_common from '@/assets/energy/floors/5/common/519-3.png';
import img_520_1_common from '@/assets/energy/floors/5/common/520-1.png';
import img_520_2_common from '@/assets/energy/floors/5/common/520-2.png';
import img_520_3_common from '@/assets/energy/floors/5/common/520-3.png';
import img_521_1_common from '@/assets/energy/floors/5/common/521-1.png';
import img_521_2_common from '@/assets/energy/floors/5/common/521-2.png';
import img_521_3_common from '@/assets/energy/floors/5/common/521-3.png';
import img_522_1_common from '@/assets/energy/floors/5/common/522-1.png';
import img_522_2_common from '@/assets/energy/floors/5/common/522-2.png';
import img_523_1_common from '@/assets/energy/floors/5/common/523-1.png';
import img_523_2_common from '@/assets/energy/floors/5/common/523-2.png';
import img_523_3_common from '@/assets/energy/floors/5/common/523-3.png';
import img_525_1_common from '@/assets/energy/floors/5/common/525-1.png';
import img_525_2_common from '@/assets/energy/floors/5/common/525-2.png';
import img_525_3_common from '@/assets/energy/floors/5/common/525-3.png';
import img_530_common from '@/assets/energy/floors/5/common/530.png';
import img_531_common from '@/assets/energy/floors/5/common/531.png';
import img_532_common from '@/assets/energy/floors/5/common/532.png';
import img_533_common from '@/assets/energy/floors/5/common/533.png';
import img_534_common from '@/assets/energy/floors/5/common/534.png';
import img_535_common from '@/assets/energy/floors/5/common/535.png';
import img_536_common from '@/assets/energy/floors/5/common/536.png';
import img_537_common from '@/assets/energy/floors/5/common/537.png';
import img_538_common from '@/assets/energy/floors/5/common/538.png';
import img_539_common from '@/assets/energy/floors/5/common/539.png';
import img_540_common from '@/assets/energy/floors/5/common/540.png';
import img_541_common from '@/assets/energy/floors/5/common/541.png';
import img_542_common from '@/assets/energy/floors/5/common/542.png';
import img_543_common from '@/assets/energy/floors/5/common/543.png';
import img_544_common from '@/assets/energy/floors/5/common/544.png';
import img_545_common from '@/assets/energy/floors/5/common/545.png';
import img_546_common from '@/assets/energy/floors/5/common/546.png';
import img_547_common from '@/assets/energy/floors/5/common/547.png';
import img_548_common from '@/assets/energy/floors/5/common/548.png';
import img_549_common from '@/assets/energy/floors/5/common/549.png';

import img_500_1_selected from '@/assets/energy/floors/5/selected/500-1.png';
import img_500_2_selected from '@/assets/energy/floors/5/selected/500-2.png';
import img_500_3_selected from '@/assets/energy/floors/5/selected/500-3.png';
import img_500_4_selected from '@/assets/energy/floors/5/selected/500-4.png';
import img_500_5_selected from '@/assets/energy/floors/5/selected/500-5.png';
import img_500_6_selected from '@/assets/energy/floors/5/selected/500-6.png';
import img_500_7_selected from '@/assets/energy/floors/5/selected/500-7.png';
import img_500_8_selected from '@/assets/energy/floors/5/selected/500-8.png';
import img_500_9_selected from '@/assets/energy/floors/5/selected/500-9.png';
import img_500_10_selected from '@/assets/energy/floors/5/selected/500-10.png';
import img_501_1_selected from '@/assets/energy/floors/5/selected/501-1.png';
import img_502_1_selected from '@/assets/energy/floors/5/selected/502-1.png';
import img_502_2_selected from '@/assets/energy/floors/5/selected/502-2.png';
import img_502_3_selected from '@/assets/energy/floors/5/selected/502-3.png';
import img_503_1_selected from '@/assets/energy/floors/5/selected/503-1.png';
import img_503_2_selected from '@/assets/energy/floors/5/selected/503-2.png';
import img_503_3_selected from '@/assets/energy/floors/5/selected/503-3.png';
import img_505_1_selected from '@/assets/energy/floors/5/selected/505-1.png';
import img_505_2_selected from '@/assets/energy/floors/5/selected/505-2.png';
import img_506_1_selected from '@/assets/energy/floors/5/selected/506-1.png';
import img_506_3_selected from '@/assets/energy/floors/5/selected/506-3.png';
import img_507_selected from '@/assets/energy/floors/5/selected/507.png';
import img_508_1_selected from '@/assets/energy/floors/5/selected/508-1.png';
import img_508_2_selected from '@/assets/energy/floors/5/selected/508-2.png';
import img_508_3_selected from '@/assets/energy/floors/5/selected/508-3.png';
import img_509_selected from '@/assets/energy/floors/5/selected/509.png';
import img_510_selected from '@/assets/energy/floors/5/selected/510.png';
import img_511_1_selected from '@/assets/energy/floors/5/selected/511-1.png';
import img_511_3_selected from '@/assets/energy/floors/5/selected/511-3.png';
import img_512_1_selected from '@/assets/energy/floors/5/selected/512-1.png';
import img_512_2_selected from '@/assets/energy/floors/5/selected/512-2.png';
import img_512_3_selected from '@/assets/energy/floors/5/selected/512-3.png';
import img_513_1_selected from '@/assets/energy/floors/5/selected/513-1.png';
import img_513_3_selected from '@/assets/energy/floors/5/selected/513-3.png';
import img_515_1_selected from '@/assets/energy/floors/5/selected/515-1.png';
import img_515_3_selected from '@/assets/energy/floors/5/selected/515-3.png';
import img_516_1_selected from '@/assets/energy/floors/5/selected/516-1.png';
import img_516_3_selected from '@/assets/energy/floors/5/selected/516-3.png';
import img_517_1_selected from '@/assets/energy/floors/5/selected/517-1.png';
import img_517_2_selected from '@/assets/energy/floors/5/selected/517-2.png';
import img_517_3_selected from '@/assets/energy/floors/5/selected/517-3.png';
import img_518_1_selected from '@/assets/energy/floors/5/selected/518-1.png';
import img_518_2_selected from '@/assets/energy/floors/5/selected/518-2.png';
import img_518_3_selected from '@/assets/energy/floors/5/selected/518-3.png';
import img_519_1_selected from '@/assets/energy/floors/5/selected/519-1.png';
import img_519_2_selected from '@/assets/energy/floors/5/selected/519-2.png';
import img_519_3_selected from '@/assets/energy/floors/5/selected/519-3.png';
import img_520_1_selected from '@/assets/energy/floors/5/selected/520-1.png';
import img_520_2_selected from '@/assets/energy/floors/5/selected/520-2.png';
import img_520_3_selected from '@/assets/energy/floors/5/selected/520-3.png';
import img_521_1_selected from '@/assets/energy/floors/5/selected/521-1.png';
import img_521_2_selected from '@/assets/energy/floors/5/selected/521-2.png';
import img_521_3_selected from '@/assets/energy/floors/5/selected/521-3.png';
import img_522_1_selected from '@/assets/energy/floors/5/selected/522-1.png';
import img_522_2_selected from '@/assets/energy/floors/5/selected/522-2.png';
import img_523_1_selected from '@/assets/energy/floors/5/selected/523-1.png';
import img_523_2_selected from '@/assets/energy/floors/5/selected/523-2.png';
import img_523_3_selected from '@/assets/energy/floors/5/selected/523-3.png';
import img_525_1_selected from '@/assets/energy/floors/5/selected/525-1.png';
import img_525_2_selected from '@/assets/energy/floors/5/selected/525-2.png';
import img_525_3_selected from '@/assets/energy/floors/5/selected/525-3.png';
import img_530_selected from '@/assets/energy/floors/5/selected/530.png';
import img_531_selected from '@/assets/energy/floors/5/selected/531.png';
import img_532_selected from '@/assets/energy/floors/5/selected/532.png';
import img_533_selected from '@/assets/energy/floors/5/selected/533.png';
import img_534_selected from '@/assets/energy/floors/5/selected/534.png';
import img_535_selected from '@/assets/energy/floors/5/selected/535.png';
import img_536_selected from '@/assets/energy/floors/5/selected/536.png';
import img_537_selected from '@/assets/energy/floors/5/selected/537.png';
import img_538_selected from '@/assets/energy/floors/5/selected/538.png';
import img_539_selected from '@/assets/energy/floors/5/selected/539.png';
import img_540_selected from '@/assets/energy/floors/5/selected/540.png';
import img_541_selected from '@/assets/energy/floors/5/selected/541.png';
import img_542_selected from '@/assets/energy/floors/5/selected/542.png';
import img_543_selected from '@/assets/energy/floors/5/selected/543.png';
import img_544_selected from '@/assets/energy/floors/5/selected/544.png';
import img_545_selected from '@/assets/energy/floors/5/selected/545.png';
import img_546_selected from '@/assets/energy/floors/5/selected/546.png';
import img_547_selected from '@/assets/energy/floors/5/selected/547.png';
import img_548_selected from '@/assets/energy/floors/5/selected/548.png';
import img_549_selected from '@/assets/energy/floors/5/selected/549.png';


const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 310) / 328}%`;
const fontSize = (w: number, f: number) => `${100 * (f / w)}%`

// titleLeft: `${100 * (1017 - 370) / 830}%`,
// titleTop: `${100 * (531 - 399) / 830}%`,

const initialRoomList = [
  {
    title: '501-1',
    titleTop: `${100 * (363 - 313) / 110}%`,
    roomNo: '501-1',
    width: width(110),
    left: left(300),
    top: top(313),
    fontSize: fontSize(110, 16),
    image: img_501_1_common,
    selectedImage: img_501_1_selected,
  },
  {
    title: '501-2',
    roomNo: '501-2',
    width: width(32),
    left: left(338),
    top: top(313),
    fontSize: '12px',
    image: img_501_2_common,
  },
  {
    title: '501-3',
    roomNo: '501-3',
    width: width(38),
    left: left(372),
    top: top(313),
    fontSize: fontSize(38, 12),
    image: img_501_3_common,
  },
  {
    title: '503-1',
    titleTop: `${100 * (363 - 313) / 88}%`,
    roomNo: '503-1',
    width: width(88),
    left: left(412),
    top: top(313),
    fontSize: fontSize(88, 16),
    image: img_503_1_common,
    selectedImage: img_503_1_selected,
  },
  {
    title: '503-2',
    roomNo: '503-2',
    width: width(32),
    left: left(452),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_503_2_common,
  },
  {
    title: '503-3',
    roomNo: '503-3',
    width: width(38),
    left: left(412),
    top: top(313),
    fontSize: fontSize(38, 12),
    image: img_503_3_common,
  },
  {
    title: '505-1',
    titleTop: `${100 * (363 - 313) / 98}%`,
    roomNo: '505-1',
    width: width(98),
    left: left(502),
    top: top(313),
    fontSize: fontSize(98, 16),
    image: img_505_1_common,
    selectedImage: img_505_1_selected,
  },
  {
    title: '505-2',
    roomNo: '505-2',
    width: width(32),
    left: left(528),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_505_2_common,
  },
  // {
  //   title: '505-3',
  //   roomNo: '505-3',
  //   width: width(38),
  //   left: left(562),
  //   top: top(313),
  //   fontSize: fontSize(38, 12),
  //   image: img_505_3_common,
  // },
  {
    title: '506-1',
    titleTop: `${100 * (363 - 313) / 94}%`,
    roomNo: '506-1',
    width: width(94),
    left: left(602),
    top: top(313),
    fontSize: fontSize(94, 16),
    image: img_506_1_common,
    selectedImage: img_506_1_selected,
  },
  {
    title: '506-2',
    roomNo: '506-2',
    width: width(32),
    left: left(642),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_506_2_common,
  },
  {
    title: '506-3',
    roomNo: '506-3',
    width: width(38),
    left: left(602),
    top: top(313),
    fontSize: fontSize(38, 12),
    image: img_506_3_common,
  },
  {
    title: '新风机房',
    roomNo: '534',
    width: width(38),
    left: left(698),
    top: top(313),
    fontSize: fontSize(38, 12),
    writingMode: 'vertical-lr',
    image: img_534_common,
    selectedImage: img_534_selected,
  },
  {
    title: '507',
    roomNo: '507',
    width: width(52),
    left: left(738),
    top: top(313),
    fontSize: fontSize(52, 16),
    image: img_507_common,
    selectedImage: img_507_selected,
  },
  {
    title: '508-1',
    titleTop: `${100 * (363 - 313) / 94}%`,
    roomNo: '508-1',
    width: width(94),
    left: left(792),
    top: top(313),
    fontSize: fontSize(94, 16),
    image: img_508_1_common,
    selectedImage: img_508_1_selected,
  },
  {
    title: '508-2',
    roomNo: '508-2',
    width: width(32),
    left: left(814),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_508_2_common,
  },
  {
    title: '508-3',
    roomNo: '508-3',
    width: width(38),
    left: left(848),
    top: top(313),
    fontSize: fontSize(38, 12),
    image: img_508_3_common,
  },
  {
    title: '512-1',
    titleTop: `${100 * (363 - 313) / 94}%`,
    roomNo: '512-1',
    width: width(94),
    left: left(888),
    top: top(313),
    fontSize: fontSize(94, 16),
    image: img_512_1_common,
    selectedImage: img_512_1_selected,
  },
  {
    title: '512-2',
    roomNo: '512-2',
    width: width(32),
    left: left(928),
    top: top(313),
    fontSize: fontSize(32, 12),
    image: img_512_2_common,
  },
  {
    title: '512-3',
    roomNo: '512-3',
    width: width(38),
    left: left(888),
    top: top(313),
    fontSize: fontSize(38, 12),
    image: img_512_3_common,
  },
  {
    title: '茶水休闲区',
    roomNo: '500-4',
    width: width(108),
    left: left(984),
    top: top(313),
    fontSize: fontSize(108, 16),
    image: img_500_4_common,
    selectedImage: img_500_4_selected,
  },
  {
    title: '509',
    roomNo: '509',
    width: width(48),
    left: left(1094),
    top: top(327),
    fontSize: fontSize(48, 16),
    image: img_509_common,
    selectedImage: img_509_selected,
  },
  {
    title: '510',
    roomNo: '510',
    width: width(48),
    left: left(1144),
    top: top(327),
    fontSize: fontSize(48, 16),
    image: img_510_common,
    selectedImage: img_510_selected,
  },
  {
    title: '511-1',
    titleTop: `${100 * (363 - 327) / 80}%`,
    roomNo: '511-1',
    width: width(80),
    left: left(1194),
    top: top(327),
    fontSize: fontSize(80, 12),
    image: img_511_1_common,
    selectedImage: img_511_1_selected,
  },
  {
    title: '511-3',
    roomNo: '511-3',
    width: width(32),
    left: left(1194),
    top: top(327),
    fontSize: fontSize(32, 12),
    image: img_511_3_common,
  },
  {
    title: '新风机房',
    roomNo: '540',
    width: width(70),
    left: left(1204),
    top: top(397),
    fontSize: 'small',
    image: img_540_common,
    selectedImage: img_540_selected,
  },
  {
    title: '517-1',
    titleTop: `${100 * (505 - 429) / 70}%`,
    roomNo: '517-1',
    width: width(70),
    left: left(1204),
    top: top(429),
    fontSize: fontSize(70, 16),
    image: img_517_1_common,
    selectedImage: img_517_1_selected,
  },
  {
    title: '517-2',
    roomNo: '517-2',
    width: width(50),
    left: left(1224),
    top: top(455),
    fontSize: fontSize(50, 12),
    image: img_517_2_common,
    // selectedImage: img_517_2_selected,
  },
  {
    title: '517-3',
    roomNo: '517-3',
    width: width(50),
    left: left(1224),
    top: top(429),
    fontSize: fontSize(50, 12),
    image: img_517_3_common,
    // selectedImage: img_517_3_selected,
  },
  {
    title: '513-1',
    titleLeft: `${100 * (1219 - 1174) / 100}%`,
    titleTop: `${100 * (579 - 555) / 100}%`,
    titleWidth: 'auto',
    roomNo: '513-1',
    width: width(100),
    left: left(1174),
    top: top(555),
    fontSize: fontSize(100, 16),
    image: img_513_1_common,
    selectedImage: img_513_1_selected,
  },
  {
    title: '513-3',
    roomNo: '513-3',
    width: width(30),
    left: left(1174),
    top: top(575),
    fontSize: fontSize(30, 12),
    image: img_513_3_common,
    // selectedImage: img_513_3_selected,
  },
  {
    title: '516-1',
    titleLeft: `${100 * (1087 - 1072) / 100}%`,
    titleTop: `${100 * (579 - 555) / 100}%`,
    titleWidth: 'auto',
    roomNo: '516-1',
    width: width(100),
    left: left(1072),
    top: top(555),
    fontSize: fontSize(100, 16),
    image: img_516_1_common,
    selectedImage: img_516_1_selected,
  },
  {
    title: '516-3',
    roomNo: '516-3',
    width: width(30),
    left: left(1142),
    top: top(575),
    fontSize: fontSize(30, 12),
    image: img_516_3_common,
    // selectedImage: img_516_3_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '500-3',
    width: width(82),
    left: left(988),
    top: top(555),
    fontSize: fontSize(82, 12),
    image: img_500_3_common,
    selectedImage: img_500_3_selected,
  },
  {
    title: '515-1',
    titleLeft: `${100 * (940 - 904) / 82}%`,
    titleTop: `${100 * (579 - 555) / 82}%`,
    titleWidth: 'auto',
    roomNo: '515-1',
    width: width(82),
    left: left(904),
    top: top(555),
    fontSize: fontSize(82, 16),
    image: img_515_1_common,
    selectedImage: img_515_1_selected,
  },
  {
    title: '515-3',
    roomNo: '515-3',
    width: width(30),
    left: left(904),
    top: top(575),
    fontSize: fontSize(30, 12),
    image: img_515_3_common,
    // selectedImage: img_515_3_selected,
  },
  {
    title: '518-1',
    titleTop: `${100 * (565 - 555) / 108}%`,
    roomNo: '518-1',
    width: width(108),
    left: left(794),
    top: top(555),
    fontSize: fontSize(108, 16),
    image: img_518_1_common,
    selectedImage: img_518_1_selected,
  },
  {
    title: '518-2',
    roomNo: '518-2',
    width: width(32),
    left: left(834),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_518_2_common,
    // selectedImage: img_518_2_selected,
  },
  {
    title: '518-3',
    roomNo: '518-3',
    width: width(38),
    left: left(794),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_518_3_common,
    // selectedImage: img_518_3_selected,
  },
  {
    title: '519-1',
    titleTop: `${100 * (565 - 555) / 94}%`,
    roomNo: '519-1',
    width: width(94),
    left: left(698),
    top: top(555),
    fontSize: fontSize(94, 16),
    image: img_519_1_common,
    selectedImage: img_519_1_selected,
  },
  {
    title: '519-2',
    roomNo: '519-2',
    width: width(32),
    left: left(720),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_519_2_common,
    // selectedImage: img_519_2_selected,
  },
  {
    title: '519-3',
    roomNo: '519-3',
    width: width(38),
    left: left(754),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_519_3_common,
    // selectedImage: img_519_3_selected,
  },
  {
    title: '520-1',
    titleTop: `${100 * (565 - 555) / 94}%`,
    roomNo: '520-1',
    width: width(94),
    left: left(602),
    top: top(555),
    fontSize: fontSize(94, 16),
    image: img_520_1_common,
    // selectedImage: img_520_1_selected,
  },
  {
    title: '520-2',
    roomNo: '520-2',
    width: width(32),
    left: left(642),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_520_2_common,
    // selectedImage: img_520_2_selected,
  },
  {
    title: '520-3',
    roomNo: '520-3',
    width: width(38),
    left: left(602),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_520_3_common,
    // selectedImage: img_520_3_selected,
  },
  {
    title: '521-1',
    titleTop: `${100 * (565 - 555) / 98}%`,
    roomNo: '521-1',
    width: width(98),
    left: left(502),
    top: top(555),
    fontSize: fontSize(98, 16),
    image: img_521_1_common,
    selectedImage: img_521_1_selected,
  },
  {
    title: '521-2',
    roomNo: '521-2',
    width: width(32),
    left: left(528),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_521_2_common,
    // selectedImage: img_521_2_selected,
  },
  {
    title: '521-3',
    roomNo: '521-3',
    width: width(38),
    left: left(562),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_521_3_common,
    // selectedImage: img_521_3_selected,
  },
  {
    title: '525-1',
    titleTop: `${100 * (565 - 555) / 88}%`,
    roomNo: '525-1',
    width: width(88),
    left: left(412),
    top: top(555),
    fontSize: fontSize(88, 16),
    image: img_525_1_common,
    selectedImage: img_525_1_selected,
  },
  {
    title: '525-2',
    roomNo: '525-2',
    width: width(32),
    left: left(452),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_525_2_common,
    // selectedImage: img_525_2_selected,
  },
  {
    title: '525-3',
    roomNo: '525-3',
    width: width(38),
    left: left(412),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_525_3_common,
    // selectedImage: img_525_3_selected,
  },
  {
    title: '523-1',
    titleTop: `${100 * (565 - 555) / 110}%`,
    roomNo: '523-1',
    width: width(110),
    left: left(300),
    top: top(555),
    fontSize: fontSize(110, 12),
    image: img_523_1_common,
    selectedImage: img_523_1_selected,
  },
  {
    title: '523-2',
    roomNo: '523-2',
    width: width(32),
    left: left(338),
    top: top(597),
    fontSize: fontSize(32, 12),
    image: img_523_2_common,
    // selectedImage: img_523_2_selected,
  },
  {
    title: '523-3',
    roomNo: '523-3',
    width: width(38),
    left: left(372),
    top: top(597),
    fontSize: fontSize(38, 12),
    image: img_523_3_common,
    // selectedImage: img_523_3_selected,
  },
  {
    title: '522-1',
    titleLeft: `${100 * (358 - 300) / 118}%`,
    titleWidth: 'auto',
    roomNo: '522-1',
    width: width(118),
    left: left(300),
    top: top(476),
    fontSize: fontSize(118, 16),
    image: img_522_1_common,
    selectedImage: img_522_1_selected,
  },
  {
    title: '522-2',
    roomNo: '522-2',
    width: width(40),
    left: left(300),
    top: top(476),
    fontSize: fontSize(40, 12),
    image: img_522_2_common,
    // selectedImage: img_522_2_selected,
  },
  {
    title: '502-1',
    titleLeft: `${100 * (358 - 300) / 118}%`,
    titleWidth: 'auto',
    roomNo: '502-1',
    width: width(118),
    left: left(300),
    top: top(422),
    fontSize: fontSize(118, 16),
    image: img_502_1_common,
    selectedImage: img_502_1_selected,
  },
  {
    title: '502-2',
    roomNo: '502-2',
    width: width(40),
    left: left(300),
    top: top(422),
    fontSize: fontSize(40, 12),
    image: img_502_2_common,
    // selectedImage: img_502_2_selected,
  },
  {
    title: '530',
    roomNo: '530',
    width: width(58),
    left: left(444),
    top: top(422),
    fontSize: fontSize(58, 12),
    image: img_530_common,
    selectedImage: img_530_selected,
  },
  {
    title: '549',
    roomNo: '549',
    width: width(58),
    left: left(444),
    top: top(476),
    fontSize: fontSize(58, 12),
    image: img_549_common,
    selectedImage: img_549_selected,
  },
  {
    title: '531',
    roomNo: '531',
    width: width(17),
    left: left(548),
    top: top(423),
    fontSize: fontSize(17, 12),
    image: img_531_common,
    selectedImage: img_531_selected,
  },
  {
    title: '532',
    roomNo: '532',
    width: width(40),
    left: left(568),
    top: top(422),
    fontSize: fontSize(40, 12),
    image: img_532_common,
    selectedImage: img_532_selected,
  },
  {
    title: '548',
    roomNo: '548',
    width: width(40),
    left: left(568),
    top: top(470),
    fontSize: fontSize(40, 12),
    image: img_548_common,
    selectedImage: img_548_selected,
  },
  {
    title: '533',
    roomNo: '533',
    width: width(58),
    left: left(634),
    top: top(422),
    fontSize: fontSize(58, 16),
    image: img_533_common,
    selectedImage: img_533_selected,
  },
  {
    title: '547',
    roomNo: '547',
    width: width(48),
    left: left(634),
    top: top(476),
    fontSize: fontSize(48, 16),
    image: img_547_common,
    selectedImage: img_547_selected,
  },
  {
    title: '535',
    roomNo: '535',
    width: width(58),
    left: left(694),
    top: top(422),
    fontSize: fontSize(58, 16),
    image: img_535_common,
    selectedImage: img_535_selected,
  },
  {
    title: '546',
    roomNo: '546',
    width: width(58),
    left: left(694),
    top: top(476),
    fontSize: fontSize(58, 16),
    image: img_546_common,
    selectedImage: img_546_selected,
  },
  {
    title: '536',
    roomNo: '536',
    width: width(34),
    left: left(778),
    top: top(422),
    fontSize: fontSize(34, 12),
    image: img_536_common,
    selectedImage: img_536_selected,
  },
  {
    title: '537',
    roomNo: '537',
    width: width(42),
    left: left(814),
    top: top(422),
    fontSize: fontSize(42, 16),
    image: img_537_common,
    selectedImage: img_537_selected,
  },
  {
    title: '545',
    roomNo: '545',
    width: width(60),
    left: left(796),
    top: top(476),
    fontSize: fontSize(60, 16),
    image: img_545_common,
    selectedImage: img_545_selected,
  },
  {
    title: '538',
    roomNo: '538',
    width: width(58),
    left: left(882),
    top: top(22),
    fontSize: fontSize(58, 16),
    image: img_538_common,
    selectedImage: img_538_selected,
  },
  {
    title: '543',
    roomNo: '543',
    width: width(58),
    left: left(882),
    top: top(488),
    fontSize: fontSize(58, 16),
    image: img_543_common,
    selectedImage: img_543_selected,
  },
  {
    title: '542',
    roomNo: '542',
    width: width(34),
    left: left(983),
    top: top(502),
    fontSize: fontSize(34, 12),
    image: img_542_common,
    selectedImage: img_542_selected,
  },
  {
    title: '539',
    roomNo: '539',
    width: width(18),
    left: left(1086),
    top: top(422),
    fontSize: fontSize(18, 12),
    writingMode: 'vertical-lr',
    image: img_539_common,
    selectedImage: img_539_selected,
  },
  {
    title: '541',
    roomNo: '541',
    width: width(26),
    left: left(1082),
    top: top(489),
    fontSize: fontSize(26, 12),
    image: img_541_common,
    selectedImage: img_541_selected,
  },
  {
    title: '500-2',
    roomNo: '500-2',
    width: width(856),
    left: left(344),
    top: top(396),
    fontSize: fontSize(856, 12),
    color: '#808080',
    image: img_500_2_common,
    selectedImage: img_500_2_selected,
  },
  {
    title: '500-1',
    roomNo: '500-1',
    width: width(856),
    left: left(344),
    top: top(529),
    fontSize: fontSize(856, 12),
    color: '#808080',
    image: img_500_1_common,
    selectedImage: img_500_1_selected,
  },
  {
    title: '500-10',
    roomNo: '500-10',
    width: width(18),
    left: left(422),
    top: top(422),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_10_common,
    selectedImage: img_500_10_selected,
  },
  {
    title: '500-9',
    roomNo: '500-9',
    width: width(18),
    left: left(612),
    top: top(422),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_9_common,
    selectedImage: img_500_9_selected,
  },
  {
    title: '500-8',
    roomNo: '500-8',
    width: width(18),
    left: left(756),
    top: top(422),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_8_common,
    selectedImage: img_500_8_selected,
  },
  {
    title: '500-7',
    roomNo: '500-7',
    width: width(18),
    left: left(860),
    top: top(422),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_7_common,
    selectedImage: img_500_7_selected,
  },
  {
    title: '500-6',
    roomNo: '500-6',
    width: width(22),
    left: left(1022),
    top: top(422),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_6_common,
    selectedImage: img_500_6_selected,
  },
  {
    title: '500-5',
    roomNo: '500-5',
    width: width(18),
    left: left(1182),
    top: top(422),
    fontSize: fontSize(18, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_500_5_common,
    selectedImage: img_500_5_selected,
  },
]

export default {
  bg,
  initialRoomList
}