import { Row, Col, Input, Select, DatePicker } from 'antd';
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";

const { Option, OptGroup } = Select;

interface Props {
  data?: any;
  floorRoomList: any;
}

const CorpStatusForm1: React.FC<Props> = ({
  data,
  floorRoomList
}) => {
  return (
    <>
      <Row gutter={[22, 0]}>
        <Col span={12}>
          <FormItem label="最后一次沟通时间" name="lastCommunicateTime">
            {
              data ? (
                <Text>{data?.lastCommunicateTime}</Text>
              ) : (
                <DatePicker />
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="最后一次沟通结果" name="roomList">
            {
              data ? (
                <Text>{data?.roomList}</Text>
              ) : (
                <Select mode="multiple" showSearch>
                  {
                    Object.keys(floorRoomList).sort((prev: string, next: string) => {
                      return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
                    }).map((floorName: string, floorIndex: number) => (
                      <OptGroup key={`device_edit_floow_${floorIndex}`} label={floorName}>
                        {
                          floorRoomList[floorName].map((roomName: string, roomIndex: number) => (
                            <Option key={`device_edit_${floorIndex}room_${roomIndex}`} value={roomName}>{roomName}</Option>
                          ))
                        }
                      </OptGroup>
                    ))
                  }
                </Select>
              )
            }
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="沟通人" name="communicatePerson">
            {
              data ? (
                <Text>{data?.communicatePerson}</Text>
              ) : (
                <Input />
              )
            }
          </FormItem>
        </Col>
      </Row>
    </>
  )
}

export default CorpStatusForm1;