import { useState } from 'react';
import styled from 'styled-components';
import { Input, Button, message, Divider } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { login } from '@/services/user';
import img_cover from '@/assets/illustration@2x.png';
import styles from './style.module.less';


const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #FFF;
`

const RightPanel = styled.div`
  height: 100vh;
  > img {
    height: 100%;
  }
`

const LoginPanel = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 60px 30px;
  height: 70vh;
`

const Title  = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const SubTitle = styled.div`
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 30px;
`

const Label = styled.div`
  font-size: 13px;
  border-bottom: 2px solid #3371FF;
  color: #3371FF;
  display: inline-block;
  margin-bottom: 12px;
`

const Login = () => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const changeMobile = (e: any) => {
    setMobile(e.target.value);
    setBtnDisabled(!e.target.value || !password);
  }
  const changePassword = (e: any) => {
    setPassword(e.target.value);
    setBtnDisabled(!e.target.value || !mobile);
  }
  const doLogin = () => {
    login(mobile, password).then(res => {
      const { resultCode, resultMsg } = res;
      if (resultCode !== 0) {
        message.error(resultMsg);
        return;
      }

      history.push('/company')
    })
  }
  

  return (
    <Container>
      <LeftPanel>
        <LoginPanel>
          <Title>登录</Title>
          <SubTitle>
            登录即代表你已阅读并同意
            <a>服务协议</a>
            和
            <a>隐私政策</a>
          </SubTitle>
          <Label>手机号</Label>
          <Input type="tel" placeholder="请输入你的手机号" value={mobile} onChange={changeMobile} />
          <Input type="password" placeholder="请输入你的密码" value={password} onChange={changePassword} style={{ marginTop: 8 }} />
          <Button type="primary" disabled={btnDisabled} className={styles.btnNext} onClick={doLogin}>登录</Button>
          <SubTitle>
            还没有账号？
            <Link to="/register">立即注册</Link>
          </SubTitle>
        </LoginPanel>
      </LeftPanel>
      <RightPanel>
        <img src={img_cover} alt="" />
      </RightPanel>
    </Container>
  )
}

export default Login;