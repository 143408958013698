import { useState, useEffect } from "react";
import styled from 'styled-components';
import { Space, Button, Popconfirm, message, Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getSelectedTextFromList } from '@/utils/transform';
import { getEmployeeDetail, updateJobStatus } from '@/services/company';
import { ValueAndText } from "@/types/common";

const Container = styled.div`
  width: 400px;
  position: relative;
  height: 100%;
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
`

const ListItem = styled.div`
  padding: 7px 23px;
`

const Label = styled.div`
  font-size: 14px;
  color: #646A73;
  padding-bottom: 8px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1F2329;
`

const BottomBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const AvatorBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 23px;

  > .userName {
    color: #1F2329;
    font-weight: 500;
    padding-left: 8px;
    font-size: 14px;
  }
`

const Avator = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-radius: 4px;
`

interface Props {
  idList?: any[];
  index: number;
}

const Detail: React.FC<Props> = ({ 
  index, 
  idList = []
}) => {
  const [detail, setDetail] = useState<any>();
  const [currentEmployeeId, setCurrentEmployeeId] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(index);
  const jobStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.jobStatusList);
  const corpRoleList: any[] = useSelector((state: any) => state.common.corpRoleList);

  const getData = () => {
    getEmployeeDetail(currentEmployeeId).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data);
      }
    })
  }

  const doResign = () => {
    updateJobStatus(currentEmployeeId, 2).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        message.success('操作完成')
        getData()
      }
    })
  }

  const doNext = () => {
    setCurrentIndex(currentIndex + 1);
    setCurrentEmployeeId(idList[currentIndex + 1].id);
  }

  const doPrev = () => {
    setCurrentIndex(currentIndex - 1);
    setCurrentEmployeeId(idList[currentIndex - 1].id);
  }

  useEffect(() => {
    console.log('index: ', index)
    if (index >= 0 && idList.length > 0) {
      setCurrentIndex(index);
      setCurrentEmployeeId(idList[index].id)
    }
  }, [index, idList])

  useEffect(() => {
    getData()
  }, [currentEmployeeId])

  return (
    <Container>
      <ButtonBar>
        <Space>
          <Link to={`/company/editemployee/${currentEmployeeId}`}><Button>编辑</Button></Link>
          <Link to={`/company/employee/${currentEmployeeId}`}><Button>详情</Button></Link>
          <Popconfirm
            title={`确认变更${detail?.realName}状态为“离职”吗？`}
            onConfirm={doResign}
          >
            <Button danger disabled={detail?.jobStatus === 2}>操作离职</Button>
          </Popconfirm>
        </Space>
      </ButtonBar>
      <AvatorBar>
        <Avator>
          <img src={detail?.headImgUrl} />
        </Avator>
        <div className="userName">{detail?.realName}</div>
      </AvatorBar>
      <ListItem>
        <Label>姓名</Label>
        <Text>{detail?.realName}</Text>
      </ListItem>
      <ListItem>
        <Label>用户ID</Label>
        <Text>{detail?.id}</Text>
      </ListItem>
      <ListItem>
        <Label>手机号码</Label>
        <Text>{detail?.mobileNo}</Text>
      </ListItem>
      <ListItem>
        <Label>公司</Label>
        <Text></Text>
      </ListItem>
      <ListItem>
        <Label>性别</Label>
        <Text>{detail?.sex == 1 ? '男' : '女'}</Text>
      </ListItem>
      <ListItem>
        <Label>成员角色</Label>
        <Text>{getSelectedTextFromList(corpRoleList, detail?.corpRole)}</Text>
      </ListItem>
      <ListItem>
        <Label>账号状态</Label>
        <Text>{getSelectedTextFromList(jobStatusList, detail?.jobStatus)}</Text>
      </ListItem>
      <ListItem>
        <Label>身份证正面</Label>
        <ImageContainer>
          <Image width={80} src={detail?.idCardFrontImgUrl} />
        </ImageContainer>
      </ListItem>
      <ListItem>
        <Label>身份证反面</Label>
        <ImageContainer>
          <Image width={80} src={detail?.idCardReverseImgUrl} />
        </ImageContainer>
      </ListItem>
      <ListItem>
        <Label>学位证照片</Label>
        <ImageContainer>
          <Image width={80} src={detail?.degreeCertificateImgUrl} />
        </ImageContainer>
      </ListItem>
      <ListItem>
        <Label>学位证编号</Label>
        <Text>{detail?.degreeCertificateNo}</Text>
      </ListItem>
      <BottomBar>
        <Button
          type="text"
          disabled={currentIndex <= 0}
          onClick={doPrev}
        ><LeftOutlined />上一个</Button>
        <Button
          type="text"
          disabled={currentIndex >= idList.length - 1}
          onClick={doNext}
        >下一个<RightOutlined /></Button>
      </BottomBar>
    </Container>
  )
}

export default Detail