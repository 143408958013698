import styled from 'styled-components';
import img_source from '@/assets/logo/source.png';
import img_enterprise from '@/assets/logo/enterprise.png';
import img_home from '@/assets/logo/home.png';
import img_manage from '@/assets/logo/manage.png';
import img_pie from '@/assets/logo/pie.png';
import img_space from '@/assets/logo/space.png';
import img_marketing from '@/assets/logo/marketing.png';
import img_book from '@/assets/logo/book@2x.png';

const Container = styled.span`
  margin-right: 10px;
`


interface Props {
  type: string;
}

const NavIcon: React.FC<Props> = ({ type }) => {
  let url = '';
  switch(type) {
    case 'source':
      url = img_source;
      break;
    case 'enterprise':
      url = img_enterprise;
      break;
    case 'home':
      url = img_home;
      break;
    case 'manage':
      url = img_manage;
      break;
    case 'pie':
      url = img_pie;
      break;
    case 'space':
      url = img_space;
      break;
    case 'marketing':
      url = img_marketing;
      break;
    case 'book':
      url = img_book;
      break;
  }
  return (
    <Container>
      <img src={url} alt="" width="16px" />
    </Container>
  )
}

export default NavIcon;