import { Row, Col, Input } from 'antd';
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";

interface Props {
  data?: any;
}

const CorpStatusForm2: React.FC<Props> = ({ data }) => (
  <>
    <Row gutter={[22, 0]}>
      <Col span={12}>
        <FormItem label="拒绝理由" name="rejectReason">
        {
          data ? (
            <Text>{data?.rejectReason}</Text>
          ) : (
            <Input />
          )
        }
        </FormItem>
      </Col>
    </Row>
  </>
)

export default CorpStatusForm2;