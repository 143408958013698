import { Modal, Row, Col, Input, message } from 'antd';
import styled from 'styled-components';
import { auditPass, auditReject } from '@/services/meeting';

const Contnet = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  color: #1F2329;
  font-size: 16px;
  font-weight: bold;
`

interface Props {
  visible: boolean;
  isApproved: boolean;
  bookingId: number;
  onClose: () => void;
  onOk?: () => void;
}

const ApprovedContent = () => (
  <Contnet>是否确认审批通过</Contnet>
)

const RejectedContent = () => (
  <Row>
    <Col span={4}>拒绝理由</Col>
    <Col span={20}>
      <Input.TextArea
        placeholder="请输入理由"
        rows={4}
      />
    </Col>
  </Row>
)

const AuditModal: React.FC<Props> = ({
  visible,
  isApproved,
  bookingId,
  onClose,
  onOk,
}) => {

  const handleOk = () => {
    if (isApproved) {
      auditPass(bookingId).then((res: any) => {
        const { resultCode, resultMsg } = res.data;
        if (resultCode === 0) {
          onOk && onOk();
          return;
        }
        message.error(resultMsg);
      })
    }
    else {
      auditReject(bookingId).then((res: any) => {
        const { resultCode, resultMsg } = res.data;
        if (resultCode === 0) {
          onOk && onOk();
          return;
        }
        message.error(resultMsg)
      });
    }
  }


  return (
    <Modal
      visible={visible}
      title={<Title>审批</Title>}
      onCancel={onClose}
      onOk={handleOk}
    >
      {
        isApproved ? (
          <ApprovedContent />
        ) : (
          <RejectedContent />
        )
      }
    </Modal>
  )
}

export default AuditModal;