import { useState, useEffect } from 'react';
import { Button, Space, Table, Drawer, Popconfirm } from 'antd';
import { SearchOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getCardList,
  getCardListCount,
  updateCard,
} from '@/services/card';
import Input from '@/components/Form/Input';
import Select from '@/components/Select';
import { CardStatus } from '@/types/common';
import { CardStatusList } from '@/common/constants';
import { getSelectedTextFromList } from '@/utils/transform';
import Editor from './Edit';

const { Column } = Table;

const renderBoolean = (_: number) => {
  if (_ > 0) {
    return <CheckOutlined />
  }

  return <CloseOutlined />
}

const CardManagent = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [cardNo, setCardNo] = useState('');
  const [cardStatus, setCardStatus] = useState<CardStatus | undefined>();
  const [loading, setLoading] = useState(false);
  const [selectedcardNo, setSelectedCardNo] = useState<string | undefined>();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const getData = (page = 1, pageSize = 30) => {
    setLoading(true);
    getCardList({
      cardNo,
      status: cardStatus,
      currentPage: page,
      pageSize
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
        setPageIndex(page);
        setPageSize(pageSize);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    getCardListCount({
      cardNo,
      status: cardStatus
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      } 
    })
  }

  const onPagination = (page: number, pageSize?: number | undefined) => {
    getData(page, pageSize);
  }

  const deleteDevice = (cardNo: string) => {
    updateCard({
      cardNo,
      status: 0,
    })
  }

  useEffect(() => {
    getData(1, pageSize);
  }, [
    cardNo, cardStatus
  ])

  return (
    <>
      <Space>
        <Input
          prefix={<SearchOutlined />}
          placeholder="请输入卡号"
          style={{ width: 200, marginRight: 12 }}
          value={cardNo}
          onChange={e => setCardNo(e.target.value)}
        />
        <Select
          label="状态"
          options={CardStatusList}
          onChange={val => setCardStatus(val)}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setSelectedCardNo('');
            setDrawerVisible(true);
          }}
        >新建卡片</Button>
      </Space>
      <Table
        rowKey={(record: any) => `tb_card_${record.cardNo}`}
        loading={loading}
        dataSource={dataSource}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination
        }}
      >
        <Column title="卡号" dataIndex="cardNo" />
        <Column title="房间权限" align="center" dataIndex="hasAllPermission" render={_ => renderBoolean(_)} />
        <Column title="会议室权限" align="center" dataIndex="hasMeetingRoomPermission" render={_ => renderBoolean(_)} />
        <Column title="未出租办公室权限" align="center" dataIndex="hasNotRentOfficePermission" render={_ => renderBoolean(_)} />
        <Column title="公共区域权限" align="center" dataIndex="hasPublicWorkspacePermission" render={_ => renderBoolean(_)} />
        <Column title="状态" align="center" dataIndex="status" render={_ => getSelectedTextFromList(CardStatusList, _)} />
        <Column
          title="操作"
          dataIndex="cardNo"
          render={_ => (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  setSelectedCardNo(_);
                  setDrawerVisible(true);
                }}
              >编辑</Button>
              <Popconfirm
                title={`确认要删除卡片${_}吗？`}
                onConfirm={() => {
                  deleteDevice(_);
                  getData(pageIndex, pageSize);
                }}
              >
                <Button type="link">删除</Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={`${selectedcardNo ? '编辑' : '添加'}卡片`}
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
          getData(pageIndex, pageSize);
        }}
        width={500}
        destroyOnClose
      >
        <Editor
          cardNo={selectedcardNo}
          onClose={() => {
            setDrawerVisible(false);
            getData(pageIndex, pageSize);
          }}
        />
      </Drawer>
    </>
  )
}

export default CardManagent;