import { uploadFile, getFileUrl } from '@/services/common';

export default function doUpload(options: any) {
  const { file, onSuccess, onError, onProgress } = options;

    return new Promise<string>((resolve, reject) => {
      uploadFile({
        file,
        onProgress
      }).then(res => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          resolve(getFileUrl(data))
          onSuccess(file);
        }
        reject(res.data)
      }).catch(err => {
        onError({ event: err });
        reject(err)
      });
    })
}