import { useState } from "react";
import { Button, Form, Input, message } from 'antd';
import { useForm } from "antd/lib/form/Form";
import { addCategory } from "@/services/knowledge";

const FormItem = Form.Item;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

interface Props {
  parentCategoryId: number | string;
  onClose: () => void;
}

const CategoryEditor: React.FC<Props> = ({
  parentCategoryId,
  onClose
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  
  const onSave = () => {
    form.validateFields().then((values: any) => {
      addCategory({
        ...values,
        parentCategoryId
      }).then((res: any) => {
        const { resultCode, resultMsg } = res.data;
        setLoading(false);
        if (resultCode === 0) {
          message.success('分类已保存');
          onClose();
          return;
        }
        message.error(resultMsg);
      }).catch(() => {
        message.error('保存失败');
        setLoading(false);
        onClose();
      })
    })
  }
  return (
    <Form {...layout} form={form}>
      <FormItem name="categoryName">
        <Input />
      </FormItem>
      <Button type="primary" loading={loading} onClick={onSave}>保存</Button>
    </Form>
  )
}

export default CategoryEditor;