import bg from '@/assets/floor_plan/5/5lou.png'
import img_501 from '@/assets/floor_plan/5/common/501.png';
import img_502 from '@/assets/floor_plan/5/common/502.png';
import img_503 from '@/assets/floor_plan/5/common/503.png';
import img_505 from '@/assets/floor_plan/5/common/505.png';
import img_506 from '@/assets/floor_plan/5/common/506.png';
import img_507 from '@/assets/floor_plan/5/common/507.png';
import img_508 from '@/assets/floor_plan/5/common/508.png';
import img_509 from '@/assets/floor_plan/5/common/509.png';
import img_510 from '@/assets/floor_plan/5/common/510.png';
import img_511 from '@/assets/floor_plan/5/common/511.png';
import img_512 from '@/assets/floor_plan/5/common/512.png';
import img_513 from '@/assets/floor_plan/5/common/513.png';
import img_515 from '@/assets/floor_plan/5/common/515.png';
import img_516 from '@/assets/floor_plan/5/common/516.png';
import img_517 from '@/assets/floor_plan/5/common/517.png';
import img_518 from '@/assets/floor_plan/5/common/518.png';
import img_519 from '@/assets/floor_plan/5/common/519.png';
import img_520 from '@/assets/floor_plan/5/common/520.png';
import img_521 from '@/assets/floor_plan/5/common/521.png';
import img_522 from '@/assets/floor_plan/5/common/522.png';
import img_523 from '@/assets/floor_plan/5/common/523.png';
import img_525 from '@/assets/floor_plan/5/common/525.png';
import img_501_selected from '@/assets/floor_plan/5/selected/501.png';
import img_502_selected from '@/assets/floor_plan/5/selected/502.png';
import img_503_selected from '@/assets/floor_plan/5/selected/503.png';
import img_505_selected from '@/assets/floor_plan/5/selected/505.png';
import img_506_selected from '@/assets/floor_plan/5/selected/506.png';
import img_507_selected from '@/assets/floor_plan/5/selected/507.png';
import img_508_selected from '@/assets/floor_plan/5/selected/508.png';
import img_509_selected from '@/assets/floor_plan/5/selected/509.png';
import img_510_selected from '@/assets/floor_plan/5/selected/510.png';
import img_511_selected from '@/assets/floor_plan/5/selected/511.png';
import img_512_selected from '@/assets/floor_plan/5/selected/512.png';
import img_513_selected from '@/assets/floor_plan/5/selected/513.png';
import img_515_selected from '@/assets/floor_plan/5/selected/515.png';
import img_516_selected from '@/assets/floor_plan/5/selected/516.png';
import img_517_selected from '@/assets/floor_plan/5/selected/517.png';
import img_518_selected from '@/assets/floor_plan/5/selected/518.png';
import img_519_selected from '@/assets/floor_plan/5/selected/519.png';
import img_520_selected from '@/assets/floor_plan/5/selected/520.png';
import img_521_selected from '@/assets/floor_plan/5/selected/521.png';
import img_522_selected from '@/assets/floor_plan/5/selected/522.png';
import img_523_selected from '@/assets/floor_plan/5/selected/523.png';
import img_525_selected from '@/assets/floor_plan/5/selected/525.png';
import img_501_expire from '@/assets/floor_plan/5/expire/501.png';
import img_502_expire from '@/assets/floor_plan/5/expire/502.png';
import img_503_expire from '@/assets/floor_plan/5/expire/503.png';
import img_505_expire from '@/assets/floor_plan/5/expire/505.png';
import img_506_expire from '@/assets/floor_plan/5/expire/506.png';
import img_507_expire from '@/assets/floor_plan/5/expire/507.png';
import img_508_expire from '@/assets/floor_plan/5/expire/508.png';
import img_509_expire from '@/assets/floor_plan/5/expire/509.png';
import img_510_expire from '@/assets/floor_plan/5/expire/510.png';
import img_511_expire from '@/assets/floor_plan/5/expire/511.png';
import img_512_expire from '@/assets/floor_plan/5/expire/512.png';
import img_513_expire from '@/assets/floor_plan/5/expire/513.png';
import img_515_expire from '@/assets/floor_plan/5/expire/515.png';
import img_516_expire from '@/assets/floor_plan/5/expire/516.png';
import img_517_expire from '@/assets/floor_plan/5/expire/517.png';
import img_518_expire from '@/assets/floor_plan/5/expire/518.png';
import img_519_expire from '@/assets/floor_plan/5/expire/519.png';
import img_520_expire from '@/assets/floor_plan/5/expire/520.png';
import img_521_expire from '@/assets/floor_plan/5/expire/521.png';
import img_522_expire from '@/assets/floor_plan/5/expire/522.png';
import img_523_expire from '@/assets/floor_plan/5/expire/523.png';
import img_525_expire from '@/assets/floor_plan/5/expire/525.png';
import img_501_mismatch from '@/assets/floor_plan/5/mismatch/501.png';
import img_502_mismatch from '@/assets/floor_plan/5/mismatch/502.png';
import img_503_mismatch from '@/assets/floor_plan/5/mismatch/503.png';
import img_505_mismatch from '@/assets/floor_plan/5/mismatch/505.png';
import img_506_mismatch from '@/assets/floor_plan/5/mismatch/506.png';
import img_507_mismatch from '@/assets/floor_plan/5/mismatch/507.png';
import img_508_mismatch from '@/assets/floor_plan/5/mismatch/508.png';
import img_509_mismatch from '@/assets/floor_plan/5/mismatch/509.png';
import img_510_mismatch from '@/assets/floor_plan/5/mismatch/510.png';
import img_511_mismatch from '@/assets/floor_plan/5/mismatch/511.png';
import img_512_mismatch from '@/assets/floor_plan/5/mismatch/512.png';
import img_513_mismatch from '@/assets/floor_plan/5/mismatch/513.png';
import img_515_mismatch from '@/assets/floor_plan/5/mismatch/515.png';
import img_516_mismatch from '@/assets/floor_plan/5/mismatch/516.png';
import img_517_mismatch from '@/assets/floor_plan/5/mismatch/517.png';
import img_518_mismatch from '@/assets/floor_plan/5/mismatch/518.png';
import img_519_mismatch from '@/assets/floor_plan/5/mismatch/519.png';
import img_520_mismatch from '@/assets/floor_plan/5/mismatch/520.png';
import img_521_mismatch from '@/assets/floor_plan/5/mismatch/521.png';
import img_522_mismatch from '@/assets/floor_plan/5/mismatch/522.png';
import img_523_mismatch from '@/assets/floor_plan/5/mismatch/523.png';
import img_525_mismatch from '@/assets/floor_plan/5/mismatch/525.png';

const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 297) / 980}%`;
const top = (t: number) => `${100 * (t - 310) / 328}%`;

const initialRoomListData = [
  {
    title: '501',
    roomNo: '501',
    width: width(110),
    left: left(300),
    top: top(313),
    image: img_501,
    selectedImage: img_501_selected,
    expireImage: img_501_expire,
    mismatchImage: img_501_mismatch
  },
  {
    title: '502',
    roomNo: '502',
    width: width(118),
    left: left(300),
    top: top(422),
    image: img_502,
    selectedImage: img_502_selected,
    expireImage: img_502_expire,
    mismatchImage: img_502_mismatch
  },
  {
    title: '503',
    roomNo: '503',
    width: width(88),
    left: left(412),
    top: top(313),
    image: img_503,
    selectedImage: img_503_selected,
    expireImage: img_503_expire,
    mismatchImage: img_503_mismatch
  },
  {
    title: '505',
    roomNo: '505',
    width: width(98),
    left: left(502),
    top: top(313),
    image: img_505,
    selectedImage: img_505_selected,
    expireImage: img_505_expire,
    mismatchImage: img_505_mismatch
  },
  {
    title: '506',
    roomNo: '506',
    width: width(94),
    left: left(602),
    top: top(313),
    image: img_506,
    selectedImage: img_506_selected,
    expireImage: img_506_expire,
    mismatchImage: img_506_mismatch
  },
  {
    title: '507',
    roomNo: '507',
    width: width(52),
    left: left(738),
    top: top(313),
    image: img_507,
    selectedImage: img_507_selected,
    expireImage: img_507_expire,
    mismatchImage: img_507_mismatch
  },
  {
    title: '508',
    roomNo: '508',
    width: width(94),
    left: left(792),
    top: top(313),
    image: img_508,
    selectedImage: img_508_selected,
    expireImage: img_508_expire,
    mismatchImage: img_508_mismatch
  },
  {
    title: '512',
    roomNo: '512',
    width: width(94),
    left: left(888),
    top: top(313),
    image: img_512,
    selectedImage: img_512_selected,
    expireImage: img_512_expire,
    mismatchImage: img_512_mismatch
  },
  {
    title: '509',
    roomNo: '509',
    width: width(48),
    left: left(1094),
    top: top(327),
    image: img_509,
    selectedImage: img_509_selected,
    expireImage: img_509_expire,
    mismatchImage: img_509_mismatch
  },
  {
    title: '510',
    roomNo: '510',
    width: width(48),
    left: left(1144),
    top: top(327),
    image: img_510,
    selectedImage: img_510_selected,
    expireImage: img_510_expire,
    mismatchImage: img_510_mismatch
  },
  {
    title: '511',
    roomNo: '511',
    width: width(80),
    left: left(1194),
    top: top(327),
    image: img_511,
    selectedImage: img_511_selected,
    expireImage: img_511_expire,
    mismatchImage: img_511_mismatch
  },
  {
    title: '517',
    roomNo: '517',
    width: width(70),
    left: left(1204),
    top: top(429),
    image: img_517,
    selectedImage: img_517_selected,
    expireImage: img_517_expire,
    mismatchImage: img_517_mismatch
  },
  {
    title: '513',
    roomNo: '513',
    width: width(100),
    left: left(1174),
    top: top(555),
    image: img_513,
    selectedImage: img_513_selected,
    expireImage: img_513_expire,
    mismatchImage: img_513_mismatch
  },
  {
    title: '516',
    roomNo: '516',
    width: width(100),
    left: left(1072),
    top: top(555),
    image: img_516,
    selectedImage: img_516_selected,
    expireImage: img_516_expire,
    mismatchImage: img_516_mismatch
  },
  {
    title: '515',
    roomNo: '515',
    width: width(82),
    left: left(904),
    top: top(555),
    image: img_515,
    selectedImage: img_515_selected,
    expireImage: img_515_expire,
    mismatchImage: img_515_mismatch
  },
  {
    title: '518',
    roomNo: '518',
    width: width(108),
    left: left(794),
    top: top(555),
    image: img_518,
    selectedImage: img_518_selected,
    expireImage: img_518_expire,
    mismatchImage: img_518_mismatch
  },
  {
    title: '519',
    roomNo: '519',
    width: width(94),
    left: left(698),
    top: top(555),
    image: img_519,
    selectedImage: img_519_selected,
    expireImage: img_519_expire,
    mismatchImage: img_519_mismatch
  },
  {
    title: '520',
    roomNo: '520',
    width: width(94),
    left: left(602),
    top: top(555),
    image: img_520,
    selectedImage: img_520_selected,
    expireImage: img_520_expire,
    mismatchImage: img_520_mismatch
  },
  {
    title: '521',
    roomNo: '521',
    width: width(98),
    left: left(502),
    top: top(555),
    image: img_521,
    selectedImage: img_521_selected,
    expireImage: img_521_expire,
    mismatchImage: img_521_mismatch
  },
  {
    title: '522',
    roomNo: '522',
    width: width(118),
    left: left(300),
    top: top(476),
    image: img_522,
    selectedImage: img_522_selected,
    expireImage: img_522_expire,
    mismatchImage: img_522_mismatch
  },
  {
    title: '525',
    roomNo: '525',
    width: width(88),
    left: left(412),
    top: top(555),
    image: img_525,
    selectedImage: img_525_selected,
    expireImage: img_525_expire,
    mismatchImage: img_525_mismatch
  },
  {
    title: '523',
    roomNo: '523',
    width: width(110),
    left: left(300),
    top: top(555),
    image: img_523,
    selectedImage: img_523_selected,
    expireImage: img_523_expire,
    mismatchImage: img_523_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}