import axios from 'axios';
import { message } from 'antd';

const request = axios.create();

request.interceptors.response.use(
  res => {
    const { resultCode, resultMsg } = res.data;

    if (resultCode === -80010006) {
      window.location.href = '/web/qrlogin';
      throw new Error("未登录");
    }
    // if (resultCode !== 0) {
    //   throw new Error(resultMsg || '服务请求失败');
    // }
    return res;
  },
  error => {
    message.error(error.message)
    throw error;
  }
)

export default request;