import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

export default function configureStore() {
  const middlewares = [thunk];

  // if (process.env.NODE_ENV === 'development') {
  //   const { createLogger } = require('redux-logger');
  //   middlewares.push(createLogger());
  // }

  return createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
}