import { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Elevator from '@/components/Elevator';
import Floor from './Floor';
import RoomInfo from './RoomInfo';
import { getFloorRoomList, getWorkspaceList } from '@/services/workspace';



import img_legend from '@/assets/floor_plan/biaoshi.png';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 24px;
`

const FloorPlanContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FloorPlanInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 60px 0;
`

const Legend = styled.img`
  width: 529px;
  margin-top: 50px;
  display: block;
`

const ElevatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;  
  width: 80px;
`


const EnergyManagement = () => {
  const floorNo = useSelector((state: any) => state.workspace.common.floorNo);
  const [workspaceList, setWorkspaceList] = useState<any[]>([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(0);
  const [selectedRoomNo, setSelectedRoomNo] = useState('');
  const [floorRoomList, setFloorRoomList] = useState<any[]>([]);
  const [roomInfoVisible, setRoomInfoVisible] = useState(false);
  const [deviceList, setDeviceList] = useState<any[]>([]);

  const getWorkspaceListByFloorNo = (floorNo: string) => {
    getWorkspaceList({
      floorNo,
      currentPage: 1,
      pageSize: 100
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setWorkspaceList(data)
      }
    })
  }

  const closeRoomInfo = () => {
    setRoomInfoVisible(false);
    setSelectedRoomNo('');
  }

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorRoomList(list.map(item => ({
        value: item,
        text: item
      })))
    })

    getWorkspaceListByFloorNo(floorNo);
  }, [])

  return (
    <>
      <Container>
        <FloorPlanContainer>
          <FloorPlanInner>
            <Floor
              floorNo={floorNo}
              workspaceList={workspaceList}
              selectedRoomNo={selectedRoomNo}
              onClick={(roomNo: string) => {
                setSelectedRoomNo(roomNo);
                setRoomInfoVisible(true);
                const roomInfo = workspaceList.find((item: any) => item.roomNo === roomNo);
                if (roomInfo) {
                  setDeviceList(roomInfo.deviceRecordList);
                }
              }}
            />
            <Legend src={img_legend} />
          </FloorPlanInner>
        </FloorPlanContainer>
        <ElevatorContainer>
          <Elevator floors={floorRoomList} onChange={getWorkspaceListByFloorNo}  />
        </ElevatorContainer>
      </Container>
      <Drawer
        visible={roomInfoVisible}
        onClose={closeRoomInfo}
        width={970}
        closable={false}
      >
        <RoomInfo 
          roomNo={selectedRoomNo}
          deviceList={deviceList}
          onClose={closeRoomInfo}
        />
      </Drawer>
    </>
  )
}

export default EnergyManagement;