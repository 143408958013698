import bg from '@/assets/floor_plan/8/8lou.png';
import img_801 from '@/assets/floor_plan/8/common/801.png';
import img_802 from '@/assets/floor_plan/8/common/802.png';
import img_803 from '@/assets/floor_plan/8/common/803.png';
import img_805 from '@/assets/floor_plan/8/common/805.png';
import img_806 from '@/assets/floor_plan/8/common/806.png';
import img_807 from '@/assets/floor_plan/8/common/807.png';
import img_808 from '@/assets/floor_plan/8/common/808.png';
import img_809 from '@/assets/floor_plan/8/common/809.png';
import img_810 from '@/assets/floor_plan/8/common/810.png';
import img_811 from '@/assets/floor_plan/8/common/811.png';
import img_812 from '@/assets/floor_plan/8/common/812.png';
import img_813 from '@/assets/floor_plan/8/common/813.png';
import img_815 from '@/assets/floor_plan/8/common/815.png';
import img_801_selected from '@/assets/floor_plan/8/selected/801.png';
import img_802_selected from '@/assets/floor_plan/8/selected/802.png';
import img_803_selected from '@/assets/floor_plan/8/selected/803.png';
import img_805_selected from '@/assets/floor_plan/8/selected/805.png';
import img_806_selected from '@/assets/floor_plan/8/selected/806.png';
import img_807_selected from '@/assets/floor_plan/8/selected/807.png';
import img_808_selected from '@/assets/floor_plan/8/selected/808.png';
import img_809_selected from '@/assets/floor_plan/8/selected/809.png';
import img_810_selected from '@/assets/floor_plan/8/selected/810.png';
import img_811_selected from '@/assets/floor_plan/8/selected/811.png';
import img_812_selected from '@/assets/floor_plan/8/selected/812.png';
import img_813_selected from '@/assets/floor_plan/8/selected/813.png';
import img_815_selected from '@/assets/floor_plan/8/selected/815.png';
import img_801_expire from '@/assets/floor_plan/8/expire/801.png';
import img_802_expire from '@/assets/floor_plan/8/expire/802.png';
import img_803_expire from '@/assets/floor_plan/8/expire/803.png';
import img_805_expire from '@/assets/floor_plan/8/expire/805.png';
import img_806_expire from '@/assets/floor_plan/8/expire/806.png';
import img_807_expire from '@/assets/floor_plan/8/expire/807.png';
import img_808_expire from '@/assets/floor_plan/8/expire/808.png';
import img_809_expire from '@/assets/floor_plan/8/expire/809.png';
import img_810_expire from '@/assets/floor_plan/8/expire/810.png';
import img_811_expire from '@/assets/floor_plan/8/expire/811.png';
import img_812_expire from '@/assets/floor_plan/8/expire/812.png';
import img_813_expire from '@/assets/floor_plan/8/expire/813.png';
import img_815_expire from '@/assets/floor_plan/8/expire/815.png';
import img_801_mismatch from '@/assets/floor_plan/8/mismatch/801.png';
import img_802_mismatch from '@/assets/floor_plan/8/mismatch/802.png';
import img_803_mismatch from '@/assets/floor_plan/8/mismatch/803.png';
import img_805_mismatch from '@/assets/floor_plan/8/mismatch/805.png';
import img_806_mismatch from '@/assets/floor_plan/8/mismatch/806.png';
import img_807_mismatch from '@/assets/floor_plan/8/mismatch/807.png';
import img_808_mismatch from '@/assets/floor_plan/8/mismatch/808.png';
import img_809_mismatch from '@/assets/floor_plan/8/mismatch/809.png';
import img_810_mismatch from '@/assets/floor_plan/8/mismatch/810.png';
import img_811_mismatch from '@/assets/floor_plan/8/mismatch/811.png';
import img_812_mismatch from '@/assets/floor_plan/8/mismatch/812.png';
import img_813_mismatch from '@/assets/floor_plan/8/mismatch/813.png';
import img_815_mismatch from '@/assets/floor_plan/8/mismatch/815.png';


const width = (w: number) => `${100 * (w / 980)}%`;
const left = (l: number) => `${100 * (l - 300) / 980}%`;
const top = (t: number) => `${100 * (t - 288) / 398}%`;

const initialRoomListData = [
  {
    title: '801',
    roomNo: '801',
    width: width(136),
    left: left(303),
    top: top(291),
    image: img_801,
    selectedImage: img_801_selected,
    expireImage: img_801_expire,
    mismatchImage: img_801_mismatch
  },
  {
    title: '802',
    roomNo: '802',
    width: width(114),
    left: left(441),
    top: top(291),
    image: img_802,
    selectedImage: img_802_selected,
    expireImage: img_802_expire,
    mismatchImage: img_802_mismatch
  },
  {
    title: '803',
    roomNo: '803',
    width: width(182),
    left: left(607),
    top: top(291),
    image: img_803,
    selectedImage: img_803_selected,
    expireImage: img_803_expire,
    mismatchImage: img_803_mismatch
  },
  {
    title: '805',
    roomNo: '805',
    width: width(172),
    left: left(791),
    top: top(291),
    image: img_805,
    selectedImage: img_805_selected,
    expireImage: img_805_expire,
    mismatchImage: img_805_mismatch
  },
  {
    title: '806',
    roomNo: '806',
    width: width(172),
    left: left(965),
    top: top(291),
    image: img_806,
    selectedImage: img_806_selected,
    expireImage: img_806_expire,
    mismatchImage: img_806_mismatch
  },
  {
    title: '807',
    roomNo: '807',
    width: width(138),
    left: left(1139),
    top: top(291),
    image: img_807,
    selectedImage: img_807_selected,
    expireImage: img_807_expire,
    mismatchImage: img_807_mismatch
  },
  {
    title: '808',
    roomNo: '808',
    width: width(98),
    left: left(1179),
    top: top(429),
    image: img_808,
    selectedImage: img_808_selected,
    expireImage: img_808_expire,
    mismatchImage: img_808_mismatch
  },
  {
    title: '809',
    roomNo: '809',
    width: width(138),
    left: left(1139),
    top: top(585),
    image: img_809,
    selectedImage: img_809_selected,
    expireImage: img_809_expire,
    mismatchImage: img_809_mismatch
  },
  {
    title: '810',
    roomNo: '810',
    width: width(114),
    left: left(1023),
    top: top(585),
    image: img_810,
    selectedImage: img_810_selected,
    expireImage: img_810_expire,
    mismatchImage: img_810_mismatch
  },
  {
    title: '811',
    roomNo: '811',
    width: width(114),
    left: left(791),
    top: top(585),
    image: img_811,
    selectedImage: img_811_selected,
    expireImage: img_811_expire,
    mismatchImage: img_811_mismatch
  },
  {
    title: '812',
    roomNo: '812',
    width: width(172),
    left: left(617),
    top: top(585),
    image: img_812,
    selectedImage: img_812_selected,
    expireImage: img_812_expire,
    mismatchImage: img_812_mismatch
  },
  {
    title: '813',
    roomNo: '813',
    width: width(172),
    left: left(443),
    top: top(585),
    image: img_813,
    selectedImage: img_813_selected,
    expireImage: img_813_expire,
    mismatchImage: img_813_mismatch
  },
  {
    title: '815',
    roomNo: '815',
    width: width(138),
    left: left(303),
    top: top(551),
    image: img_815,
    selectedImage: img_815_selected,
    expireImage: img_815_expire,
    mismatchImage: img_815_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}