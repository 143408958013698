import { ValueAndText } from '@/types/common';
import { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setFloorNo } from '@/store/actions/workspace';

const Container = styled.div`
  width: 26px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 14px;
`

const Floor = styled.div`
  color: #1F2329;
  background-color: transparent;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    color: #FFFFFF;
    background-color: #3371FF;
  }
`

type Props = {
  floors: Array<ValueAndText>;
  onChange?: (floorNo: string) => void;
}

const Elevator: React.FC<Props> = ({
  floors,
  onChange,
}) => {
  const floorNo = useSelector((state: any) => state.workspace.common.floorNo) || '04F';
  const dispatch = useDispatch();
  const handleClick = (val: string) => {
    dispatch(setFloorNo(val))
    onChange && onChange(val);
  }

  return (
    <Container>
      {
        floors.map((item, index) => (
          <Floor
            key={`elevator_floor_${index}`}
            className={`${floorNo === item.value ? 'active' : ''}`}
            onClick={() => {
              handleClick(item.value as string)
            }}
          >{item.text}</Floor>
        ))
      }
    </Container>
  )
}

export default Elevator;