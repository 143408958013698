import request from '@/utils/request';
import qs from 'qs';
import jsonToFormData from 'json-form-data';
import { FanMode, HVACMode } from '@/types/device';

export function getDeviceList(roomNo: string) {
  return request.get(`/api/logined/deviceRecord/list?belongRoom=${roomNo}`)
}

export function turnOnAirConditioning(deviceNo: string) {
  return request.get(`/api/logined/operateAc/turn_on?deviceNo=${deviceNo}`)
}

export function turnOffAirConditioning(deviceNo: string) {
  return request.get(`/api/logined/operateAc/turn_off?deviceNo=${deviceNo}`)
}

export function turnOnLight(deviceNo: string) {
  return request.get(`/api/logined/operateLight/turn_on?deviceNo=${deviceNo}`)
}

export function turnOffLight(deviceNo: string) {
  return request.get(`/api/logined/operateLight/turn_off?deviceNo=${deviceNo}`)
}

export function getDeviceDetail(deviceId: number) {
  return request.get(`/api/logined/deviceRecord/detail?deviceId=${deviceId}`)
}

export function setFanMode(deviceNo: string, fanMode: FanMode) {
  return request.get(`/api/logined/operateAc/set_fan_mode?deviceNo=${deviceNo}&fan_mode=${fanMode}`)
}

export function setHVACMode(deviceNo: string, hVacMode: HVACMode) {
  return request.get(`/api/logined/operateAc/set_hvac_mode?deviceNo=${deviceNo}&hvac_mode=${hVacMode}`)
}

export function setTemperature(deviceNo: string, temperature: string | number) {
  return request.get(`/api/logined/operateAc/set_temperature?deviceNo=${deviceNo}&temperature=${temperature}`)
}