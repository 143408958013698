import { combineReducers } from 'redux';
import user from './user';
import company from './company';
import common from './common';
import employee from './employee';
import workspace from './workspace';
import knowledge from './knowledge';

export default combineReducers({
  user,
  company,
  common,
  employee,
  workspace,
  knowledge,
})