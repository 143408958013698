import React, { ReactChildren } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

const Container = styled.div`
  display: block;
`

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #646A73;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
`

type Props = {
  label: string;
  name?: string;
  required?: boolean;
  children?: ReactChildren | any;
}

const FormItem: React.FC<Props> = ({
  label,
  name,
  required,
  children
}) => {
  return (
    <Container>
      <Label>
        {label}
        {
          required && (
            <div style={{ color: '#F64A46', paddingLeft: 4, fontSize: 14 }}>*</div>
          )
        }
      </Label>
      <div>
        <Form.Item name={name}>{ children }</Form.Item>
      </div>
    </Container>
  )
}

export default FormItem;