import { Button, Space, Spin } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getWorkspaceDetail } from '@/services/workspace';
import { useSelector } from 'react-redux';
import { getSelectedTextFromList } from '@/utils/transform';

const Container = styled.div`
  padding: 0px;
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const RoomNumber = styled.div`
  font-size: 20px;
  color: #1F2329;
  text-align: left;
  padding-top: 27px;
`

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1px;
  background-color: #DEE0E3;
  padding: 1px;
  margin-bottom: 33px;
  margin-top: 24px;
`

const ListItem = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 25px 15px 16px 15px;
 background-color: #FFF;

 > .title {
   color: #1F2329;
   font-size: 18px;
   
 }

 > .subTitle {
   color: #646A73;
   font-size: 14px;
   padding-top: 13px;
 }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Label = styled.div`
  color: #646A73;
  font-size: 14px;
  width: 110px;
  text-align: left;
`

const RightCell = styled.div`
  flex: 1;
  color: #1F2329;
  font-size: 14px;
  font-weight: 400;
`

interface Props {
  roomType: string;
  workspaceId: number;
  onClose: () => void;
}

const RoomInfo: React.FC<Props> = ({
  roomType,
  workspaceId,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const rentStatusList = useSelector((state: any) => state.common.rentStatusList)

  useEffect(() => {
    setLoading(true);
    getWorkspaceDetail(workspaceId).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setData(data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }, [workspaceId])

  return (
    <Spin spinning={loading}>
      <Container>
        <ButtonBar>
          <CloseOutlined onClick={onClose} />
          <Space>
            <Button><Link to={`/workspace/detail/${data.workspaceId}`}>查看详情</Link></Button>
            <Button><Link to={`/workspace/edit/${data.workspaceId}`}>编辑</Link></Button>
          </Space>
        </ButtonBar>
        <RoomNumber>{data.roomNo}</RoomNumber>
        <List>
          <ListItem>
            <div className="title">{roomType}</div>
            <div className="subTitle">功能类型</div>
          </ListItem>
          <ListItem>
            <div className="title">{data.workspaceArea}㎡</div>
            <div className="subTitle">面积</div>
          </ListItem>
          <ListItem>
            <div className="title">{data.rentCost}</div>
            <div className="subTitle">租金</div>
          </ListItem>
          <ListItem>
            <div className="title">{data.realGalleryful}人</div>
            <div className="subTitle">容纳人数</div>
          </ListItem>
        </List>
        <Row>
          <Label>使用情况</Label>
          <RightCell>{getSelectedTextFromList(rentStatusList, data.rentStatus)}</RightCell>
        </Row>
        <Row>
          <Label>联系人</Label>
          <RightCell>{data.salesman}</RightCell>
        </Row>
        <Row>
          <Label>承租方</Label>
          <RightCell>{data.rentCorpName}</RightCell>
        </Row>
        <Row>
          <Label>所属楼层</Label>
          <RightCell>{data.floorNo}</RightCell>
        </Row>
        {/* <Row>
          <Label>包含设备</Label>
          <RightCell></RightCell>
        </Row> */}
      </Container>
    </Spin>
  )
}

export default RoomInfo;