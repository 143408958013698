import { useState, useEffect } from "react";
import { Row, Col, Button, Table, Space, Image } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import styled from "styled-components";
import Title from "@/components/Form/Title";
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";
import { getCompanyDetail, getContactList, getWorkspaceRentContractImgList } from "@/services/company";
import { getWorkspaceList } from '@/services/workspace';
import { ValueAndText } from "@/types/common";
import { getSelectedTextFromList } from '@/utils/transform';
import CorpStatusAndRelatedFields from './CorpStatusAndRelatedFields';
import DownloadableQRCode from '@/components/DownloadableQRCode';
import { setSelectedCorpId } from '@/store/actions/company';
import { setCorpInfo } from "@/store/actions/employee";

const { Column } = Table;

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  width: ${(props: { width?: number }) => props.width || 80}px;
  cursor: pointer;
`

interface ParamTypes {
  id: string;
}

const Detail = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [companyDetail, setCompnayDetail] = useState<any>({});
  const [roomList, setRoomList] = useState<any []>([]);
  const [contactList, setContactList] = useState<any[]>([]);
  const [contractImgList, setContractImgList] = useState<any[]>([]);
  const staffSizeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.staffSizeTypeList);
  const industryList: Array<ValueAndText> = useSelector((state: any) => state.common.industryList);


  useEffect(() => {
    dispatch(setSelectedCorpId(id));

    getCompanyDetail(id).then((res) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setCompnayDetail(data);
        dispatch(setCorpInfo(data.corpId, data.corpName))
        getWorkspaceRentContractImgList(data.lastRentContractRecord?.contractId).then((res: any) => {
          const { resultCode, data } = res.data;
          if (resultCode === 0) {
            setContractImgList(data.map((item: any, index: number) => {
              return {
                ...item,
                index: index + 1,
                isRemoved: false
              }
            }))
          }
        })
      }
    });

    getWorkspaceList({
      rentCorpId: id
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setRoomList(data.map((item: any) => {
          return {
            ...item,
            isRemoved: false
          }
        }))
      }
    })

    getContactList(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setContactList(data.map((item: any, index: number) => {
          return {
            ...item,
            index: index + 1,
            isRemoved: false
          }
        }))
      }
    })
  }, [id, dispatch]);
  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { history.goBack() }}><CloseOutlined /></Button>
      </ButtonBar>
      <Container>
        <Panel>
          <TitleContainer>
            <Title>基本信息</Title>
            <Link to={`/company/edit/${id}`}><Button>编辑</Button></Link>
          </TitleContainer>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="企业名称">
                  <Text>{companyDetail?.corpName}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="企业编号">
                  <Text>{companyDetail?.corpNo}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="营业执照编号">
                  <Text>{companyDetail?.businessLicenseNo}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="注册资本">
                  <Text>{companyDetail?.capitalSocial}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="成立日期">
                  <Text>{companyDetail?.establishedDate}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="行业">
                  <Text>{getSelectedTextFromList(industryList, companyDetail?.belongIndustry)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="规模">
                  <Text>{getSelectedTextFromList(staffSizeTypeList, companyDetail?.staffSizeType)}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="主营业务">
                  <Text>{companyDetail?.businessType}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="营业执照照片">
                  <ImageContainer>
                    <Image src={companyDetail?.businessLicenseUrl} />
                  </ImageContainer>
                </FormItem>
              </Col>
              {
                companyDetail?.corpId ? (
                  <Col span={12}>
                    <FormItem label="企业二维码">
                      <DownloadableQRCode value={`https://zlh.yoymoney.com/tools/corpId=${companyDetail?.corpId}`} direction="horizontal" size={120} />
                    </FormItem>
                  </Col>
                ) : null
              }
              <Col span={12}>
                <FormItem label="社保账号">
                  <Text>{companyDetail?.socialSecurityAccount}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="社保在缴人数">
                  <Text>{companyDetail?.socialSecurityPeopleNumber}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="法人姓名">
                  <Text>{companyDetail?.legalPersonName}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="法人证件号">
                  <Text>{companyDetail?.legalPersonIdNumber}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="法人证件照正反面">
                  <Space>
                    <ImageContainer>
                      <Image src={companyDetail?.legalPersonIdPositiveImg} />
                    </ImageContainer>
                    <ImageContainer>
                      <Image src={companyDetail?.legalPersonIdNegativeImg} />
                    </ImageContainer>
                  </Space>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
        <Panel>
          <Title>知识产权</Title>
          <Table
            dataSource={companyDetail?.patentRecordList}
            pagination={false}
            rowKey={(record: any) => `corp_c_patent_${record.index}`}
          >
            <Column title="类别" />
            <Column title="名称" />
            <Column title="详情" dataIndex="patentDescription" />
            <Column
              title="专利图片"
              dataIndex="patentImageUrl"
              render={(_: string) => <ImageContainer width={80}><Image src={_} /></ImageContainer>}
            />
          </Table>
        </Panel>
        <Panel>
          <Title>联系人</Title>
          <Table
            dataSource={contactList.filter((item: any) => item.isRemoved === false)}
            pagination={false}
            rowKey={(record: any) => `corp_c_contact_${record.index}`}
          >
            <Column title="姓名" dataIndex="contactName" />
            <Column title="手机号" dataIndex="contactMobile" />
            <Column title="邮箱" dataIndex="contactEmail" />
          </Table>
        </Panel>
        <Panel>
          <Title>企业状态</Title>
          <CorpStatusAndRelatedFields
            contractStatus={companyDetail?.lastRentContractRecord?.status || 0}
            data={companyDetail?.lastRentContractRecord || {}}
            contractImgList={contractImgList}
            mode="readonly"
            cachedRoomList={roomList}
          />
        </Panel>
      </Container>
    </>
  );
};

export default Detail;
