import { useEffect, useState } from 'react';
import { Input, Table, Popover, Space, Button, Popconfirm, message } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import img_search from '@/assets/search@2x.png';
import Title from '@/components/Form/Title';
import Chip from '@/components/Chip';
import DailyDetail from './DailyDetail';
import { getMeetingRoomList, cancelBookingRecord } from '@/services/meeting';
import { getSelectedTextFromList } from '@/utils/transform';
import { useSelector } from 'react-redux';
import AuditModal from '../AuditModal';

const { Column } = Table;

const Container = styled.div`
  overflow: auto;
  margin-bottom: 30px;
  -moz-user-select:none; 
  -webkit-user-select:none; 
  -ms-user-select:none;
  -khtml-user-select:none;
  user-select:none;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  height: 36px;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const SearchBox = styled.div`
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #C2C5CA;
  > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > input {
    width: 211px;
  }
`

const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #1F2329;
  font-size: 12px;
  > .day {
    width: 32px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-bottom: 1px solid #C2C5CA;
    &:first-child {
      border-left: 1px solid #E8E8E8;
    }
  }
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > .roomName {
    width: 224px;
    padding-left: 8px;
    border-bottom: 1px solid #E2EBFF;
    > div {
      width: 224px;
    }
  }

  .day {
    width: 32px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-left: 1px solid #E2EBFF;
    border-bottom: 1px solid #E2EBFF;
    &.used {
      background-color: #E2EBFF;
      color: #123FA7;
      cursor: pointer;
    }
  } 
`

const fillCalendarData = (data: any[], queryDate: string): any[] => {
  const calendarData: any[] = [];
  const queryMonth = moment(queryDate).format('YYYY-MM');

  data.forEach((item: any) => {
    let roomData = calendarData.find(cd => cd.roomNo === item.roomNo);
    if (!roomData) {
      roomData = {
        roomNo: item.roomNo,
        roomAlias: item.roomAlias,
        days: Array.from(Array(moment(queryDate).daysInMonth()).keys(), x => ({
          useDate: moment(`${queryMonth}-${x+1}`).format('YYYY-MM-DD'),
          count: 0
        }))
      };
      calendarData.push(roomData);
    }

    item.bookingList.forEach((bookingRecord: any) => {
      roomData.days[moment(bookingRecord.useDate).date()-1].count += 1
    })
  })

  return calendarData;
}

const fillBookingData = (data: any[]): any[] => {
  const bookingData: Array<any> = [];

  data.forEach((item: any) => {
    item.bookingList.forEach((bookingRecord: any) => {
      bookingData.push({
        ...bookingRecord,
        realGalleryful: item.realGalleryful,
        workspaceType: item.workspaceType
      })
    })
  })

  return bookingData;
}

interface Props {
  queryDate: string;
}

const Calendar: React.FC<Props> = ({
  queryDate
}) => {
  const daysInMonth = Array.from(Array(moment(queryDate).daysInMonth()).keys(), x => x + 1);
  const queryMonth = moment(queryDate).format('YYYY-MM');
  const [tableData, setTableData] = useState<any[]>([]);
  const [calendarData, setCalendarData] = useState<any[]>([]);
  const workspaceTypeList = useSelector((state: any) => state.common.workspaceTypeList);
  const [auditModalVisible, setAuditModalVisible] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(0);
  const [roomNoForFilter, setRoomNoForFilter] = useState('');
  const [originData, setOriginData] = useState<any[]>([]);

  useEffect(() => {
    getMeetingRoomList({
      beginUseDate: `${queryMonth}-1`,
      endUseDate: `${queryMonth}-${moment(queryDate).daysInMonth()}`,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setOriginData(data);
        setTableData(fillBookingData(data));
        setCalendarData(fillCalendarData(data, queryDate));
      }
    })
  }, [queryDate]);

  useEffect(() => {
    const filterdData = originData.filter((item: any) => item.roomNo.includes(roomNoForFilter));
    setTableData(fillBookingData(filterdData));
    setCalendarData(fillCalendarData(filterdData, queryDate));
  }, [
    roomNoForFilter
  ])
  
  return (
    <>
      <Container>
        <Header>
          <SearchBox>
            <img src={img_search} alt="" />
            <Input placeholder="搜索会议室" bordered={false} width={200} onChange={e => setRoomNoForFilter(e.target.value)} />
          </SearchBox>
          <DaysContainer>
            {
              daysInMonth.map(day => (
                <div className="day" key={`month_${day}`}>{day}</div>
              ))
            }
          </DaysContainer>
        </Header>
        {
          calendarData.map((item: any, index: number) => (
            <TableRow key={`table_row_day_${index}`}>
              <div className="roomName">
                <div>{`${item.roomNo} - ${item.roomAlias}`}</div>
              </div>
              <DaysContainer>
              {
                item.days.map((day: any) => {
                  if (day.count > 0) {
                    return (
                      <Popover key={`month_data_${index}_${day.useDate}`} placement="right" trigger="click" content={<DailyDetail roomNo={item.roomNo} queryDate={day.useDate} />}>
                        <div className="day used">{day.count}</div>
                      </Popover>
                    )
                  }

                  return <div className="day"></div>
                })
              }
              </DaysContainer>
            </TableRow>
          ))
        }
      </Container>
      <Title>会议记录</Title>
      <Table
        dataSource={tableData}
        pagination={false}
        rowKey={(record: any) => `calendar_month_data_${record.bookingId}`}
        scroll={{ x: 1500 }}
      >
        <Column title="会议名称" dataIndex="meetingSubject" />
        <Column title="使用方" dataIndex="corpName" />
        <Column title="用户名称" dataIndex="bookingUserRealName" />
        <Column title="预约发起时间" dataIndex="createTime" />
        <Column title="会议室" dataIndex="roomNo" />
        <Column
          title="会议室类型"
          dataIndex="workspaceType"
          render={_ => getSelectedTextFromList(workspaceTypeList, _)}
        />
        <Column title="容纳人数" dataIndex="realGalleryful" />
        <Column
          title="开始时间"
          dataIndex="useBeginTime"
          sorter={(a: any, b: any) => moment(a.useBeginTime).diff(b.useBeginTime)}
          sortDirections={['ascend', 'descend']}
          sortOrder="descend"
        />
        <Column title="结束时间" dataIndex="useEndTime" />
        <Column
          title="审批状态"
          dataIndex="status"
          render={_ => <Chip meetingRoomStatus={_} />}
        />
        <Column
          title="操作"
          dataIndex="bookingId"
          render={(_: number, record: any) => {
            if (record.workspaceType === 20) {
              return (
                <Space>
                  <Button
                    type="link"
                    onClick={() => {
                      setIsApproved(true);
                      setAuditModalVisible(true);
                      setSelectedBookingId(_);
                    }}
                  >通过</Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setIsApproved(false);
                      setAuditModalVisible(true);
                      setSelectedBookingId(_);
                    }}
                  >拒绝</Button>
                </Space>
              )
            }
            return (
              <>
                <Popconfirm
                  title={`确定要取消会议${record.bookingId}吗？`}
                  onConfirm={() => {
                    cancelBookingRecord(_).then((res: any) => {
                      const { resultCode, resultMsg } = res.data;
                      if (resultCode === 0) {
                        message.success(`已取消会议：${_}`);
                        return;
                      }
                      message.error(resultMsg);
                    })
                  }}
                >
                  <Button type="link">取消</Button>
                </Popconfirm>
              </>
            )
          }}
        />
      </Table>
      <AuditModal
        bookingId={selectedBookingId}
        visible={auditModalVisible}
        isApproved={isApproved}
        onClose={() => setAuditModalVisible(false)}
      />
    </>
  )
}

export default Calendar;