import { useState } from "react";
import { Space } from 'antd';
import styled from 'styled-components';
import Panel from '@/screens/EnergyManagement/Panel';
import img_wifi_on from '@/assets/wifi_on@2x.png';
import img_wifi_off from '@/assets/wifi_off@2x.png';
import { Button, SwitchContainer } from './StyledComponents';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 42px;
`

const Logo = styled.img`
  width: 66px;
`

const Speed = styled.div`
  font-size: 10px;
  color: #8F959E;
  > span {
    padding: 0 3px
  }
`

interface Props {
  deviceId: number;
  deviceNo: string;
}

const WifiController: React.FC<Props> = () => {
  const [speed, setSpeed] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Panel title="网络接入">
      <>
        <LogoContainer>
          <Logo src={isOpen ? img_wifi_on : img_wifi_off} />
          <Speed>
            当前速度
            <span>{speed}</span>
            mb/s
          </Speed>
        </LogoContainer>
        <SwitchContainer>
          <Space size={18}>
              <Button
                actived={isOpen}
                onClick={() => setIsOpen(true)}
              >开启</Button>
              <Button
                actived={!isOpen}
                onClick={() => setIsOpen(false)}
              >关闭</Button>
            </Space>
        </SwitchContainer>
      </>
    </Panel>
  )
}

export default WifiController;