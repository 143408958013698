import { Dictionary, ValueAndText } from "@/types/common";
import { SexMap } from '@/common/constants';

export function convertDict2List(data: Dictionary) {
  const list: Array<ValueAndText> = [];

  if (data) {
    Object.keys(data).forEach(key => list.push({
      text: data[key],
      label: data[key],
      value: isNaN(Number(key)) ? key : Number(key)
    }))
  }

  return list;
}

export function getSelectedTextFromList(list: ValueAndText[], selectedValue: string | number | undefined): string {
  return list.find(item => item.value.toString() === selectedValue?.toString())?.text || '';
}

export function getSexText(sex: number | string) {
  return SexMap[sex?.toString()] || ''
}

export function removeEmptyParams(params: any) {
  const newParams = { ...params };
  Object.keys(newParams).forEach(key => {
    if (!newParams[key]) {
      delete newParams[key]
    }
  })

  return newParams;
}