import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import StatusBar from './StatusBar';
import { Button, Row, Col, message, Input, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Title from "@/components/Form/Title";
import FormItem from "@/components/Form/Item";
import Text from "@/components/Form/Text";
import { getTaskDetail, finishTask, updateRepariDesc } from '@/services/task';
import { getUserInfoById } from '@/services/user';

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`;

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FormContainer = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  background-color: #F4F5F9;
  padding: 5px 10px;
  font-size: 14px;
  color: #1f2329;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 0;
  margin-top: 24px;
`

const TaskDetail: React.FC = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const [detail, setDetail] = useState<any>({});
  const [applyUserName, setApplyUserName] = useState('');
  const [applyPhone, setApplyPhone] = useState('');
  const [remark, setRemark] = useState('');

  const onFinish = () => {
    finishTask(id).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        getData();
      } else {
        message.error('操作失败');
      }
    })
  }

  const onSave = () => {
    updateRepariDesc(id, remark).then((res: any) => {
      const { resultCode } = res.data;
      if (resultCode === 0) {
        getData();
        message.success('报修描述已保存');
      } else {
        message.error('操作失败');
      }
    })
  }

  const getData = () => {
    getTaskDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDetail(data);
        setRemark(data?.repairDesc);
        return getUserInfoById(data.applyUserId);
      }
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setApplyUserName(data.realName);
        setApplyPhone(data.mobileNo);
      }
    })
  }

  useEffect(() => {
    getData()
  }, [id])

  return (
    <>
      <ButtonBar>
        <Button type="text" onClick={() => { history.goBack() }}><CloseOutlined /></Button>
      </ButtonBar>
      <Container>
        {/* <StatusBar status="1" endDate="2022-11-28 16:56:10" /> */}
        <Panel>
          <Row gutter={[14, 12]} wrap={false}>
            <Col>
              <ImageContainer>
                <img src={detail.imageUrls} />
              </ImageContainer>
            </Col>
            <Col flex={1}>
              <TextContainer>{detail.repairTitle}</TextContainer>
              <TextContainer>{detail.repairDesc}</TextContainer>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <TitleContainer>
            <Title>任务详情</Title>
            {
              detail?.handleStatus !== 2 ? (
                <Space>
                  <Button type="primary" onClick={onSave}>保存</Button>
                  <Button type="primary" onClick={onFinish}>完成</Button>
                </Space>
              ) : '已完成'
            }
          </TitleContainer>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="任务申报时间">
                  <Text>{detail.applyTime}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="任务编号">
                  <Text>{detail.taskId}</Text>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="报修描述（供应商信息、耗材记录）">
                  <Input.TextArea
                    placeholder="请输入报修描述（供应商信息、耗材记录）"
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                  />
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
        <Panel>
          <TitleContainer>
            <Title>申报人信息</Title>
          </TitleContainer>
          <FormContainer>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="申报人">
                  <Text>{applyUserName}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="申报房间">
                  <Text>{detail.roomNo}</Text>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="联系手机">
                  <Text>{applyPhone}</Text>
                </FormItem>
              </Col>
            </Row>
          </FormContainer>
        </Panel>
      </Container>
    </>
  )
}

export default TaskDetail;