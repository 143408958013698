import { useEffect, useState } from 'react';
import { Input, Select, Row, Col, Modal, Upload, Form } from 'antd';
import { useSelector } from 'react-redux';
import { uploadFile, getFileUrl } from '@/services/common';
import { ValueAndText } from '@/types/common';
import UploadButton from '@/components/UploadButton';

const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

interface Props {
  data: any;
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
}

const PatentEditModal: React.FC<Props> = ({
  data,
  visible,
  onCancel,
  onOk
}) => {
  const patentTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.patentTypeList);
  const [patentImageUrl, setPatentImageUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [form] = Form.useForm();
  
  const uploadPatentImage = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;
    setUploading(true);
    uploadFile({
      file,
      onProgress
    }).then(res => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setPatentImageUrl(getFileUrl(data))
        onSuccess(file)
        setUploading(false)
      }
    }).catch(err => {
      onError({ event: err })
      setUploading(false)
    });
  }

  useEffect(() => {
    form.setFieldsValue(data);
    setPatentImageUrl(data.patentImageUrl || '')
  }, [data])

  return (
    <Modal
      title="知识产权"
      visible={visible}
      closable
      onCancel={onCancel}
      onOk={() => {
        onOk({
          ...form.getFieldsValue(),
          patentImageUrl
        });
      }}
    >
      <Form form={form}>
        <Row gutter={[22, 0]}>
          <Col span={12}>
            <FormItem label="类别" name="patentType">
              <Select>
                {
                  patentTypeList.map(({ value, text }) => (
                    <Option key={`cmp_edit_patenttype_${value}`} value={value}>{text}</Option>
                  ))
                }
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="名称" name="patentName">
              <Input />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="详情" name="patentDescription">
              <TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="照片">
              <Upload
                listType="picture-card"
                showUploadList={false}
                customRequest={uploadPatentImage}
              >
                { patentImageUrl ? <img src={patentImageUrl} alt="" style={{ width: '100%' }} /> : <UploadButton loading={uploading} /> }
              </Upload>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default PatentEditModal;