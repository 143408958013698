import bg from '@/assets/floor_plan/11/11lou.png';
import img_1101 from '@/assets/floor_plan/11/common/1101.png';
import img_1102 from '@/assets/floor_plan/11/common/1102.png';
import img_1103 from '@/assets/floor_plan/11/common/1103.png';
import img_1105 from '@/assets/floor_plan/11/common/1105.png';
import img_1101_selected from '@/assets/floor_plan/11/selected/1101.png';
import img_1102_selected from '@/assets/floor_plan/11/selected/1102.png';
import img_1103_selected from '@/assets/floor_plan/11/selected/1103.png';
import img_1105_selected from '@/assets/floor_plan/11/selected/1105.png';

import img_1101_expire from '@/assets/floor_plan/11/expire/1101.png';
import img_1102_expire from '@/assets/floor_plan/11/expire/1102.png';
import img_1103_expire from '@/assets/floor_plan/11/expire/1103.png';
import img_1105_expire from '@/assets/floor_plan/11/expire/1105.png';

import img_1101_mismatch from '@/assets/floor_plan/11/mismatch/1101.png';
import img_1102_mismatch from '@/assets/floor_plan/11/mismatch/1102.png';
import img_1103_mismatch from '@/assets/floor_plan/11/mismatch/1103.png';
import img_1105_mismatch from '@/assets/floor_plan/11/mismatch/1105.png';

const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;

const initialRoomListData = [
  {
    title: '1101',
    titleLeft: `${100 * (573 - 401) / 380}%`,
    titleTop: `${100 * (618 - 418) / 380}%`,
    roomNo: '1101',
    width: width(380),
    left: left(401),
    top: top(418),
    image: img_1101,
    selectedImage: img_1101_selected,
    expireImage: img_1101_expire,
    mismatchImage: img_1101_mismatch
  },
  {
    title: '1102',
    roomNo: '1102',
    width: width(368),
    left: left(447),
    top: top(280),
    image: img_1102,
    selectedImage: img_1102_selected,
    expireImage: img_1102_expire,
    mismatchImage: img_1102_mismatch
  },
  {
    title: '1103',
    roomNo: '1103',
    width: width(346),
    left: left(817),
    top: top(280),
    image: img_1103,
    selectedImage: img_1103_selected,
    expireImage: img_1103_expire,
    mismatchImage: img_1103_mismatch
  },
  {
    title: '1105',
    titleLeft: `${100 * (956 - 783) / 380}%`,
    titleTop: `${100 * (618 - 434) / 380}%`,
    roomNo: '1105',
    width: width(380),
    left: left(783),
    top: top(434),
    image: img_1105,
    selectedImage: img_1105_selected,
    expireImage: img_1105_expire,
    mismatchImage: img_1105_mismatch
  },
]

export default {
  bg,
  initialRoomList: initialRoomListData
}