import { useState, useEffect } from 'react';
import { Button, Radio } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import { CalendarMode } from '@/types/common';
import Calendar from './Calendar';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #E8E8E8;
`

const QueryDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > .title {
    width: 137px;
    text-align: center;
    color: #1F2329;
    font-size: 16px;
    font-weight: 500;
    height: 22px;
    line-height: 22px;
    -moz-user-select:none; 
    -webkit-user-select:none; 
    -ms-user-select:none;
    -khtml-user-select:none;
    user-select:none;
  }

  > .button {
    cursor: pointer;
    display: block;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-container: row;
`

const BtnToday = styled.div`
  margin-left: 12px;
  background-color: #3371FF;
  border-radius: 3px;
  width: 50px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
`

const MeetingRoom = () => {
  const [queryDate, setQueryDate] = useState(moment());
  const [calendarMode, setCalendarMode] = useState<CalendarMode>(CalendarMode.month);

  const next = () => {
    setQueryDate(queryDate.add(1, calendarMode as moment.unitOfTime.DurationConstructor).clone())
  }
  const prev = () => {
    setQueryDate(queryDate.subtract(1, calendarMode as moment.unitOfTime.DurationConstructor).clone())
  }

  return (
    <div>
      <Header>
        <Button icon={<LeftOutlined />} type="link">返回</Button>
        <QueryDate>
          <LeftOutlined color="#646A73" className="button" onClick={prev} />
          <div className="title">{
            calendarMode === CalendarMode.month ? queryDate.format('YYYY年MM月') : queryDate.format('YYYY年MM月DD日')
          }</div>
          <RightOutlined color="#646A73" className="button" onClick={next} />
        </QueryDate>
        <ButtonGroup>
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={calendarMode}
            onChange={e => setCalendarMode(e.target.value as CalendarMode)}
          >
            <Radio.Button value={CalendarMode.day}>日</Radio.Button>
            <Radio.Button value={CalendarMode.month}>月</Radio.Button>
          </Radio.Group>
          <BtnToday
            onClick={() => {
              setCalendarMode(CalendarMode.day);
              setQueryDate(moment())
            }}
          >今天</BtnToday>
        </ButtonGroup>
      </Header>
      <Calendar mode={calendarMode} queryDate={queryDate.format('YYYY-MM-DD')} />      
    </div>
  )
}

export default MeetingRoom;