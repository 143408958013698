import styled from 'styled-components';

interface RoomContainerProps {
  width: string | number;
  left: string | number;
  top: string | number;
  disabled: boolean;
}

const Container = styled.div<RoomContainerProps>`
  position: absolute;
  width: ${props => props.width};
  left: ${props => props.left};
  top: ${props => props.top};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  ${props => props.color ? `color: ${props.color}` : null}
`

interface TitleContainerProps {
  left?: string;
  top?: string;
  writingMode?: string;
}
interface TitleProps {
  left?: string;
  top?: string;
  fontSize?: string;
  width?: string;
  writingMode?: string;
  rotate?: string;
}

// TODO: align-items 和 justify-content 需要根据flex-direction 来切换和left/top的对应关系

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  flex-direction: ${props => props.writingMode?.includes('vertical') ? 'row' : 'column'};
  position: absolute;
  align-items: ${props => props.left ? 'flex-start' : 'center'};
  justify-content: ${props => props.top ? 'flex-start' : 'center'};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  ${props => props.left ? `padding-left: ${props.left};` : null}
  ${props => props.top ? `padding-top: ${props.top};` : null}
`

const Title = styled.div<TitleProps>`
  font-size: ${props => props.fontSize || '16px'};
  text-align: center;
  width: ${props => props.width || (props.writingMode?.includes('vertical') ? 'auto' : '100%')};
  ${props => props.writingMode ? `writing-mode: ${props.writingMode};` : null}
  ${props => props.fontSize === 'small' ? `transform: scale(0.8);` : null}
  ${props => props.fontSize === 'small' ? `line-height: 12px;` : null}
  ${props => props.rotate ? `transform: rotate(${props.rotate})` : null}
`

interface Props {
  roomNo: string;
  title: string;
  titleLeft?: string;
  titleTop?: string;
  titleWidth?: string;
  writingMode?: string;
  fontSize?: string;
  color?: string;
  rotate?: string;
  selectedColor?: string;
  width: string;
  left: string;
  top: string;
  disabled: boolean;
  selected?: boolean;
  onClick: () => void;
  image: string;
  selectedImage: string;
}

const Room: React.FC<Props> = ({
  roomNo,
  title,
  titleLeft,
  titleTop,
  titleWidth,
  fontSize,
  color = '#FFFFFF',
  rotate,
  selectedColor,
  image,
  writingMode,
  selected,
  selectedImage,
  ...props
}) => {
  let src = image;
  let titleColor = color;

  if (selected && selectedImage) {
    src = selectedImage;
  }
  if (selected && selectedColor) {
    titleColor = selectedColor;
  }

  return (
    <Container {...props} color={titleColor}>
      <img alt="" src={src} width="100%" />
      <TitleContainer left={titleLeft} top={titleTop} writingMode={writingMode}>
        <Title
          fontSize={fontSize}
          width={titleWidth}
          writingMode={writingMode}
          rotate={rotate}
        >{title}</Title>
        {
          title !== roomNo ? (
            <Title
              fontSize={fontSize}
              width={titleWidth}
              writingMode={writingMode}
              rotate={rotate}
            >{roomNo}</Title>
          ) : null
        }
      </TitleContainer>
    </Container>
  )
}

export default Room;