import { useState, useEffect } from 'react';
import { Row, Col, Table, Space, Button, Popconfirm } from "antd";
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from '@/components/Select';
import { getEmployeeList, updateJobStatus, getEmployeeCount } from '@/services/company';
import { getSelectedTextFromList } from '@/utils/transform';

const { Column } = Table;

const ApplicantList = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [currentJobStatus, setCurrentJobStatus] = useState<number>();
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const jobStatusList = useSelector((state: any) => state.common.jobStatusList);
  const history = useHistory();
  const { corpId } = history.location.state as any;
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState<number | undefined>(100);
  const [dataCount, setDataCount] = useState(0);
  
  const getData = (params: any, page = 1, pageSize = 100) => {
    getEmployeeList({
      corpId,
      ...params,
      currentPage: page,
      pageSize: pageSize
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data)
      }
    });
    getEmployeeCount({
      corpId,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
  }

  const pass = (id: number | string) => {
    updateJobStatus(id, 1).then(() => {
      getData({
        corpId,
        jobStatus: currentJobStatus,
        currentPage: 1,
        pageSize: 10
      })
    })
  }

  const refuse = (id: number | string) => {
    updateJobStatus(id, 4).then(() => {
      getData({
        corpId,
        jobStatus: currentJobStatus,
        currentPage: 1,
        pageSize: 10
      })
    })
  }

  const batchPass = () => {
    Promise.all(selectedIds.map(id => updateJobStatus(id, 1))).then(() => {
      getData({
        corpId,
        jobStatus: currentJobStatus,
        currentPage: 1,
        pageSize: 10
      })
    })
  }

  const batchRefuse = () => {
    Promise.all(selectedIds.map(id => updateJobStatus(id, 4))).then(() => {
      getData({
        corpId,
        jobStatus: currentJobStatus,
        currentPage: 1,
        pageSize: 10
      })
    })
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    setPageIndex(page);
    setPageSize(pageSize_ || pageSize);
    getData({
      jobStatus: currentJobStatus,
    }, page, pageSize_)
  }

  useEffect(() => {
    getData({
      corpId,
      jobStatus: currentJobStatus,
      currentPage: 1,
      pageSize: 10
    })
  }, [])

  return (
    <>
      <Row style={{ marginBottom: 22 }}>
        <Col flex={2}>
          <Select
            label="申请状态"
            options={jobStatusList}
            value={currentJobStatus}
            onChange={(val: number) => {
              setCurrentJobStatus(val);
              getData({ jobStatus: val })
            }}
          />
        </Col>
        <Col flex={3}>
          <Row justify="end">
            <Space>
              <Popconfirm
                placement="top"
                title="确认批量通过这些申请吗？"
                onConfirm={batchPass}
              >
                <Button>批量通过</Button>
              </Popconfirm>
              <Popconfirm
                placement="top"
                title="确认批量拒绝这些申请吗？"
                onConfirm={batchRefuse}
              >
                <Button danger>批量拒绝</Button>
              </Popconfirm>
            </Space>
          </Row>
        </Col>
      </Row>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(selectedRowKeys)
            setSelectedIds(selectedRows.map(item => item.id))
          }

        }}
        rowKey={(record: any) => `aptl_data_${record.id}`}
        dataSource={dataSource}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: pageIndex,
          onChange: onPagination,
          showSizeChanger: true
        }}
      >
        <Column title="申请人姓名" dataIndex="realName" />
        <Column title="手机" dataIndex="mobileNo" />
        <Column title="申请加入公司" dataIndex="corpName" />
        <Column title="账号状态" dataIndex="jobStatus" render={(_) => getSelectedTextFromList(jobStatusList, _)} />
        <Column title="邀请人" />
        <Column
          title="操作"
          dataIndex="id"
          render={(_: string, record: any) => {
            if (record.jobStatus?.toString() === '0') {
              return  (
                <Space>
                  <Popconfirm
                    placement="top"
                    title={`是否确认通过${record.realName}的入职申请`}
                    onConfirm={() => { pass(_)}}
                  >
                    <Button type="link">通过</Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="top"
                    title={`是否确认通过${record.realName}的入职申请`}
                    onConfirm={() => { refuse(_)}}
                  >
                    <Button type="link">拒绝</Button>
                  </Popconfirm>
                </Space>
              )
            }
            return <></>
          }}
        />
      </Table>
    </>
  )
}

export default ApplicantList;