import React from 'react';
import Cube from './Cube';
import { Gauge } from '@ant-design/plots';

const config: any = {
  percent: 0.32,
  range: {
    color: '#30BF78',
  },
  indicator: {
    pointer: {
      style: {
        stroke: '#D0D0D0',
      },
    },
    pin: {
      style: {
        stroke: '#D0D0D0',
      },
    },
  },
  axis: {
    label: {
      formatter(v: any) {
        return Number(v) * 100;
      },
    },
    subTickLine: {
      count: 3,
    },
    // max: 100,
    min: 0,
  },
  statistic: {
    content: {
      // formatter: ({ percent }) => `Rate: ${(percent * 100).toFixed(0)}%`,
      style: {
        color: 'rgba(0,0,0,0.65)',
        fontSize: 12,
      },
    },
  },
};

interface Props {
  monthlySaveCost: number;
  dailySaveCost: number;
  rate: number;
}

const CubeElectric: React.FC<Props> = ({
  dailySaveCost,
  monthlySaveCost,
  rate
}) => {
  return (
    <Cube
      title="电量统计"
      dailyValue={`${dailySaveCost}kWh`}
      dailyText="昨日用电量"
      monthlyValue={monthlySaveCost}
      monthlyText="本月用电量"
      rate={rate}
      logo={<Gauge width={162} height={136} {...config} />}
    />
  )
}

export default CubeElectric;