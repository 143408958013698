import React from 'react';
import { Modal, Table } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getSelectedTextFromList } from '@/utils/transform';

const { Column } = Table;

const Container = styled.div`
  padding: 12px;
`

interface Props {
  data: Array<any>;
  visible: boolean;
  onClose: () => void;
}

const Detail: React.FC<Props> = ({
  data,
  visible,
  onClose,
}) => {
  const businessTypeList = useSelector((state: any) => state.common.businessTypeList);
  return (
    <Modal
      visible={visible}
      title="账单详情"
      onCancel={onClose}
    >
      <Container>
        <Table dataSource={data} pagination={false}>
          <Column title="房间号" dataIndex="roomNo" />
          <Column title="账单名称" dataIndex="billTitle" />
          <Column title="账单类型" dataIndex="businessType" render={_ => getSelectedTextFromList(businessTypeList, _)} />
          <Column title="账单金额" dataIndex="payableAmount" />
        </Table>
      </Container>
    </Modal>
  )
}

export default Detail;