import request from '@/utils/request';
import jsonToFormData from 'json-form-data';


export function saveActivity(data: any) {
  const formData = jsonToFormData(data, {
    initialFormData: new FormData()
  });
  const api = data.activityId ? '/api/permission/activityRecord/upd' : '/api/permission/activityRecord/add'
  return request.post(api, formData);
}

export function getActivityList(data: any) {
  const formData = jsonToFormData({
    ...data,
    pageSize: 20
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/activityRecord/list', formData);
}

export function getActivityCount(data: any) {
  const formData = jsonToFormData(data, { initialFormData: new FormData() })
  return request.post('/api/permission/activityRecord/count', formData);
}

export function getActivityDetail(id: string | number) {
  return request.get(`/api/permission/activityRecord/detail?activityId=${id}`);
}

export function deleteActivity(id: string | number) {
  return request.get(`/api/permission/activityRecord/del?activityId=${id}`);
}