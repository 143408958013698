import request from '@/utils/request';
import jsonToFormData from 'json-form-data';
import { convertDict2List } from '@/utils/transform';

export function getAdvertiseList(data: any) {
  const formData = jsonToFormData({
    ...data,
    pageSize: 20
  }, { initialFormData: new FormData() })
  return request.post('/api/permission/adRecord/list', formData)
}

export function getAdvertiseCount(data: any) {
  const formData = jsonToFormData(data, { initialFormData: new FormData() })
  return request.post('/api/permission/adRecord/count', formData);
}

export function saveAdvertise(data: any) {
  const formData = jsonToFormData(data, {
    initialFormData: new FormData()
  });
  const api = data.adId ? '/api/permission/adRecord/upd' : '/api/permission/adRecord/add'
  return request.post(api, formData);
}

export function getAdvertiseDetail(id: string | number) {
  return request.get(`/api/permission/adRecord/detail?adId=${id}`)
}

export function getAdTypeList() {
  return request.get('/api/conf/ad/adType').then(res => convertDict2List(res.data))
}

export function getAdDisplayLocationList() {
  return request.get('/api/conf/ad/displayLocation').then(res => convertDict2List(res.data))
} 

export function getAdStatusList() {
  return request.get('/api/conf/ad/status').then(res => convertDict2List(res.data))
}
