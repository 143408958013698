import { Divider } from 'antd';
import React from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  width: 388px;
  height: 332px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  height: 58px;
  border-bottom: 1px solid #E8E8E8;
  padding-left: 18px;
  line-height: 58px;
  font-size: 15px;
`

const Body = styled.div`
  padding: 0 38px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Row1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Logo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 16px;
  > img {
    width: 82px;
  }
`

const MonthlyValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
`

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  border-top: 1px solid #E8E8E8;
  width: 100%;
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const MonthlyValue = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #1F2329;
`

const DailyValue = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #1F2329;
`

const Rate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;

  &.up {
    color: #F64A46;
  }

  &.down {
    color: #00D6B9;
  }
`

const Name = styled.div`
  font-size: 10px;
  color: #9F959E;
`

interface Props {
  title: string;
  dailyValue: number | string;
  dailyText: string;
  monthlyValue: number | string;
  monthlyText: string;
  rate: number;
  logo: React.ReactNode;
}

const Cube: React.FC<Props> = ({
  title,
  dailyValue,
  dailyText,
  monthlyValue,
  monthlyText,
  rate,
  logo,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Body>
        <Row1>
          <Logo>{logo}</Logo>
          <MonthlyValueContainer>
            <MonthlyValue>{monthlyValue}</MonthlyValue>
            <Name>{monthlyText}</Name>
          </MonthlyValueContainer>
        </Row1>
        <Row2>
          <ValueContainer>
            <DailyValue>{dailyValue}</DailyValue>
            <Name>{dailyText}</Name>
          </ValueContainer>
          <Divider type="vertical" />
          <ValueContainer>
            <Rate className={rate > 0 ? 'up' : 'down'}>
              { rate > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
              <span>{`${Math.abs(rate)}%`}</span>
            </Rate>
            <Name>与上月同比</Name>
          </ValueContainer>
        </Row2>
      </Body>
    </Container>
  )
}

export default Cube;