import bg from '@/assets/energy/floors/12/12.png';
import img_1200_1_common from '@/assets/energy/floors/12/common/1200-1.png';
import img_1200_2_common from '@/assets/energy/floors/12/common/1200-2.png';
import img_1200_3_common from '@/assets/energy/floors/12/common/1200-3.png';
import img_1200_4_common from '@/assets/energy/floors/12/common/1200-4.png';
import img_1200_5_common from '@/assets/energy/floors/12/common/1200-5.png';
import img_1200_6_common from '@/assets/energy/floors/12/common/1200-6.png';
import img_1205_1_common from '@/assets/energy/floors/12/common/1205-1.png';
import img_1205_2_common from '@/assets/energy/floors/12/common/1205-2.png';
import img_1205_3_common from '@/assets/energy/floors/12/common/1205-3.png';
import img_1205_4_common from '@/assets/energy/floors/12/common/1205-4.png';
import img_1205_5_common from '@/assets/energy/floors/12/common/1205-5.png';
import img_1205_6_common from '@/assets/energy/floors/12/common/1205-6.png';
import img_1205_7_common from '@/assets/energy/floors/12/common/1205-7.png';
import img_1205_8_common from '@/assets/energy/floors/12/common/1205-8.png';
import img_1205_9_common from '@/assets/energy/floors/12/common/1205-9.png';
import img_1201_1_common from '@/assets/energy/floors/12/common/1201-1.png';
import img_1201_2_common from '@/assets/energy/floors/12/common/1201-2.png';
import img_1201_3_common from '@/assets/energy/floors/12/common/1201-3.png';
import img_1201_4_common from '@/assets/energy/floors/12/common/1201-4.png';
import img_1201_5_common from '@/assets/energy/floors/12/common/1201-5.png';
import img_1201_6_common from '@/assets/energy/floors/12/common/1201-6.png';
import img_1202_1_common from '@/assets/energy/floors/12/common/1202-1.png';
import img_1202_2_common from '@/assets/energy/floors/12/common/1202-2.png';
import img_1202_3_common from '@/assets/energy/floors/12/common/1202-3.png';
import img_1202_4_common from '@/assets/energy/floors/12/common/1202-4.png';
import img_1202_5_common from '@/assets/energy/floors/12/common/1202-5.png';
import img_1202_6_common from '@/assets/energy/floors/12/common/1202-6.png';
import img_1203_1_common from '@/assets/energy/floors/12/common/1203-1.png';
import img_1203_2_common from '@/assets/energy/floors/12/common/1203-2.png';
import img_1203_3_common from '@/assets/energy/floors/12/common/1203-3.png';
import img_1203_4_common from '@/assets/energy/floors/12/common/1203-4.png';
import img_1203_5_common from '@/assets/energy/floors/12/common/1203-5.png';
import img_1203_6_common from '@/assets/energy/floors/12/common/1203-6.png';
import img_1203_7_common from '@/assets/energy/floors/12/common/1203-7.png';
import img_1203_8_common from '@/assets/energy/floors/12/common/1203-8.png';
import img_1230_common from '@/assets/energy/floors/12/common/1230.png';
import img_1231_common from '@/assets/energy/floors/12/common/1231.png';
import img_1232_common from '@/assets/energy/floors/12/common/1232.png';
import img_1233_common from '@/assets/energy/floors/12/common/1233.png';
import img_1234_common from '@/assets/energy/floors/12/common/1234.png';
import img_1235_common from '@/assets/energy/floors/12/common/1235.png';
import img_1236_common from '@/assets/energy/floors/12/common/1236.png';
import img_1237_common from '@/assets/energy/floors/12/common/1237.png';

import img_1200_1_selected from '@/assets/energy/floors/12/selected/1200-1.png';
import img_1200_2_selected from '@/assets/energy/floors/12/selected/1200-2.png';
import img_1200_3_selected from '@/assets/energy/floors/12/selected/1200-3.png';
import img_1200_4_selected from '@/assets/energy/floors/12/selected/1200-4.png';
import img_1200_5_selected from '@/assets/energy/floors/12/selected/1200-5.png';
import img_1200_6_selected from '@/assets/energy/floors/12/selected/1200-6.png';
import img_1205_1_selected from '@/assets/energy/floors/12/selected/1205-1.png';
import img_1205_2_selected from '@/assets/energy/floors/12/selected/1205-2.png';
import img_1205_3_selected from '@/assets/energy/floors/12/selected/1205-3.png';
import img_1205_4_selected from '@/assets/energy/floors/12/selected/1205-4.png';
import img_1205_5_selected from '@/assets/energy/floors/12/selected/1205-5.png';
import img_1205_6_selected from '@/assets/energy/floors/12/selected/1205-6.png';
import img_1205_7_selected from '@/assets/energy/floors/12/selected/1205-7.png';
import img_1205_8_selected from '@/assets/energy/floors/12/selected/1205-8.png';
import img_1205_9_selected from '@/assets/energy/floors/12/selected/1205-9.png';
import img_1201_1_selected from '@/assets/energy/floors/12/selected/1201-1.png';
import img_1201_2_selected from '@/assets/energy/floors/12/selected/1201-2.png';
import img_1201_3_selected from '@/assets/energy/floors/12/selected/1201-3.png';
import img_1201_4_selected from '@/assets/energy/floors/12/selected/1201-4.png';
import img_1201_5_selected from '@/assets/energy/floors/12/selected/1201-5.png';
import img_1201_6_selected from '@/assets/energy/floors/12/selected/1201-6.png';
import img_1202_1_selected from '@/assets/energy/floors/12/selected/1202-1.png';
import img_1202_2_selected from '@/assets/energy/floors/12/selected/1202-2.png';
import img_1202_3_selected from '@/assets/energy/floors/12/selected/1202-3.png';
import img_1202_4_selected from '@/assets/energy/floors/12/selected/1202-4.png';
import img_1202_5_selected from '@/assets/energy/floors/12/selected/1202-5.png';
import img_1202_6_selected from '@/assets/energy/floors/12/selected/1202-6.png';
import img_1203_1_selected from '@/assets/energy/floors/12/selected/1203-1.png';
import img_1203_2_selected from '@/assets/energy/floors/12/selected/1203-2.png';
import img_1203_3_selected from '@/assets/energy/floors/12/selected/1203-3.png';
import img_1203_4_selected from '@/assets/energy/floors/12/selected/1203-4.png';
import img_1203_5_selected from '@/assets/energy/floors/12/selected/1203-5.png';
import img_1203_6_selected from '@/assets/energy/floors/12/selected/1203-6.png';
import img_1203_7_selected from '@/assets/energy/floors/12/selected/1203-7.png';
import img_1203_8_selected from '@/assets/energy/floors/12/selected/1203-8.png';
import img_1230_selected from '@/assets/energy/floors/12/selected/1230.png';
import img_1231_selected from '@/assets/energy/floors/12/selected/1231.png';
import img_1232_selected from '@/assets/energy/floors/12/selected/1232.png';
import img_1233_selected from '@/assets/energy/floors/12/selected/1233.png';
import img_1234_selected from '@/assets/energy/floors/12/selected/1234.png';
import img_1235_selected from '@/assets/energy/floors/12/selected/1235.png';
import img_1236_selected from '@/assets/energy/floors/12/selected/1236.png';
import img_1237_selected from '@/assets/energy/floors/12/selected/1237.png';

const width = (w: number) => `${100 * (w / 768)}%`;
const left = (l: number) => `${100 * (l - 398) / 768}%`;
const top = (t: number) => `${100 * (t - 277) / 408}%`;
const fontSize = (w: number, f: number) => `${100 * (f / w)}%`
// {
//   title: '12xx-',
//   roomNo: '12xx-',
//   width: width(w),
//   left: left(),
//   top: top(),
//   fontSize: fontSize(w, 12),
//   color: '#808080',
//   writingMode: 'vertical-lr',
//   image: img_12xx__common,
//   selectedImage: img_12xx__selected,
// },
const initialRoomList = [
  {
    title: '空调机房',
    roomNo: '1230',
    width: width(44),
    left: left(401),
    top: top(280),
    fontSize: fontSize(44, 12),
    writingMode: 'vertical-lr',
    image: img_1230_common,
    selectedImage: img_1230_selected,
  },
  {
    title: '1201-1',
    titleLeft: `${100 * (599 - 447) / 368}%`,
    roomNo: '1201-1',
    width: width(368),
    left: left(447),
    top: top(280),
    fontSize: fontSize(368, 16),
    image: img_1201_1_common,
    selectedImage: img_1201_1_selected,
  },
  {
    title: '1201-2',
    roomNo: '1201-2',
    width: width(60),
    left: left(447),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1201_2_common,
    // selectedImage: img_1201_2_selected,
  },
  {
    title: '1201-3',
    roomNo: '1201-3',
    width: width(60),
    left: left(447),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1201_3_common,
    // selectedImage: img_1201_3_selected,
  },
  {
    title: '1201-4',
    roomNo: '1201-4',
    width: width(60),
    left: left(447),
    top: top(338),
    fontSize: fontSize(60, 12),
    image: img_1201_4_common,
    // selectedImage: img_1201_4_selected,
  },
  {
    title: '1201-5',
    roomNo: '1201-5',
    width: width(46),
    left: left(509),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1201_5_common,
    // selectedImage: img_1201_5_selected,
  },
  {
    title: '1201-6',
    roomNo: '1201-6',
    width: width(60),
    left: left(755),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1201_6_common,
    // selectedImage: img_1201_6_selected,
  },
  {
    title: '1202-1',
    titleLeft: `${100 * (1003 - 817) / 346}%`,
    titleWidth: 'atuo',
    roomNo: '1202-1',
    width: width(346),
    left: left(817),
    top: top(280),
    fontSize: fontSize(346, 16),
    image: img_1202_1_common,
    selectedImage: img_1202_1_selected,
  },
  {
    title: '1202-2',
    roomNo: '1202-2',
    width: width(60),
    left: left(879),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1202_2_common,
    // selectedImage: img_1202_2_selected,
  },
  {
    title: '1202-3',
    roomNo: '1202-3',
    width: width(60),
    left: left(817),
    top: top(280),
    fontSize: fontSize(60, 12),
    image: img_1202_3_common,
    // selectedImage: img_1202_3_selected,
  },
  {
    title: '1202-4',
    roomNo: '1202-4',
    width: width(60),
    left: left(817),
    top: top(338),
    fontSize: fontSize(60, 12),
    image: img_1202_4_common,
    // selectedImage: img_1202_4_selected,
  },
  {
    title: '1202-5',
    roomNo: '1202-5',
    width: width(46),
    left: left(1117),
    top: top(280),
    fontSize: fontSize(46, 12),
    image: img_1202_5_common,
    // selectedImage: img_1202_5_selected,
  },
  {
    title: '1202-6',
    roomNo: '1202-6',
    width: width(46),
    left: left(1117),
    top: top(328),
    fontSize: fontSize(46, 12),
    image: img_1202_6_common,
    // selectedImage: img_1202_6_selected,
  },
  {
    title: '空调机房',
    roomNo: '1233',
    width: width(108),
    left: left(1055),
    top: top(390),
    fontSize: fontSize(108, 12),
    image: img_1233_common,
    selectedImage: img_1233_selected,
  },
  {
    title: '1203-1',
    titleLeft: `${100 * (948 - 783) / 380}%`,
    titleTop: `${100 *(592 - 434) / 380}%`,
    titleWidth: 'atuo',
    roomNo: '1203-1',
    width: width(380),
    left: left(783),
    top: top(434),
    fontSize: fontSize(380, 16),
    image: img_1203_1_common,
    selectedImage: img_1203_1_selected,
  },
  {
    title: '1203-2',
    roomNo: '1203-2',
    width: width(60),
    left: left(1103),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1203_2_common,
    // selectedImage: img_1203_2_selected,
  },
  {
    title: '1203-3',
    roomNo: '1203-3',
    width: width(60),
    left: left(783),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1203_3_common,
    // selectedImage: img_1203_3_selected,
  },
  {
    title: '1203-4',
    roomNo: '1203-4',
    width: width(76),
    left: left(783),
    top: top(632),
    fontSize: fontSize(76, 12),
    image: img_1203_4_common,
    // selectedImage: img_1203_4_selected,
  },
  {
    title: '1203-5',
    roomNo: '1203-5',
    width: width(60),
    left: left(1055),
    top: top(632),
    fontSize: fontSize(60, 12),
    image: img_1203_5_common,
    // selectedImage: img_1203_5_selected,
  },
  {
    title: '1203-6',
    roomNo: '1203-6',
    width: width(46),
    left: left(1117),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1203_6_common,
    // selectedImage: img_1203_6_selected,
  },
  {
    title: '1203-7',
    roomNo: '1203-7',
    width: width(46),
    left: left(861),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1203_7_common,
    // selectedImage: img_1203_7_selected,
  },
  {
    title: '1203-8',
    roomNo: '1203-8',
    width: width(46),
    left: left(909),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1203_8_common,
    // selectedImage: img_1203_8_selected,
  },
  {
    title: '1205-1',
    titleLeft: `${100 * (566 - 401) / 380}%`,
    titleTop: `${100 * (592 - 418) / 380}%`,
    titleWidth: 'atuo',
    roomNo: '1205-1',
    width: width(380),
    left: left(401),
    top: top(418),
    fontSize: fontSize(380, 16),
    image: img_1205_1_common,
    selectedImage: img_1205_1_selected,
  },
  {
    title: '1205-2',
    roomNo: '1205-2',
    width: width(60),
    left: left(721),
    top: top(574),
    fontSize: fontSize(60, 12),
    image: img_1205_2_common,
    // selectedImage: img_1205_2_selected,
  },
  {
    title: '1205-3',
    roomNo: '1205-3',
    width: width(76),
    left: left(705),
    top: top(632),
    fontSize: fontSize(76, 12),
    image: img_1205_3_common,
    // selectedImage: img_1205_3_selected,
  },
  {
    title: '1205-4',
    roomNo: '1205-4',
    width: width(60),
    left: left(401),
    top: top(580),
    fontSize: fontSize(60, 12),
    image: img_1205_4_common,
    // selectedImage: img_1205_4_selected,
  },
  {
    title: '1205-5',
    roomNo: '1205-5',
    width: width(46),
    left: left(401),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1205_5_common,
    // selectedImage: img_1205_5_selected,
  },
  {
    title: '1205-6',
    roomNo: '1205-6',
    width: width(46),
    left: left(657),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1205_6_common,
    // selectedImage: img_1205_6_selected,
  },
  {
    title: '1205-7',
    roomNo: '1205-7',
    width: width(46),
    left: left(609),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1205_7_common,
    // selectedImage: img_1205_7_selected,
  },
  {
    title: '1205-8',
    roomNo: '1205-8',
    width: width(46),
    left: left(449),
    top: top(632),
    fontSize: fontSize(46, 12),
    image: img_1205_8_common,
    // selectedImage: img_1205_8_selected,
  },
  {
    title: '1205-9',
    roomNo: '1205-9',
    width: width(46),
    left: left(401),
    top: top(528),
    fontSize: fontSize(46, 12),
    image: img_1205_9_common,
    // selectedImage: img_1205_9_selected,
  },
  {
    title: '茶水休闲区',
    roomNo: '1200-3',
    width: width(102),
    left: left(525),
    top: top(418),
    fontSize: fontSize(102, 16),
    image: img_1200_3_common,
    selectedImage: img_1200_3_selected,
  },
  {
    title: '1231',
    roomNo: '1231',
    width: width(69),
    left: left(657),
    top: top(418),
    fontSize: fontSize(69, 12),
    image: img_1231_common,
    selectedImage: img_1231_selected,
  },
  {
    title: '1236',
    roomNo: '1236',
    width: width(69),
    left: left(657),
    top: top(496),
    fontSize: fontSize(69, 12),
    image: img_1236_common,
    selectedImage: img_1236_selected,
  },
  {
    title: '1237',
    roomNo: '1237',
    width: width(40),
    left: left(686),
    top: top(468),
    fontSize: fontSize(40, 12),
    image: img_1237_common,
    selectedImage: img_1237_selected,
  },
  {
    title: '1235',
    roomNo: '1235',
    width: width(41),
    left: left(777),
    top: top(513),
    fontSize: fontSize(41, 12),
    image: img_1235_common,
    selectedImage: img_1235_selected,
  },
  {
    title: '1234',
    roomNo: '1234',
    width: width(32),
    left: left(910),
    top: top(497),
    fontSize: fontSize(32, 12),
    image: img_1234_common,
    selectedImage: img_1234_selected,
  },
  {
    title: '1232',
    roomNo: '1232',
    width: width(24),
    left: left(914),
    top: top(418),
    fontSize: fontSize(24, 12),
    writingMode: 'vertical-lr',
    image: img_1232_common,
    selectedImage: img_1232_selected,
  },
  {
    title: '1200-1',
    titleLeft: `${100 * (826 - 524) / 527}%`,
    titleTop: `${100 * (552 - 514) / 527}%`,
    titleWidth: 'atuo',
    roomNo: '1200-1',
    width: width(527),
    left: left(524),
    top: top(514),
    fontSize: fontSize(527, 12),
    color: '#808080',
    image: img_1200_1_common,
    selectedImage: img_1200_1_selected,
  },
  {
    title: '1200-2',
    roomNo: '1200-2',
    width: width(650),
    left: left(401),
    top: top(392),
    fontSize: fontSize(650, 12),
    color: '#808080',
    image: img_1200_2_common,
    selectedImage: img_1200_2_selected,
  },
  {
    title: '1200-4',
    roomNo: '1200-4',
    width: width(22),
    left: left(1029),
    top: top(418),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1200_4_common,
    selectedImage: img_1200_4_selected,
  },
  {
    title: '1200-5',
    roomNo: '1200-5',
    width: width(43),
    left: left(823),
    top: top(418),
    fontSize: fontSize(43, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1200_5_common,
    selectedImage: img_1200_5_selected,
  },
  {
    title: '1200-6',
    roomNo: '1200-6',
    width: width(22),
    left: left(631),
    top: top(418),
    fontSize: fontSize(22, 12),
    color: '#808080',
    writingMode: 'vertical-lr',
    image: img_1200_6_common,
    selectedImage: img_1200_6_selected,
  },
]

export default {
  bg,
  initialRoomList
}