import React, { useEffect, useState } from 'react';
import { Table, Button, Layout, Select, Space, Drawer, Popconfirm } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlusOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import img_add_user from '@/assets/add_user@2x.png';
import img_del_user from '@/assets/delete_user@2x.png';
import img_team from '@/assets/team@2x.png';
import { getEmployeeList, getEmployeeCount, updateJobStatus } from '@/services/company';
import SideBar from './SideBar';
import { ValueAndText } from '@/types/common';
import { getSelectedTextFromList } from '@/utils/transform';
import Detail from '../Detail';
import Chip from '@/components/Chip';
import { Key } from 'antd/lib/table/interface';
import { setJobStatus, setPagination } from '@/store/actions/employee';

const { Sider } = Layout;
const { Column } = Table;
const { Option } = Select;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > .text {
    font-size: 16px;
    color: #1F2329;
    padding-right: 12px;
  }

  > img {
    width : 16px;
    height : 16px;
  }
`

const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DEE0E3;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 190px;
  > .label {
    color: #8F959E;
    display: inline-block;
    padding-left: 12px;
    padding-right: 12px;     
  }
`

const LinkBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > img {
    width: 12px;
    height: 12px;
  }
  > .text {
    color: #3371FF;
    font-size: 14px;
  }
`

const NavBar = styled.div`
`

const ContentContainer = styled.div`
  background-color: #FFFFFF;
  border-left: 1px solid #DEE0E3;
  position: relative;
  flex: 1;
  padding: 0 12px 0 30px;
  margin-left: 26px;
`

const BtnCollapse = styled.div`
  position: absolute;
  left: -26px;
  top: 20px;
  background-color: #FFFFFF;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  border: 1px solid #DEE0E3;
  width: 26px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right-width: 0;
  &:after {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    top: -1px;
    right: -1px;
  }
`

const ButtonDetail = styled.div`
  cursor: pointer;
  color: #3371FF;
`

const EmployeeList = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [btnDepartureDisabled, setBtnDepartureDisabled] = useState(true);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState<number>(-1);
  const [dataSource, setDataSource] = useState<any[]>();
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resignList, setResignList] = useState<number[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const educationList: Array<ValueAndText> = useSelector((state: any) => state.common.educationList);
  const employeeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.employeeTypeList);
  const jobStatusList: Array<ValueAndText> = useSelector((state: any) => state.common.jobStatusList);
  const pageIndex = useSelector((state: any) => state.employee.pageIndex);
  const pageSize = useSelector((state: any) => state.employee.pageSize);
  const selectedCompanyId = useSelector((state: any) => state.employee.corpId);
  const selectedCompanyName = useSelector((state: any) => state.employee.corpName);
  const jobStatus = useSelector((state: any) => state.employee.jobStatus);

  const dispatch = useDispatch();

  const getData = (params: any = {}, page = 1, pageSize = 100) => {
    setLoading(true);
    getEmployeeList({
      corpId: selectedCompanyId,
      ...params,
      currentPage: page,
      pageSize: pageSize,
    }).then(res => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    });
    getEmployeeCount({
      corpId: selectedCompanyId,
      ...params
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataCount(data);
      }
    })
  }

  const showDetail = (index: number) => {
    setSelectedEmployeeIndex(index);
    setIsShowDetail(true);
  }

  const closeDetail = () => {
    setIsShowDetail(false);
    setSelectedEmployeeIndex(-1);
  }

  const onPagination = (page: number, pageSize_?: number | undefined) => {
    dispatch(setPagination(page, pageSize_ || pageSize))
    getData({}, page, pageSize_)
  }

  const batchDoResign = () => {
    Promise.all(
      resignList.map((employeeId: number) => updateJobStatus(employeeId, 2))
    ).then((values: any[]) => {
      getData({
        jobStatus: jobStatus > 0 ? jobStatus : undefined
      }, pageIndex, pageSize)
    })

    setResignList([]);
    setSelectedRowKeys([]);
  }

  useEffect(() => {
    dispatch(setPagination(1, pageSize))
    getData({}, 1, pageSize)
  }, [])

  useEffect(() => {
    getData({
      jobStatus: jobStatus >= 0 ? jobStatus : undefined,
      corpId: selectedCompanyId
    }, pageIndex, pageSize)
  }, [jobStatus, selectedCompanyId])

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={0} theme="light">
        <SideBar />
      </Sider>
      <ContentContainer className="sub-container">
        <BtnCollapse
          onClick={() => setCollapsed(!collapsed)}
        >{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</BtnCollapse>
        <Toolbar>
          <Title>
            <div className="text">{selectedCompanyName}</div>
            <img src={img_team} alt="" />
          </Title>
          <Space>
            {/* <LinkBtn>
              <img src={img_del_user} alt="" />
              <Link className="text" to="">已离职成员</Link>
            </LinkBtn> */}
            <LinkBtn>
              <img src={img_add_user} alt="" />
              <Link className="text" to={{
                pathname: `/company/invitation/${selectedCompanyId}`,
                state: { corpName: selectedCompanyName, corpId: selectedCompanyId }
              }}>邀请成员加入公司</Link>
            </LinkBtn>
            <LinkBtn>
              <img src={img_add_user} alt="" />
              <Link className="text" to={{
                pathname: `/company/applicants/${selectedCompanyId}`,
                state: { corpName: selectedCompanyName, corpId: selectedCompanyId }
              }}>审批成员</Link>
            </LinkBtn>
          </Space>
        </Toolbar>
        <Toolbar>
          <SearchBox>
            <div className="label">在职状态</div>
            <Select bordered={false} style={{ flex: 1 }} value={jobStatus} onChange={val => dispatch(setJobStatus(val))}>
              <Option value="-1">全部</Option>
              {
                jobStatusList.map((item, index) => (
                  <Option key={`empls_user_status_${index}`} value={item.value}>{item.text}</Option>
                ))
              }
            </Select>
          </SearchBox>
          <Space align="end">
            <Link to={`/company/addemployee/${selectedCompanyId}`}><Button type="primary" icon={<PlusOutlined />}>添加成员</Button></Link>
            {/* <Button>批量导入</Button> */}
            <Popconfirm
              title="确定要批量操作这些成员离职吗？"
              onConfirm={batchDoResign}
            >
              <Button disabled={btnDepartureDisabled}>操作离职</Button>
            </Popconfirm>
          </Space>
        </Toolbar>
        <div>
          <Table
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRowKeys,
              onChange: (keys: Key[], selectedRows: any[]) => {
                // batchDoResign(selectedRows)
                setSelectedRowKeys(keys)
                setBtnDepartureDisabled(selectedRows.length === 0);
                setResignList(selectedRows.map((record: any) => record.id));
              }
            }}
            dataSource={dataSource}
            loading={loading}
            rowKey={record => `employeelist_item_${record.id}`}
            pagination={{
              total: dataCount,
              pageSize: pageSize,
              current: pageIndex,
              onChange: onPagination,
              showSizeChanger: true
            }}
          >
            <Column
              title="姓名"
              dataIndex="realName"
              render={(_, record: any, index: number) => (
                <ButtonDetail onClick={() => {showDetail(index)}}>{_}</ButtonDetail>
              )}
            />
            <Column 
              title="性别"
              dataIndex="sex"
              render={(_) => _ == 1 ? '男' : '女'}
            />
            <Column
              title="年龄"
              dataIndex="birth"
              render={(_) => {
                const now = new Date();
                const birth = new Date(_);
                return now.getFullYear() - birth.getFullYear();
              }}
            />
            <Column
              title="学历" 
              dataIndex="education"
              render={(_) => getSelectedTextFromList(educationList, _)}
            />
            <Column title="角色" dataIndex="corpRole" />
            <Column title="生日" dataIndex="birth" />
            <Column title="手机" dataIndex="mobileNo" />
            <Column 
              title="在职状态" 
              dataIndex="jobStatus"
              render={_ => <Chip jobStatus={_} />}
            />
            <Column
              title="操作"
              dataIndex="id"
              render={(_: number, record: any, index: number) => <ButtonDetail onClick={() => {
                console.log('index: ', index)
                showDetail(index)}}>查看</ButtonDetail>}
            />
          </Table>
          <Drawer 
            visible={isShowDetail} 
            closable={true} 
            onClose={closeDetail}
            width={468}
          >
            { selectedEmployeeIndex >= 0 ? <Detail index={selectedEmployeeIndex} idList={dataSource} /> : null}
          </Drawer>
        </div>
      </ContentContainer>
    </Layout>
  )
}

export default EmployeeList;