import { useEffect, useRef } from 'react';
import styles from './style.module.less';

interface Props {
  rowIndex: number;
  colIndex: number;
  onChange?: (row: number, col: number, left: number, top: number) => void;
}

const Cell: React.FC<Props> = ({
  rowIndex,
  colIndex,
  onChange
}) => {
  const _ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (onChange && _ref.current) {
      onChange(rowIndex, colIndex, _ref.current?.offsetLeft, _ref.current?.offsetTop)
    }
  }, [])
  return (
    <div ref={_ref} className={styles.dateTimeCell}></div>
  )
}

export default Cell;