import { DisplayMode } from '@/types/common';

export function setWorkspacePagination(type: 'common' | 'sales', pageIndex: number, pageSize: number) {
  return {
    type: type === 'common' ? 'SET_WS_COMMON_PAGINATION' : 'SET_WS_SALES_PAGINATION',
    data: {
      pageIndex,
      pageSize
    }
  }
}

export function setRentStatus(rentStatus: number | undefined) {
  return {
    type: 'SET_WS_RENT_STATUS',
    data: rentStatus
  }
}

export function setFloorNo(floorNo: string) {
  return {
    type: 'SET_WS_FLOOR_NO',
    data: floorNo
  }
}

export function setWorkspaceType(workspaceType: number | undefined) {
  return {
    type: 'SET_WS_WORKSPACE_TYPE',
    data: workspaceType
  }
}

export function setWorkspaceSizeType(workspaceSizeType: number | undefined) {
  return {
    type: 'SET_WS_WORKSPACE_SIZE_TYPE',
    data: workspaceSizeType
  }
}

export function setWorkspaceDisplayMode(displayMode: DisplayMode) {
  return {
    type: 'SET_WS_DISPLAY_MODE',
    data: displayMode
  }
}