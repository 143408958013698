import { useEffect, useState } from 'react';
import { DatePicker, Popover, Space } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Select from '@/components/Select';
import { getWorkspaceAcAmmeterDayStat } from '@/services/bill';
import { getFloorRoomList } from '@/services/workspace';
import RoomDailyDetail from './RoomDailyDetail';

const Container = styled.div``

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const RoomName = styled.div`
  width: 224px;
  height: 35px;
  line-height: 35px;
  padding-left: 8px;
  border-bottom: 1px solid #E2EBFF;
`

const Cell = styled.div`
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 36px;
  border-left: 1px solid #E2EBFF;
  border-bottom: 1px solid #E2EBFF;
  > div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  >.used {
    background-color: #E2EBFF;
    cursor: pointer
  }
`

const TimesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const timeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const checkIsUsed = (time: Number, switchOperateRecordList: any[] = []): Boolean => {
  for(let i = 0; i < switchOperateRecordList.length; i++) {
    const record =  switchOperateRecordList[i];
    if (time >= record.onHour && time <= record.offHour) {
      return true;
    }
  }
  return false;
}

interface Props {}

const DailyBill: React.FC<Props> = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [queryDate, setQueryDate] = useState<moment.Moment | null>(moment());
  const [floorNo, setFloorNo] = useState('');
  const [roomNo, setRoomNo] = useState('');
  const [floorRoomMap, setFloorRoomMap] = useState<any>({});
  const [floorList, setFloorList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);

  useEffect(() => {
    if (queryDate) {
      getWorkspaceAcAmmeterDayStat(queryDate.format('YYYY-MM-DD')).then((res: any) => {
        const { resultCode, data } = res.data;
        if (resultCode === 0) {
          const newDataSource: Array<any> = [];
          data.forEach((d: any) => {
            const { ammeterDayDataList, subList, ...roomData } = d;
            if (ammeterDayDataList.length > 0) {
              ammeterDayDataList.forEach((dayData: any) => {
                newDataSource.push({
                  roomData,
                  ...dayData
                })
              });
              return;
            }

            subList.forEach((subData: any) => {
              const { subAmmeterDayDataList: ammeterDayDataList, ...subRoomData } = subData;
              subRoomData.ammeterDayDataList.forEach((dayData: any) => {
                newDataSource.push({
                  roomData: subRoomData,
                  ...dayData
                })
              });
            })
            
          });

          setDataSource(newDataSource);
        }
      })
    }
  }, [queryDate])

  useEffect(() => {
    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorList(list.map(item => ({
        value: item,
        text: item
      })));
      setFloorRoomMap(res.data);
    })
  }, [])

  useEffect(() => {
    if (floorNo) {
      setRoomList(floorRoomMap[floorNo].filter((item: string) => !item.includes('-')).map((item: string) => ({
        value: item,
        text: item
      })))
    }
  }, [floorNo])


  return (
    <Container>
      <Space>
        <DatePicker value={queryDate} onChange={val => setQueryDate(val)} />
        <Select label="所属楼层" value={floorNo} options={floorList} onChange={val => setFloorNo(val)} />
        <Select label="所属房间" value={roomNo} options={roomList} onChange={val => setRoomNo(val)} />
      </Space>
      <Header>
        <RoomName>房间号</RoomName>
        <TimesContainer>
          {
            timeList.map(t => <Cell key={`time_${t}`}>{t}</Cell>)
          }
        </TimesContainer>
      </Header>
      {
        dataSource.filter((item: any) => {
          if (floorNo && roomNo) {
            return item.roomData.floorNo === floorNo && item.roomData.roomNo === roomNo;
          }
          if (floorNo) {
            return item.roomData.floorNo === floorNo;
          }
          if (roomNo) {
            return item.roomData.roomNo === roomNo;
          }
          return true; 
        }).map((item: any, index: number) => {
          return (
            <TableRow key={`table_row_time_${index}`}>
              <RoomName>{`${item.roomData.roomNo} - ${item.ammeterAddress}`}</RoomName>
              <TimesContainer>
                {
                  timeList.map(t => {
                    return (
                      <Cell key={`cell_${index}_${t}`}>
                        {
                          checkIsUsed(t, item.switchOperateRecordList) ? (
                            <Popover
                              placement="right"
                              trigger="click"
                              content={<RoomDailyDetail data={item} />}
                            >
                              <div className="used"></div>
                            </Popover>
                          ) : null
                        }
                      </Cell>
                    )
                  })
                }
              </TimesContainer>
            </TableRow>
          )
        })
      }
    </Container>
  )
}

export default DailyBill;