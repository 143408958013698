import { useState, useEffect } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import CorpStatus from './CorpStatus';
import Form1 from './CorpStatusForm1';
import Form2 from './CorpStatusForm2';
import Form3 from './CorpStatusForm3';
import Form4 from './CorpStatusForm4';
import { getCanRentRoomList } from '@/services/workspace';
import { FormInstance } from 'antd/lib/form';

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

interface Props {
  mode?: 'edit' | 'readonly';
  data?: any;
  form?: FormInstance;
  contractStatus: number;
  contractImgList: any [];
  cachedRoomList: any[];
  onStatusChange?: (status: number) => void;
  onContractImgListChange?: (list: any []) => void;
  cacheRoom?: (values: any) => void;
  removeRoom?: (roomNo: string) => void;
  modifyRoom?: (values: any) => void;
}

const CorpStatusAndRelatedFields: React.FC<Props> = ({
  mode = 'edit',
  data,
  form,
  contractStatus,
  contractImgList,
  cachedRoomList,
  onStatusChange,
  onContractImgListChange,
  cacheRoom,
  removeRoom,
  modifyRoom
}) => {
  const [floorRoomList, setFloorRoomList] = useState<any>({});
  
  useEffect(() => {
    getCanRentRoomList().then((res: any) => {
      setFloorRoomList(res.data)
    })
  }, [])

  return (
    <Container>
      <TabContainer>
        <Space>
          <CorpStatus
            mode={mode}
            title="未签约洽谈中"
            actived={contractStatus === 0}
            onClick={() => onStatusChange && onStatusChange(0)}
          />
          <CorpStatus
            mode={mode}
            title="未签约已拒绝"
            actived={contractStatus === 1}
            onClick={() => onStatusChange && onStatusChange(1)}
          />
          <CorpStatus
            mode={mode}
            title="已签约"
            actived={contractStatus === 2}
            onClick={() => onStatusChange && onStatusChange(2)}
          />
          <CorpStatus
            mode={mode}
            title="已退驻"
            actived={contractStatus === 4}
            onClick={() => onStatusChange && onStatusChange(4)}
          />
        </Space>
      </TabContainer>
      { contractStatus === 0 && <Form1 floorRoomList={floorRoomList} data={data} /> }
      { contractStatus === 1 && <Form2 data={data} /> }
      { contractStatus === 2 && (
        <Form3
          floorRoomList={floorRoomList}
          data={data}
          form={form}
          contractImgList={contractImgList}
          cachedRoomList={cachedRoomList}
          onContractImgListChange={onContractImgListChange}
          cacheRoom={cacheRoom}
          removeRoom={removeRoom}
          modifyRoom={modifyRoom}
        />
      ) }
    </Container>
  )
}

export default CorpStatusAndRelatedFields;