
import styled from 'styled-components';

const Text = styled.div`
  width: auto;
  background: #F4F5F9;
  border-radius: 4px;
  line-height: 32px;
  padding-left: 10px;
  word-wrap: break-word;
`

export default Text;