import { Tabs } from 'antd';
import { Route, Switch, useHistory } from 'react-router-dom';
import CompanyList from './Company/List';
import CompanyDetail from './Company/Detail';
import CompanyEdit from './Company/Edit';
import EmployeeList from './Employee/List';
import EmployeeDetail from './Employee/Detail.discard';
import EmployeeEdit from './Employee/Edit';
import Invitation from './Employee/Invitation';
import ApplicantList from './Employee/ApplicantList';

const { TabPane } = Tabs;

const CompanyManagement = () => {
  // const history = useHistory();
  // console.log(history.location.state)
  // const { tab }: any = history.location.state;
  
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="公司" key="1">
          <Switch>
            <Route path="/company/detail/:id"><CompanyDetail /></Route>
            <Route path="/company/add"><CompanyEdit type="add" /></Route>
            <Route path="/company/edit/:id"><CompanyEdit type="edit" /></Route>
            <Route path="/company"><CompanyList /></Route>
          </Switch>
        </TabPane>
        <TabPane tab="成员" key="2">
          <Switch>
            <Route path="/company/addemployee/:corpId"><EmployeeEdit type="add" /></Route>
            <Route path="/company/editemployee/:id"><EmployeeEdit type="edit" /></Route>
            <Route path="/company/employee/:id"><EmployeeDetail /></Route>
            <Route path="/company/invitation/:corpId"><Invitation /></Route>
            <Route path="/company/applicants/:corpId"><ApplicantList /></Route>
            <Route path="/company"><EmployeeList /></Route>
          </Switch>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CompanyManagement;