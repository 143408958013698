import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Space, Table, Popconfirm, Input, Select, Form, message, Popover } from 'antd';
import { PlusOutlined, QrcodeOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AsyncQRCode from '@/components/AsyncQRCode';
import Title from '@/components/Form/Title';
import FormItem from '@/components/Form/Item';
import BindDevice from '../BindDevice';
import { getFloorRoomList, getWorkspaceDetail, updateWorkspace, getWorkspaceList } from '@/services/workspace'; 
import { getDeviceList, updateDevice, getDeviceQRCodeUrl } from '@/services/device';
import { getSelectedTextFromList } from '@/utils/transform';
import { ValueAndText } from '@/types/common';
import EditDevice from '../EditDevice';

const { Column } = Table;
const { Option } = Select;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
`

const Panel = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const Content = styled.div`
  background: #F4F5F9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1F2329;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
`

interface Props {

}

const WorkspaceEdit: React.FC<Props> = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();
  const [deviceList1, setDeviceList1] = useState<any []>([]);
  const [deviceList2, setDeviceList2] = useState<any []>([]);
  const [floorRoomList, setFloorRoomList] = useState<any[]>([]);
  const [detail, setDetail] = useState<any>({});
  const [bindDeviceVisible1, setBindDeviceVisible1] = useState(false);
  const [bindDeviceVisible2, setBindDeviceVisible2] = useState(false);
  const [editDeviceVisible, setEditDeviceVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowDeviceList, setIsShowDeviceList] = useState(false);
  const rentStatusList = useSelector((state: any) => state.common.rentStatusList);
  const workspaceTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.workspaceTypeList);
  const workspaceSizeTypeList: Array<ValueAndText> = useSelector((state: any) => state.common.workspaceSizeTypeList);
  const deviceTypeList = useSelector((state: any) => state.common.deviceTypeList);
  const deviceStatusList = useSelector((state: any) => state.common.deviceStatusList);

  const getBoundDeviceList = () => {
    getDeviceList({
      belongRoom: detail.roomNo,
      assetType: 0,
      currentPage: 1,
      pageSize: 10
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList1(data)
      }
    });

    getDeviceList({
      belongRoom: detail.roomNo,
      assetType: 1,
      currentPage: 1,
      pageSize: 10
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDeviceList2(data)
      }
    })
  }

  const save = (values: any) => {
    setLoading(true);
    updateWorkspace({
      ...values,
      workspaceId: id
    }).then((res: any) => {
      setLoading(false);
      const { resultCode, resultMsg } = res.data;
      if (resultCode === 0) {
        message.success('保存成功');
        setTimeout(() => {
          history.goBack();
        }, 1000)
        return;
      }
      message.error(resultMsg || '保存失败');
    }).catch(() => {
      setLoading(false);
    })
  }


  useEffect(() => {
    getWorkspaceDetail(id).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        form.setFieldsValue(data);
        setDetail(data);
        setIsShowDeviceList(data.roomNo !== data.parentRoomNo);
        return [data.roomNo !== data.parentRoomNo, data.parentRoomNo];
      }
      return [false, null]
    })
    .then(([success, parentRoomNo]) => success ? getWorkspaceList({ roomNo: parentRoomNo }) : null)
    .then((res: any) => {
      if (!res) {
        return
      }

      const { resultCode, data } = res.data;
      if (resultCode === 0 && data.length > 0) {
        setDetail({
          ...detail,
          rentCost: data[0].rentCost
        })
      }
    })

    getFloorRoomList().then((res: any) => {
      const list = Object.keys(res.data).sort((prev: string, next: string) => {
        return Number(prev.replace('F', '')) - Number(next.replace('F', ''))
      }) || []
      setFloorRoomList(list)
    })
  }, [])

  useEffect(() => {
    getBoundDeviceList();
  }, [detail])
  return (
    <>
      <CloseButtonContainer>
        <Button type="text" onClick={() => history.goBack() }><CloseOutlined /></Button>
      </CloseButtonContainer>
      <Container>
        <Form form={form} layout="vertical" onFinish={save}>
          <Panel>
            <Title>基本信息</Title>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="功能类型" name="workspaceType">
                  <Select>
                    {
                      workspaceTypeList.map(({value, text}) => (
                        <Option value={value}>{text}</Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="空间编号" name="roomNo">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="门牌号" name="corpRoomAlias">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="房间面积" name="workspaceArea">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="所属园区" name="belongPark">
                  <Input />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="所属楼层" name="floorNo">
                  <Select>
                  {
                    floorRoomList.map((floorNo: string) => (
                      <Option
                        key={`device_floor_${floorNo}`}
                        value={floorNo}
                      >{floorNo}</Option>
                    ))
                  }
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="容纳人数" name="realGalleryful" >
                  <Input />
                </FormItem>
              </Col>
              {
                isShowDeviceList ? (
                  <Col span={12}>
                    <FormItem label="租金">
                      <Content>{detail.rentCost}</Content>
                    </FormItem>
                  </Col>
                ) : detail.workspaceType === 1 ? (
                  <Col span={12}>
                    <FormItem label="租金" name="meetingRoomRentPerHoure">
                      <Input />
                    </FormItem>
                  </Col>
                ) : (
                  <Col span={12}>
                    <FormItem label="租金" name="officeMonthlyRent">
                      <Input />
                    </FormItem>
                  </Col>
                )
              }
            </Row>
          </Panel>
          <Panel>
            <Title>入驻信息</Title>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <FormItem label="入驻状态">
                  <Content>{detail.rendStatus === 1 ? '已租' : '待租'}</Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="入驻公司">
                  <Content>{detail.rentCorpName}</Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="合约时间">
                  <Content>{detail.rentExpireDate}</Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="合约费用">
                  <Content>{detail.rentCost}</Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="销售">
                  <Content>{detail.salesman}</Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="销售手机">
                  <Content></Content>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="销售跟进情况">
                  <Content></Content>
                </FormItem>
              </Col>
            </Row>
          </Panel>
          {
            isShowDeviceList ? (
              <>
                <Panel>
                  <Title>包含基础设施</Title>
                  <Space style={{ marginBottom: 29 }}>
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => setBindDeviceVisible1(true)}>添加</Button>
                    {/* <Button>批量导入/导出</Button> */}
                  </Space>
                  <Table
                    rowKey={(record: any) => `w_e_dl_a_${record.deviceId}`}
                    dataSource={deviceList1}
                  >
                    <Column title="名称" dataIndex="deviceName" />
                    <Column
                      title="种类"
                      dataIndex="deviceType"
                      render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
                    />
                    <Column title="编号" dataIndex="deviceNo" />
                    <Column title="型号" dataIndex="deviceModel" />
                    <Column title="关联房间" dataIndex="belongRoom" />
                    <Column
                      title="使用状态"
                      dataIndex="deviceStatus"
                      render={text => getSelectedTextFromList(deviceStatusList, text)}
                    />
                    <Column
                      title="二维码"
                      dataIndex="deviceNo"
                      render={(_: string) => (
                        <Popover content={<AsyncQRCode value={getDeviceQRCodeUrl(_)} />}>
                          <QrcodeOutlined style={{ fontSize: 20 }} />
                        </Popover>
                      )}
                    />
                    <Column
                      title="操作"
                      dataIndex="deviceId"
                      render={(deviceId: number, record: any) => {
                        return (
                          <Space>
                            <Button type="link" onClick={() => {
                              setEditDeviceVisible(true);
                              setSelectedDeviceId(deviceId);
                            }}>编辑</Button>
                            <Popconfirm
                              title={`确认要解除房间${detail.roomNo}与设备${record.deviceNo}的关联吗？`}
                              placement="topRight"
                              onConfirm={() => {
                                updateDevice({
                                  deviceId,
                                  belongRoom: '-',
                                  belongFloor: '-',
                                }).then(getBoundDeviceList)
                              }}
                            >
                              <Button type="link" danger>删除</Button>
                            </Popconfirm>
                          </Space>
                        )
                      }}
                    />
                  </Table>
                </Panel>
                <Panel>
                  <Title>包含固定资产</Title>
                  <Space style={{ marginBottom: 29 }}>
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => setBindDeviceVisible2(true)}>添加</Button>
                    {/* <Button>批量导入/导出</Button> */}
                  </Space>
                  <Table
                    rowKey={(record: any) => `w_e_dl_b_${record.deviceId}`}
                    dataSource={deviceList2}
                  >
                    <Column title="名称" dataIndex="deviceName" />
                    <Column
                      title="种类"
                      dataIndex="deviceType"
                      render={(_: number) => getSelectedTextFromList(deviceTypeList, _)}
                    />
                    <Column title="编号" dataIndex="deviceNo" />
                    <Column title="型号" dataIndex="deviceModel" />
                    <Column title="关联房间" dataIndex="belongRoom" />
                    <Column
                      title="使用状态"
                      dataIndex="deviceStatus"
                      render={text => getSelectedTextFromList(deviceStatusList, text)}
                    />
                    <Column
                      title="二维码"
                      dataIndex="deviceNo"
                      render={(_: string) => (
                        <Popover content={<AsyncQRCode value={getDeviceQRCodeUrl(_)} />}>
                          <QrcodeOutlined style={{ fontSize: 20 }} />
                        </Popover>
                      )}
                    />
                    <Column
                      title="操作"
                      dataIndex="deviceId"
                      render={(deviceId: number, record: any) => {
                        return (
                          <Space>
                            <Button type="link" onClick={() => {
                              setEditDeviceVisible(true);
                              setSelectedDeviceId(deviceId);
                            }}>编辑</Button>
                            <Popconfirm
                              title={`确认要解除房间${detail.roomNo}与设备${record.deviceNo}的关联吗？`}
                              placement="topRight"
                              onConfirm={() => {
                                updateDevice({
                                  deviceId,
                                  belongRoom: '-',
                                  belongFloor: '-',
                                }).then(getBoundDeviceList)
                              }}
                            >
                              <Button type="link" danger>删除</Button>
                            </Popconfirm>
                          </Space>
                        )
                      }}
                    />
                  </Table>
                </Panel>
              </>
            ) : null
          }
          <Row>
            <Space>
              <Button onClick={() => {
                history.goBack();
              }}>取消</Button>
              <Button
                type="primary"
                style={{ marginLeft: 12 }}
                htmlType="submit"
                loading={loading}
              >确定</Button>
            </Space>
          </Row>

          <BindDevice
            visible={bindDeviceVisible1}
            roomNo={detail.roomNo}
            floorNo={detail.floorNo}
            assetType={0}
            onClose={() => {
              setBindDeviceVisible1(false)
              getBoundDeviceList()
            }}
          />
          <BindDevice
            visible={bindDeviceVisible2}
            roomNo={detail.roomNo}
            floorNo={detail.floorNo}
            assetType={1}
            onClose={() => {
              setBindDeviceVisible2(false)
              getBoundDeviceList()
            }}
          />
          <EditDevice
          visible={editDeviceVisible}
          deviceId={selectedDeviceId}
          onClose={() => setEditDeviceVisible(false)}
          onOk={() => {
            setEditDeviceVisible(false);
            getBoundDeviceList();
          }}
          />
        </Form>
      </Container>
    </>
  )
}

export default WorkspaceEdit;