import React, { useState, useEffect } from 'react';
import { Button, Space, Tabs } from 'antd';
import styled from 'styled-components';
import Table from '@/components/Table';
import { getTaskList, getTaskCount } from '@/services/task';
import { Link } from 'react-router-dom';
import { taskStatusMap } from '@/common/constants';

const { TabPane } = Tabs;

const ActivityInfoContianer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  column-gap: 12px;
`;

const ActivityCover = styled.div`
  width: 80px;
  > img { width: 100% }
`

const ActivityTitle = styled.div`
  color: #1f2329;
  font-size: 12px;
`;

const ActivityDetail = styled.div`
  color: #8f959e;
  font-size: 12px;
`;

const TaskManagement: React.FC = () => {
  const columns = [
    {
      title: '任务',
      width: '40%',
      render: (record: any) => (
        <ActivityInfoContianer>
          <ActivityCover>
            <img src={record.imageUrls} alt="" />
          </ActivityCover>
          <div style={{ flex: 1}}>
            <ActivityTitle>{record.repairTitle}</ActivityTitle>
            <ActivityDetail>
              <div>描述：</div>
              {record.repairDesc}
            </ActivityDetail>
          </div>
        </ActivityInfoContianer>
      )
    },
    {
      title: '数量',
      dataIndex: 'repairCount',
      width: '15%'
    },
    {
      title: '任务类型',
      width: '15%',
      render: () => '维修'
    },
    {
      title: '任务状态',
      width: '15%',
      render: (record: any) => taskStatusMap[record.handleStatus]
    },
    {
      title: '任务操作',
      width: '15%',
      render: (record: any) => {
        return (
          <Space direction="vertical">
            <Link to={`/task/${record.taskId}`}><Button type="primary">立即处理</Button></Link>
          </Space>
        )
      }
    },
    {
      title: '',
      dataIndex: 'createTime',
    },
    {
      title: '任务编号',
      dataIndex: 'taskId',
    },
    // {
    //   title: '申报人',
    //   dataIndex: 'applier',
    // },
    {
      title: '申报房间',
      dataIndex: 'roomNo'
    }
  ];

  const data: Array<any> = [
    {
      taskName: 'task 1',
      taskType: 'type 1',
      taskStatus: 'ready',
      price: 100,
      count: 2,
      payment: 100,
      applier: 'Sandy',
      applyRoom: '8602, 8603',
      taskCode: '1234567899323231',
      createdDate: '2020-12-14'
    }
  ]
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);

  const getData = () => {
    getTaskList({
      currentPage: current,
    }).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setDataSource(data);
      }
    })
    getTaskCount({}).then((res: any) => {
      const { resultCode, data } = res.data;
      if (resultCode === 0) {
        setTotal(data);
      }
    })
  }

  const onPaginationChange = (page: number) => {
    setCurrent(page);
  } 

  useEffect(() => {
    getData();
  }, [current])

  return (
    <>
      <Tabs>
        <TabPane tab="所有任务" key="task_tab_1">
          <Table
            columns={columns}
            dataSource={dataSource}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        {/* <TabPane tab="待付款" key="task_tab_2">
          <Table
            columns={columns}
            dataSource={dataSource}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane> */}
        <TabPane tab="待处理" key="task_tab_3">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({handleStatus}) => handleStatus === 0)}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="处理中" key="task_tab_4">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({handleStatus}) => handleStatus === 1)}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
        <TabPane tab="已完成" key="task_tab_5">
          <Table
            columns={columns}
            dataSource={dataSource.filter(({handleStatus}) => handleStatus === 2)}
            current={current}
            total={total}
            onChange={onPaginationChange}
          />
        </TabPane>
      </Tabs>
    </>
  );
}

export default TaskManagement;