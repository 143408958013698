import { Dictionary, ValueAndText } from "@/types/common"

export const breadcrumbNameMap: Dictionary = {
  '/companyanddata': '公司及数据管理',
  '/company': '公司管理',
  '/company/detail': '详情',
  '/company/add': '新增',
  '/company/edit': '编辑',
  '/data': '数据管理',
  '/workspace': '空间配置管理',
  '/salesspace': '空间入驻管理',
  '/workspace/detail': '详情',
  '/workspace/edit': '编辑',
  '/device': '资产管理',
  '/device/add': '新增',
  '/device/detail': '详情',
  '/device/edit': '编辑',
  '/company/invitation': '邀请成员加入',
  '/company/applicants': '审批成员',
  '/energy': '能源管理',
  '/energy/monthlybill': '账单',
  '/energy/monthlybillc': '账单',
  '/energy/dailybill': '日账单',
  '/energy/invoice': '开票申请',
  '/personnelfiling': '人员备案',
  '/personnelfiling/permission': '权限管理',
  '/personnelfiling/permission/edit': '权限编辑',
}

export const API_BASE = 'https://zlh.yoymoney.com'

export const SexMap: Dictionary = {
  '1': '男',
  '2': '女'
}

export const CardStatusList: Array<ValueAndText> = [
  { text: '无效', value: 0, label: '无效' },
  { text: '有效', value: 1, label: '有效' }
]

export const taskStatusMap = ['待处理', '处理中', '已完成', '已取消']

export const invoiceStatusList: Array<ValueAndText> = [
  { text: '待申请', value: 0, label: '待申请' },
  { text: '已申请', value: 1, label: '已申请' },
  { text: '已开票', value: 2, label: '已开票' },
  { text: '已取消', value: 3, label: '已取消' },
]